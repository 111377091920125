export const estados = [['BA', 'Bahia'], ['ES', 'Espírito Santo'], ['GO', 'Goiás'], ['MA', 'Maranhão'], ['MG', 'Minas Gerais'], ['MT', 'Mato Grosso'], ['PB', 'Paraíba'], ['PE', 'Pernambuco'], ['RO', 'Rondônia'], ['RJ', 'Rio de Janeiro'], ['RS', 'Rio Grande do Sul'], ['SC', 'Santa Catarina'], ['SE', 'Sergipe'], ['TO', 'Tocantins']];

export const cidades = [
  // ['Alagoinhas', 'Bom Jesus Da Lapa', 'Brumado', 'Candeias', 'Feira de Santana', 'Itabuna', 'Itacaré', 'Itapetinga', 'Luis Eduardo Magalhães', 'Santo Antônio de Jesus', 'Simões Filho', 'Vitória da Conquista'],
  ['Alagoinhas', 'Bom Jesus Da Lapa', 'Brumado', 'Candeias', 'Feira de Santana', 'Itabuna', 'Itacaré', 'Itapetinga', 'Luis Eduardo Magalhães', 'Santo Antônio de Jesus', 'Simões Filho',],
  ['Cariacica'],
  ['Caldas Novas', 'Sanclerlândia'],
  ['Santa Luzia'],
  // ['Além Paraiba', 'Arcos', 'Campos Altos', 'Cássia', 'Extrema', 'Formiga', 'Frutal', 'Jaboticatubas', 'Passos', 'Porteirinha', 'Rio Piracicaba', 'Santa Bárbara', 'São Gonçalo do Pará', 'São Gonçalo do Rio Abaixo', 'São Gotardo', 'São Lourenço', 'Uberaba', 'Verissimo'],
  ['Além Paraiba', 'Campos Altos', 'Cássia', 'Extrema', 'Jaboticatubas', 'Passos', 'Porteirinha', 'Rio Piracicaba', 'Santa Bárbara', 'São Gonçalo do Pará', 'São Lourenço', 'Verissimo'],
  ['Cáceres', 'Itanhangá'],
  ['Campina Grande'],
  ['Ipojuca'],
  // ['Alta Floresta D Oeste', 'Cacoal', 'Nova Brasilândia do Oeste', 'Rolim de Moura', 'São Francisco do Guaporé', 'São Miguel do Guaporé', 'Urupá', 'Vilhena'],
  ['Alta Floresta D Oeste', 'Nova Brasilândia do Oeste', 'Rolim de Moura', 'São Francisco do Guaporé', 'São Miguel do Guaporé', 'Urupá', 'Vilhena'],
  ['Barra do Piraí', 'Miguel Pereira', 'Paty do Alferes', 'Santa Maria Madalena', 'Sapucaia', 'Teresópolis'],
  ['Bagé'],
  ['Cocal do Sul', 'Lauro Müller', 'Siderópolis'],
  ['Aquidabã', 'Aracaju', 'Barra dos Coqueiros', 'Capela', 'Campo do Brito', 'Carmópolis', 'Estância', 'Itabaiana', 'Japaratuba', 'Laranjeiras', 'Moita Bonita', 'Nossa Senhora do Socorro', 'Rosário do Catete', 'São Cristóvão', 'Tobias Barreto'],
  ['Araguaína', 'Araguatins', 'Dianópolis', 'Gurupi', 'Palmas', 'Paraíso do Tocantins', 'Porto Nacional', 'Xambioá']
];

export const enderecos = {
  "Alagoinhas": "http://alagoinhasba.webiss.com.br",
  "Bom Jesus Da Lapa": "http://bomjesusdalapaba.webiss.com.br",
  "Brumado": "http://brumadoba.webiss.com.br",
  "Candeias": "http://candeiasba.webiss.com.br",
  "Feira de Santana": "http://feiradesantanaba.webiss.com.br",
  "Itabuna": "http://itabunaba.webiss.com.br",
  "Itacaré": "http://itacareba.webiss.com.br",
  "Itapetinga": "http://itapetingaba.webiss.com.br",
  "Luis Eduardo Magalhães": "http://luiseduardomagalhaesba.webiss.com.br",
  "Santo Antônio de Jesus": "http://santoantoniodejesusba.webiss.com.br",
  "Simões Filho": "https://simoesfilhoba.webiss.com.br",
  // "Vitória da Conquista": "http://www4.webiss.com.br/vitoriadaconquistaba",
  "Cariacica": "http://cariacicaes.webiss.com.br",
  "Caldas Novas": "http://caldasnovasgo.webiss.com.br",
  "Sanclerlândia": "http://sanclerlandiago.webiss.com.br",
  "Santa Luzia": "http://santaluziama.webiss.com.br",
  "Além Paraiba": "http://alemparaibamg.webiss.com.br",
  // "Arcos": "http://www1.webiss.com.br/arcosmg",
  "Campos Altos": "http://camposaltosmg.webiss.com.br",
  "Cássia": "http://cassiamg.webiss.com.br",
  "Extrema": "http://extremamg.webiss.com.br",
  // "Formiga": "http://www1.webiss.com.br/formigamg",
  // "Frutal": "http://www.webiss.com.br/mgfrutal",
  "Jaboticatubas": "http://jaboticatubasmg.webiss.com.br",
  "Passos": "http://passosmg.webiss.com.br",
  "Porteirinha": "http://porteirinhamg.webiss.com.br",
  "Rio Piracicaba": "http://riopiracicabamg.webiss.com.br",
  "Santa Bárbara": "http://santabarbaramg.webiss.com.br",
  "São Gonçalo do Pará": "http://saogoncalodoparamg.webiss.com.br",
  // "São Gonçalo do Rio Abaixo": "http://www7.webiss.com.br/saogoncalodorioabaixomg",
  // "São Gotardo": "http://www4.webiss.com.br/saogotardomg",
  "São Lourenço": "http://saolourencomg.webiss.com.br",
  // "Uberaba": "http://www1.webiss.com.br/uberaba",
  "Verissimo": "http://verissimomg.webiss.com.br",
  "Cáceres": "http://caceresmt.webiss.com.br",
  "Itanhangá": "http://itanhangamt.webiss.com.br",
  "Campina Grande": "http://campinagrandepb.webiss.com.br",
  "Ipojuca": "https://ipojucape.webiss.com.br",
  "Alta Floresta D Oeste": "http://altaflorestadoestero.webiss.com.br",
  // "Cacoal": "http://www4.webiss.com.br/cacoalro",
  "Nova Brasilândia do Oeste": "http://novabrasilandiadoestero.webiss.com.br",
  "Rolim de Moura": "http://rolimdemouraro.webiss.com.br",
  "São Francisco do Guaporé": "https://saofranciscodoguaporero.webiss.com.br",
  "São Miguel do Guaporé": "http://saomigueldoguaporero.webiss.com.br",
  "Urupá": "http://uruparo.webiss.com.br",
  "Vilhena": "http://vilhenaro.webiss.com.br",
  "Barra do Piraí": "http://barradopirairj.webiss.com.br",
  "Miguel Pereira": "http://miguelpereirarj.webiss.com.br",
  "Paty do Alferes": "http://patydoalferesrj.webiss.com.br",
  // "Queimados": "http://www1.webiss.com.br/queimadosrj",
  "Santa Maria Madalena": "http://santamariamadalenarj.webiss.com.br",
  "Sapucaia": "https://sapucaiarj.webiss.com.br",
  "Teresópolis": "http://teresopolisrj.webiss.com.br",
  "Bagé": "http://bagers.webiss.com.br",
  "Cocal do Sul": "https://cocaldosulsc.webiss.com.br",
  "Lauro Müller": "http://lauromullersc.webiss.com.br",
  "Siderópolis": "http://sideropolissc.webiss.com.br",
  "Aquidabã": "http://aquidabase.webiss.com.br",
  "Aracaju": "http://aracajuse.webiss.com.br",
  "Barra dos Coqueiros": "http://barradoscoqueirosse.webiss.com.br",
  "Capela": "http://capelase.webiss.com.br",
  "Campo do Brito": "http://campodobritose.webiss.com.br",
  "Carmópolis": "http://carmopolisse.webiss.com.br",
  "Estância": "http://estanciase.webiss.com.br",
  "Itabaiana": "http://itabaianase.webiss.com.br ",
  "Japaratuba": "http://japaratubase.webiss.com.br ",
  "Laranjeiras": "http://laranjeirasse.webiss.com.br",
  "Moita Bonita": "http://moitabonitase.webiss.com.br",
  "Nossa Senhora do Socorro": "http://nossasenhoradosocorrose.webiss.com.br",
  "Rosário do Catete": "http://rosariodocatetese.webiss.com.br",
  "São Cristóvão": "http://saocristovaose.webiss.com.br",
  "Tobias Barreto": "http://tobiasbarretose.webiss.com.br",
  "Araguaína": "http://araguainato.webiss.com.br",
  "Araguatins": "http://araguatinsto.webiss.com.br",
  "Dianópolis": "http://dianopolisto.webiss.com.br",
  "Gurupi": "https://gurupito.webiss.com.br",
  "Palmas": "https://palmasto.webiss.com.br",
  "Paraíso do Tocantins": "http://paraisodotocantinsto.webiss.com.br",
  "Porto Nacional": "http://portonacionalto.webiss.com.br",
  "Xambioá": "https://xambioato.webiss.com.br",
};
