import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import pricingPageStyle from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";
import Wizard from "../../components/Wizard/Wizard";
import Checkbox from "../../components/CustomInput/CustomCheckbox";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import {cardTitle} from "../../assets/jss/material-dashboard-pro-react";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import Table from "../../components/Table/Table";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  cardTitle,
  pageSubcategoriesTitle: {color: "#3C4858", textDecoration: "none", textAlign: "center"},
  cardCategory: {margin: "0", color: "#999999"},
  ...extendedFormsStyle
};

const useStyles = makeStyles(styles);
const useStyles1 = makeStyles(pricingPageStyle);


const SelecionarModulos = ({sendState}) => {
  const classes = useStyles();

  const [modulosAtivos, setModulosAtivos] = useState(['Entrada', 'Saída', 'Serviços']);

  const alteraModulosAtivos = tipoNota => {
    const tempModulosAtivos = [...modulosAtivos];
    const posicao = tempModulosAtivos.indexOf(tipoNota);
    if (posicao > -1)
      delete tempModulosAtivos[posicao];
    else
      tempModulosAtivos.push(tipoNota);
    setModulosAtivos(tempModulosAtivos);
  };

  return (
    <GridContainer>
      <GridItem lg={12} md={12} sm={12} xs={12} align={"center"}>
        <h3>Selecione os módulos que farão parte do seu pacote</h3>
      </GridItem>
      {['Entrada', 'Saída', 'Serviços'].map((prop, key) => (
        <GridItem lg={4} md={4} sm={4} xs={12} key={key}>
          <div className={classes.choiche}>
            <Checkbox
              checked={modulosAtivos.indexOf(prop) > -1}
              tabIndex={-1}
              onClick={() => alteraModulosAtivos(prop)}
              checkedIcon={<i className={"fas fa-file " + classes.iconCheckboxIcon}/>}
              icon={<i className={"fas fa-file " + classes.iconCheckboxIcon}/>}
              classes={{checked: classes.iconCheckboxChecked, root: classes.iconCheckbox}}
            />
            <h6>{prop}</h6>
          </div>
        </GridItem>
      ))}
    </GridContainer>
  );
}

const Empresas = () => {
  const [empresas] = useState([]);
  const [pagina, setPagina] = useState(0);

  /*const getEmpresas = async () => {
    const {ok, empresas} = await WebService(`/empresa/getEmpresas`, {token: 'gwy6altNxrtR69xczRU6aajiUggodsN6NBgaEx5mrOT4kbfjYi'});
    if (ok)
      setClientes(empresas);
  };*/

  useEffect(() => {
    // getEmpresas();
  }, [])

  return (
    <GridContainer>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <h3>Total: {empresas.length} empresas</h3>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Table
          hover
          tableHead={["CNPJ", "Razão Social", ""]}
          tableData={empresas.slice(pagina * 5, (pagina * 5) + 5).map(prop => {
            const {nome_razao_social, cnpj} = prop;
            return [
              cnpj,
              nome_razao_social,
              ""
            ];
          })}
        />
      </GridItem>
      <GridItem lg={6} md={6} sm={6} xs={6}>
        <Button onClick={() => setPagina(pagina - 1)} color={"info"} simple fullWidth>
          Anterior
        </Button>
      </GridItem>
      <GridItem lg={6} md={6} sm={6} xs={6}>
        <Button onClick={() => setPagina(pagina + 1)} color={"info"} simple fullWidth>
          Próximo
        </Button>
      </GridItem>
    </GridContainer>
  );
};

const Contrato = () => {
  return (
    <GridContainer>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <h4>
          <p>O contrato está disponível e pode ser visualizado logo abaixo.</p>
          {/*<a href={"https://crm.gefis.com.br/contract/2/4d9b503fbba4633e727878e2d7a0402d"}>
            https://crm.gefis.com.br/contract/2/4d9b503fbba4633e727878e2d7a0402d
          </a>*/}
          <p>A assinatura é feita digitalmente com o certificado digital A1 da sua empresa com mesma validade
            jurídica.</p>
        </h4>
        <Button color={"info"} onClick={() => window.open(`https://crm.gefis.com.br/contract/2/4d9b503fbba4633e727878e2d7a0402d`)} simple>
          Visualizar contrato
        </Button>
      </GridItem>
      <GridItem lg={6} md={6} sm={6} xs={6}>
        <Button color={"info"} fullWidth>
          Carregar certificado
        </Button>
      </GridItem>
      <GridItem lg={6} md={6} sm={6} xs={6}>
        <Button color={"info"} fullWidth disabled>
          Assinar
        </Button>
      </GridItem>
    </GridContainer>
  )
};

export default function PricingPage() {
  const classes = useStyles1();
  return (
    <div className={classes.container}>
      <Wizard
        title={"Tudo pronto para se tornar cliente!"}
        subtitle={"Só mais alguns passos e você poderá desfrutar 100% da ferramenta!"}
        nextButtonText={"Próximo"}
        finishButtonText={"Finalizar"}
        previousButtonText={"Anterior"}
        steps={[
          {stepName: "Contrato", stepComponent: Contrato, stepId: "pagina3"},
          {stepName: "Módulos", stepComponent: SelecionarModulos, stepId: "pagina1"},
          {stepName: "Empresas", stepComponent: Empresas, stepId: "pagina2"},
          {stepName: "Fatura", stepComponent: Empresas, stepId: "pagina4"}
        ]}
        color={"info"}
      />
    </div>
  );
}
