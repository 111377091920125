import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// core components
import AuthNavbar from "../components/Navbars/AuthNavbar.js";
import Footer from "../components/Footer/Footer.js";

import routes from "../routes.js";

import styles from "../assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

import register from "../assets/img/register.jpeg";
import login from "../assets/img/login.jpeg";
import lock from "../assets/img/lock.jpeg";
import error from "../assets/img/clint-mckoy.jpg";
import pricing from "../assets/img/bg-pricing.jpeg";
import ativacao from "../assets/img/bg-ativacao.jpeg";
import fill from "../assets/img/bg-fill.jpg";

const LoadRouterProps = ({component: Component, ...props}) => <Route render={p => <Component {...props} {...p}/>}/>;
const useStyles = makeStyles(styles);

export default function Pages(props) {
  const {...rest} = props;

  const wrapper = React.createRef();

  const classes = useStyles();

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    return function cleanup() {
    };
  });

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse)
        return getRoutes(prop.views);

      return (prop.layout === "/auth") ?
        <LoadRouterProps path={prop.path} component={prop.component} key={key} setToken={props.setToken}/> : null;
    });
  };

  const getBgImage = () => {
    if (window.location.pathname.indexOf("/cadastro") !== -1) {
      return `url(${register})`;
    } else if (window.location.pathname.indexOf("/login") !== -1) {
      return `url(${login})`;
    } else if (window.location.pathname.indexOf("/precos") !== -1) {
      return `url(${pricing})`;
    } else if (window.location.pathname.indexOf("/ativacao") !== -1) {
      return `url(${ativacao})`;
    } else if (window.location.pathname.indexOf("/lock-screen") !== -1) {
      return `url(${lock})`;
    } else if (window.location.pathname.indexOf("/error") !== -1) {
      return `url(${error})`;
    } else if (window.location.pathname.indexOf("/relatorio") !== -1)
      return `url(${fill})`;
      // return `url(https://www.67nj.org/wp-content/uploads/2016/06/116370-thumb.jpg)`;
      // return `url(https://www.itl.cat/pngfile/big/48-488018_light-blue-wallpapers-background-blue-screen-photo-background.jpg)`;
    // return `linear-gradient(to bottom, ${infoColor[3]}, ${infoColor[5]})`;
  };

  const getActiveRoute = routes => {
    let activeRoute = "Gefis - Inteligência Fiscal";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) return collapseActiveRoute
      } else if (window.location.href.indexOf(routes[i].path) !== -1) return routes[i].name;
    }
    return activeRoute;
  };
  return (
    <div>
      <AuthNavbar brandText={getActiveRoute(routes)} {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{
            backgroundImage: "" + getBgImage() + "",
            transition: '.3s',
            backgroundSize: window.location.pathname.indexOf("/relatorio") !== -1 ? 'contain' : 'auto'
          }}
        >
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/" to="/login"/>
          </Switch>
          <Footer white/>
        </div>
      </div>
    </div>
  );
}
