import React, {useEffect, useState} from 'react';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import Table from "../../../components/Table/Table";
import {formataCPFCNPJ, somenteNumero} from "../../../components/Funcoes";
import {Tooltip} from "@material-ui/core";
import {Close, Delete} from "@material-ui/icons";
import {WebService} from "../../../components/WebService";
import Swal from "sweetalert2";
import Success from "../../../components/Typography/Success";
import Danger from "../../../components/Typography/Danger";

const Megasoft = () => {
  const [empresas, setEmpresas] = useState([]);

  const getEmpresas = async () => {
    const {ok, empresas} = await WebService(`/servico/megasoftGetEmpresas`);
    if (ok) setEmpresas(empresas);
  };

  useEffect(() => {
    getEmpresas();
  }, []);

  const excluirEmpresa = async id_empresa_servico_megasoft => {
    const {value} = await Swal.fire({
      icon: "question",
      title: 'Deseja realmente excluir a empresa?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    });

    if (value) {
      const {ok} = await WebService(`/servico/megasoftRemoverEmpresa`, {id_empresa_servico_megasoft});
      if (ok) {
        await Swal.fire({icon: "success", title: 'Empresa excluída com sucesso!'});
        getEmpresas();
      }
    }
  };

  const adicionarEmpresa = async () => {
    let {value: cpf} = await Swal.fire({
      input: "text",
      title: 'Digite o CPF de acesso.',
      confirmButtonText: 'Próximo',
      cancelButtonText: 'Cancelar',
      showCancelButton: true
    });
    cpf = somenteNumero(cpf || '');
    if (!cpf || cpf.length < 11) {
      Swal.fire({icon: "warning", title: 'O valor digitado não é válido'});
      return false;
    }

    const {value: senha} = await Swal.fire({
      input: "password",
      title: 'Digite a senha de acesso.',
      confirmButtonText: 'Próximo',
      cancelButtonText: 'Cancelar',
      showCancelButton: true
    });

    if (!senha || senha.length < 4) {
      Swal.fire({icon: "warning", title: 'O valor digitado não é válido'});
      return false;
    }

    const {value: url} = await Swal.fire({
      input: "text",
      title: 'Digite a URL de acesso.',
      confirmButtonText: 'Cadastrar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true
    });

    if (!url || url.length < 10) {
      Swal.fire({icon: "warning", title: 'A URL digitada não é válida.'});
      return false;
    }

    const {ok} = await WebService(`/servico/megasoftCadastrarEmpresa`, {cpf, senha, url}, 'POST');
    if (ok) {
      await Swal.fire({icon: 'success', title: 'Empresa cadastrada com sucesso!'});
      getEmpresas();
    }
  };

  return (
    <GridContainer>
      <GridItem lg={3} md={4} sm={6} xs={12}>
        <Button color={'primary'} fullWidth onClick={adicionarEmpresa.bind(this)}>
          Adicionar empresa
        </Button>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardBody>
            <Table
              tableHead={['#', 'Razão Social', 'CPF', 'CNPJ', 'Inscrição Municipal', 'Situação', 'Opções']}
              tableData={empresas.map(prop => [
                prop.id_empresa_servico_megasoft,
                prop.nome_razao,
                formataCPFCNPJ(prop.cpf),
                formataCPFCNPJ(prop.cnpj),
                prop.inscricao_municipal,
                prop.cadastrado ? (<Success>Cadastrado</Success>) : (<Danger>Não cadastrado</Danger>),
                (
                  <>
                    <Tooltip title={'Excluir'}>
                      <Button
                        color={'danger'}
                        justIcon
                        round
                        simple
                        onClick={excluirEmpresa.bind(this, prop.id_empresa_servico_megasoft)}
                      >
                        <Close/>
                      </Button>
                    </Tooltip>
                  </>
                )
              ])}
              hover
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Megasoft;
