import React, {useState} from "react";
import * as Swal from "sweetalert2";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import Business from "@material-ui/icons/Business";
import CardBody from "../../components/Card/CardBody";
import GridItem from "../../components/Grid/GridItem";
import Table from "../../components/Table/Table";
import {cardTitle, infoColor} from "../../assets/jss/material-dashboard-pro-react";
import {makeStyles} from "@material-ui/core/styles";
import Info from "../../components/Typography/Info";
import Warning from "../../components/Typography/Warning";
import {WebService} from "../../components/WebService";
import Success from "../../components/Typography/Success";
import Danger from "../../components/Typography/Danger";
import {buscarDadosCNPJ, undefinedFunction} from "../../components/Funcoes";
import Button from "../../components/CustomButtons/Button";
import extendedTablesStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import {ArrowBack, CheckCircle, Cloud, FindInPage, PlayCircleFilled, Settings} from "@material-ui/icons";
import AjudaSistema from "../../components/Help/AjudaSistema";
import {AjudaCadastroEmMassa} from "../../components/Help/mensagens";
import {ProgressBar, Step} from "react-step-progress-bar";
import {Tooltip} from "@material-ui/core";

const styles = {
  cardIconTitle: {...cardTitle, marginTop: "15px", marginBottom: "0px"},
  ...extendedTablesStyle,
  ...buttonStyle,
  ...dashboardStyle
};

const useStyles = makeStyles(styles);

export default function CadastrarEmpresaMassa({history}) {
  const classes = useStyles();

  const [empresas, setEmpresas] = useState([]);
  const [importando, setImportando] = useState(false);
  const [percentual, setPercentual] = useState(0);

  const handleImageChange = async e => {
    setImportando(true);
    e.preventDefault();
    const {files} = e.target;
    const tempEmpresas = [];
    let totalEmpresas = files.length * 4;
    let contAtual = 0;
    for (let i = 0; i < files.length; i++) {
      contAtual++;
      setPercentual((100 / totalEmpresas) * contAtual);
      const result = await new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(files[i]);
      });
      let {name} = files[i];
      name = name.split(".")[0];
      tempEmpresas.push({
        certificado: result,
        status: <Info>Obtendo dados.</Info>,
        nomeArquivo: name,
        flag_erro: false,
        senha: name.split("_")[1] || (<Warning>Sem senha</Warning>)
      });
    }
    setEmpresas(tempEmpresas);

    // * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - *
    for (let i = 0; i < tempEmpresas.length; i++) {
      contAtual++;
      setPercentual((100 / totalEmpresas) * contAtual);
      const {senha, certificado} = tempEmpresas[i];
      const arquivo = certificado;
      if (senha && (typeof senha === "string")) {
        const {ok, mensagem_usuario, razao: razao_social, cnpj, flag_assinado, uuid, data_final} = await WebService(`/certificado/upload`, {
          arquivo,
          senha
        }, "POST", false, false);
        tempEmpresas[i].razao_social = razao_social;
        tempEmpresas[i].cnpj = cnpj;
        if (ok) {
          if (flag_assinado) {
            const certificadoVencido = new Date(data_final) < new Date();
            tempEmpresas[i].uuid = uuid;
            tempEmpresas[i].data_final_string = data_final;
            tempEmpresas[i].data_final = data_final;
            tempEmpresas[i].status = certificadoVencido ? (
              <Danger>395 - Certificado vencido</Danger>
            ) : (
              <Info>Processado</Info>
            );
            if (certificadoVencido)
              tempEmpresas[i].flag_erro = true;
          } else {
            tempEmpresas[i].flag_erro = true;
            tempEmpresas[i].status = <Danger>397 - Certificado sem chave privada.</Danger>;
          }
        } else {
          tempEmpresas[i].flag_erro = true;
          tempEmpresas[i].status = <Danger>{mensagem_usuario}</Danger>;
        }
      } else {
        tempEmpresas[i].status = <Danger>Certificado sem senha</Danger>;
        tempEmpresas[i].flag_erro = true;
      }
      setEmpresas([...tempEmpresas]);
    }

    // * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - *
    for (let i = 0; i < tempEmpresas.length; i++) {
      contAtual++;
      setPercentual((100 / totalEmpresas) * contAtual);
      const {flag_erro, cnpj} = tempEmpresas[i];
      if (!flag_erro) {
        const {ok, empresa} = await buscarDadosCNPJ(cnpj, false, false);
        if (ok) {
          const {nome_fantasia, cnae_fiscal, uf} = empresa;
          tempEmpresas[i] = {...tempEmpresas[i], nome_fantasia, cnae_fiscal, uf};
        } else {
          tempEmpresas[i].status = <Danger>Dados automáticos da empresa não encontrados.</Danger>;
          tempEmpresas[i].flag_erro = true;
        }
      }
      setEmpresas([...tempEmpresas]);
    }

    // * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - *
    for (let i = 0; i < tempEmpresas.length; i++) {
      contAtual++;
      setPercentual((100 / totalEmpresas) * contAtual);
      const {flag_erro} = tempEmpresas[i];
      if (!flag_erro) {
        tempEmpresas[i].senha_certificado = tempEmpresas[i].senha;
        const {ok, mensagem_usuario} = await WebService(`/empresa/cadastrarEmpresa`, tempEmpresas[i], "POST", false, false);
        if (ok) {
          tempEmpresas[i].status = <Success>{mensagem_usuario || "Empresa cadastrado com sucesso!"}</Success>;
        } else {
          tempEmpresas[i].status = <Danger>Não foi possível cadastrar a empresa: [{mensagem_usuario}]</Danger>;
        }
        setEmpresas([...tempEmpresas]);
      }
    }
    setImportando(false);
    const totalErros = tempEmpresas.filter(el => el.flag_erro).length;
    Swal.fire({
      icon: totalErros ? "info" : "success",
      title: `Importação finalizada ${totalErros ? 'com' : 'sem'} erros.`,
      html: totalErros ? `A importação não conseguiu carregar ${totalErros} certificados.<br/>Veja os detalhes em '<strong>Imprimir erros</strong>' no canto superior direito.` : ''
    });
  };

  const imprimirErros = () => {
    let html = `<style>td {border: 1px solid #000;}</style><table style='width: 100%'>`;
    html += `<tr>`;
    html += `    <th>Nome do Arquivo</th>`;
    html += `    <th>Senha reconhecida</th>`;
    html += `    <th>Razão Social</th>`;
    html += `    <th>Status</th>`;
    html += `</tr>`;
    for (let i = 0; i < empresas.length; i++) {
      let {flag_erro, status, nomeArquivo, razao_social, senha} = empresas[i];
      status = typeof status === "object" ? status.props.children : status;
      if (flag_erro) {
        html += `<tr>`;
        html += `    <td>${nomeArquivo}.pfx</td>`;
        html += `    <td>${senha}</td>`;
        html += `    <td>${razao_social}</td>`;
        html += `    <td>${status}</td>`;
        html += `</tr>`;
      }
    }
    html += `</table>`;
    const errosHTML = window.open('', '', 'height=400,width=800');
    errosHTML.document.write(html);
    errosHTML.document.close();
    errosHTML.print();
  };

  const importarArquivoLista = async e => {
    setImportando(true);
    e.preventDefault();
    const [file] = e.target.files;

    setPercentual(0);

    let tempEmpresas = await new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = () => resolve(atob(reader.result.split(",")[1]));
      reader.readAsDataURL(file);
    });
    if (tempEmpresas.slice(0, 3) === "ï»¿") tempEmpresas = tempEmpresas.slice(3);

    // let {name} = file;
    // name = name.split(".")[0];

    tempEmpresas = tempEmpresas.split("\n").filter(el => el).map(prop => prop.split(",")).map(prop => ({
      cnpj: prop[0],
      razao_social: prop[1],
      flag_erro: false,
      status: '',
      nome_fantasia: '',
      cnae_fiscal: '',
      uf: '',
      senha: ''
    }));

    let totalEmpresas = tempEmpresas.length * 4;
    let contAtual = 0;

    setEmpresas([...tempEmpresas]);
    for (let i = 0; i < tempEmpresas.length * 2; i++) contAtual++;
    setPercentual((100 / totalEmpresas) * contAtual);

    for (let i = 0; i < tempEmpresas.length; i++) {
      contAtual++;
      setPercentual((100 / totalEmpresas) * contAtual);
      const {flag_erro, cnpj} = tempEmpresas[i];
      if (!flag_erro) {
        const {ok, empresa} = await buscarDadosCNPJ(cnpj, false, false);
        if (ok) {
          const {nome_fantasia, cnae_fiscal, uf} = empresa;
          tempEmpresas[i] = {...tempEmpresas[i], nome_fantasia, cnae_fiscal, uf};
        } else {
          tempEmpresas[i].status = <Danger>Dados automáticos da empresa não encontrados.</Danger>;
          tempEmpresas[i].flag_erro = true;
        }
      }
      setEmpresas([...tempEmpresas]);
    }

    //******************************************************************************************

    for (let i = 0; i < tempEmpresas.length; i++) {
      contAtual++;
      setPercentual((100 / totalEmpresas) * contAtual);
      const {flag_erro} = tempEmpresas[i];
      if (!flag_erro) {
        tempEmpresas[i].senha_certificado = tempEmpresas[i].senha;
        const {ok, mensagem_usuario} = await WebService(`/empresa/cadastrarEmpresa`, tempEmpresas[i], "POST", false, false);
        if (ok)
          tempEmpresas[i].status = <Success>{mensagem_usuario || "Empresa cadastrado com sucesso!"}</Success>;
        else
          tempEmpresas[i].status = <Danger>Não foi possível cadastrar a empresa: [{mensagem_usuario}]</Danger>;
        setEmpresas([...tempEmpresas]);
      }
    }
    setImportando(false);
  };

  let uploadInput = {click: undefinedFunction};
  let uploadListaInput = {click: undefinedFunction};

  return (
    <GridContainer>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Button color={"info"} onClick={() => history.goBack()}>
          <ArrowBack/> Voltar
        </Button>
        <Button onClick={() => uploadInput.click()} color={'info'} disabled={importando}>
          Carregar certificados
        </Button>
        <Button onClick={() => uploadListaInput.click()} color={'info'} disabled={importando}>
          Importar arquivo
        </Button>
        <input
          type="file"
          style={{display: "none"}}
          onChange={handleImageChange}
          ref={ref => uploadInput = ref}
          accept={".pfx"}
          multiple
        />
        <input
          type="file"
          style={{display: "none"}}
          onChange={importarArquivoLista}
          ref={ref => uploadListaInput = ref}
          accept={".csv,.txt"}
        />
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card plain>
          <CardBody>
            <ProgressBar
              percent={percentual}
              filledBackground={`linear-gradient(to right, ${infoColor[4]}, ${infoColor[3]})`}
            >
              <Step transition="scale">
                {({accomplished}) => (
                  <Tooltip title={"Carregando certificados"}>
                    <div
                      style={{
                        filter: `grayscale(${accomplished ? 0 : 80}%)`,
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        height: 40
                      }}
                    >
                      <Info><PlayCircleFilled style={{fontSize: 40}}/></Info>
                    </div>
                  </Tooltip>
                )}
              </Step>
              <Step transition="scale">
                {({accomplished}) => (
                  <Tooltip title={"Buscando informações do certificado"}>
                    <div
                      style={{
                        filter: `grayscale(${accomplished ? 0 : 80}%)`,
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        height: 40
                      }}
                    >
                      <Info><FindInPage style={{fontSize: 40}}/></Info>
                    </div>
                  </Tooltip>
                )}
              </Step>
              <Step transition="scale">
                {({accomplished}) => (
                  <Tooltip title={"Buscando informações da empresa"}>
                    <div
                      style={{
                        filter: `grayscale(${accomplished ? 0 : 80}%)`,
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        height: 40
                      }}
                    >
                      <Info><Cloud style={{fontSize: 40}}/></Info>
                    </div>
                  </Tooltip>
                )}
              </Step>
              <Step transition="scale">
                {({accomplished}) => (
                  <Tooltip title={"Realizando cadastro"}>
                    <div
                      style={{
                        filter: `grayscale(${accomplished ? 0 : 80}%)`,
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        height: 40
                      }}
                    >
                      <Info><Settings style={{fontSize: 40}}/></Info>
                    </div>
                  </Tooltip>
                )}
              </Step>
              <Step transition="scale">
                {({accomplished}) => (
                  <Tooltip title={"Processo finalizado"}>
                    <div
                      style={{
                        filter: `grayscale(${accomplished ? 0 : 80}%)`,
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        height: 40
                      }}
                    >
                      <Info><CheckCircle style={{fontSize: 40}}/></Info>
                    </div>
                  </Tooltip>
                )}
              </Step>
            </ProgressBar>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <Business/>
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Certificados</h4>
            {(!importando && empresas.length) ? (
              <Button simple color={"info"} style={{marginTop: -30, right: 0, position: 'absolute'}}
                      onClick={imprimirErros.bind(this)}>
                Imprimir erros
              </Button>
            ) : ""}
          </CardHeader>
          <CardBody>
            <Table
              tableHead={["CNPJ", "Razão Social", "Nome Fantasia", "CNAE", "UF", "Senha", "Vencimento", "Status"]}
              tableData={empresas.map(prop => {
                const {status, senha, razao_social, cnpj, nome_fantasia, cnae_fiscal, uf} = prop;
                // const certificadoVencido = new Date(data_final) < new Date();
                return [
                  cnpj,
                  razao_social,
                  nome_fantasia,
                  cnae_fiscal,
                  uf,
                  senha,
                  "",
                  /*certificadoVencido ? (
                    <Warning>{convertDate(data_final, false, true)}</Warning>
                  ) : (
                    <Success>{convertDate(data_final, false, true)}</Success>
                  ),*/
                  status
                ]
              })}
              //              }).sort((a, b) => (a[7].flag_erro < b[7].flag_erro) ? -1 : ((a[7].flag_erro > b[7].flag_erro) ? 1 : 0)).sort((a, b) => (a[7].props.children < b[7].props.children) ? -1 : ((a[7].props.children > b[7].props.children) ? 1 : 0))}
              hover
            />
          </CardBody>
        </Card>
      </GridItem>
      <AjudaSistema slug={"cadastro_empresa_massa"} conteudo={AjudaCadastroEmMassa}/>
    </GridContainer>
  );
}
