export const permicoesUsuario = [
  {
    descricao: 'Dashboard',
    slug: 'dashboard',
    pode_ver: false,
    pode_criar: false,
    pode_editar: false,
    pode_excluir: false
  },
  {
    descricao: 'Empresas',
    slug: 'empresas',
    pode_ver: false,
    pode_criar: false,
    pode_editar: false,
    pode_excluir: false
  },
  {
    descricao: 'Notas',
    slug: 'notas',
    pode_ver: false,
    pode_criar: false,
    pode_editar: false,
    pode_excluir: false
  },
  {
    descricao: 'Configurações',
    slug: 'configuracoes',
    pode_ver: false,
    pode_criar: false,
    pode_editar: false,
    pode_excluir: false
  }
];
