import React from "react";
import Wizard from "../../components/Wizard/Wizard";
import Step1 from "./Cadastro/Step1";
import Step2 from "./Cadastro/Step2";
import {WebService} from "../../components/WebService";
import * as Swal from "sweetalert2";
import Button from "../../components/CustomButtons/Button";
import {ArrowBack} from "@material-ui/icons";

export default function CadastrarEmpresa({history, flag_contabilidade}) {
  const label = (flag_contabilidade ? "Contabilidade" : "Empresa");

  const cadastrarEmpresa = async ({certificado, dados_empresa}) => {
    const {ok} = await WebService(`/empresa/cadastrarEmpresa`, {...certificado, ...dados_empresa, flag_contabilidade}, "POST");
    if (ok) {
      await Swal.fire({icon: "success", title: `${label} cadastrada com sucesso!`});
      history.push(`/${label.toLowerCase()}s`);
    }
  };

  return (
    <>
      <Button color={"info"} onClick={() => history.goBack()}>
        <ArrowBack/> Voltar
      </Button>
      <Wizard
        nextButtonText={"Próximo"}
        previousButtonText={"Anterior"}
        color={"info"}
        validate
        steps={[
          {stepName: "Certificado", stepComponent: Step1, stepId: "certificado"},
          {stepName: `Dados da ${label}`, stepComponent: Step2, stepId: "dados_empresa"}
        ]}
        title={`Cadastrar ${label}`}
        subtitle=""
        finishButtonText={"Cadastrar"}
        finishButtonClick={cadastrarEmpresa}
      />
    </>
  );
}
