import React from "react";

class SpanH2 extends React.Component {
    render() {
        return (
            <h2
                style={{
                    color: '#9d9ea0',
                    // fontFamily: 'Poppins-Light',
                    textTransform: this.props.lower || 'uppercase',
                    fontWeight: 700,
                    marginBottom: 0,
                    fontSize: '1.3rem',
                    ...this.props.style
                }}
                onClick={this.props.onClick}
            >
                {this.props.children}
            </h2>
        );
    }
}

export default SpanH2;


