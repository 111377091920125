import React, {useEffect, useLayoutEffect, useState} from "react";
import cx from "classnames";
import {Switch, Redirect} from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin.js";

import styles from "../assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import {ls} from "../components/Funcoes";
import {LoadRouterProps} from "../components/DataModule";

var ps;

const useStyles = makeStyles(styles);

export default function Dashboard({token, ...rest}) {

  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);
  const [image, setImage] = useState(require("assets/img/sidebar-2.jpg"));
  const [routes, setRoutes] = useState(require("../routes").default);
  const [color, setColor] = useState("blue");
  const [bgColor, setBgColor] = useState("white");
  const [webSocket, setWebSocket] = useState(null);

  const [fixedClasses, setFixedClasses] = useState("dropdown");

  const [logo, setLogo] = useState(require("../assets/img/logo.svg"));

  const classes = useStyles();
  const mainPanelClasses = classes.mainPanel + " " + cx({
    [classes.mainPanelSidebarMini]: miniActive,
    [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
  });

  const mainPanel = React.createRef();

  useEffect(() => {
    const {default: routes} = require("../routes");
    setRoutes(routes);
  }, [token]);

  const conectarWebSocket = () => {
    const conn = new WebSocket(process.env.NODE_ENV === 'production' ? 'wss://api.gefis.com.br:19655' : 'wss://127.0.0.1:19655');
    conn.onopen = function () {
      console.log("Conexão realizada");
      conn.send(JSON.stringify({token: ls('token'), metodo: 'login'}));
    };

    conn.addEventListener("message", function ({data}) {
      data = JSON.parse(data);
      const {autenticado, mensagemCarregando} = data;
      if (autenticado)
        setWebSocket(conn);
      if (mensagemCarregando) {
        if (document.getElementById("labelCarregando"))
          document.getElementById("labelCarregando").innerText = mensagemCarregando;
        localStorage.setItem("ultimaMensagemCarregando", mensagemCarregando);
      }
    });

    conn.onclose = function (e) {
      console.log(`Socket foi finalizado. Reconectando em 1 segundo. [${e.reason}]`);
      setTimeout(conectarWebSocket, 1000);
    };

    conn.onerror = function (err) {
      console.error(`Socket encountered error: ${err.message} Closing socket`);
      conn.close();
    };
  };

  useEffect(() => {
    conectarWebSocket();
  }, []);

  useEffect(() => {
    if (window.Conpass)
      window.Conpass.init({
        name: ls(`usuario_nome`),
        email: ls(`usuario_email`),
        custom_fields: {
          id: ls(`usuario_id`),
          sistema: 'gefis-v1'
        }
      });
  }, [window.Conpass]);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  // functions for changeing the states from components
  const handleImageClick = setImage;
  const handleColorClick = setColor;

  const handleBgColorClick = bgColor => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg"));
        break;
      default:
        setLogo(require("assets/img/logo-white.svg"));
        break;
    }
    setBgColor(bgColor);
  };

  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    // return window.location.pathname.indexOf("/contabilidades/") === -1
    return true;
  };
  const getActiveRoute = routes => {
    let activeRoute = "Gefis";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) return collapseActiveRoute;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) return routes[i].name;
    }
    return activeRoute;
  };

  const getRoutes = routes => routes.map((prop, key) => {
      const {collapse, views, layout, path, component, params} = prop;
      if (collapse) return getRoutes(views);

      return (layout === "/admin") ? (
        <LoadRouterProps path={path} component={component} key={key} webSocket={webSocket} {...params}/>
      ) : null;
    }
  );

  const sidebarMinimize = () => setMiniActive(!miniActive);

  const resizeFunction = () => {
    if (window.innerWidth >= 960) setMobileOpen(false);
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"Creative Tim"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch atEnter={{opacity: 0}} atLeave={{opacity: 0}} atActive={{opacity: 1}}
                      className="switch-wrapper">
                {getRoutes(routes)}
                <Redirect from="/" to="/painel"/>
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch atEnter={{opacity: 0}} atLeave={{opacity: 0}} atActive={{opacity: 1}}
                    className="switch-wrapper">
              {getRoutes(routes)}
              <Redirect from="/" to="/painel"/>
            </Switch>
          </div>
        )}
        {getRoute() ? <Footer fluid/> : null}
        <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          handleBgColorClick={handleBgColorClick}
          color={color}
          bgColor={bgColor}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
        />
      </div>
    </div>
  );
}
