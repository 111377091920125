import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/icons/Menu";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Button from "components/CustomButtons/Button";

import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => setOpen(!open);
  const activeRoute = routeName => (window.location.href.indexOf(routeName) > -1);
  const classes = useStyles();
  const {color, brandText} = props;
  const appBarClasses = cx({[" " + classes[color]]: color});

  var list = (
    <List className={classes.list}>
      {/*<ListItem className={classes.listItem}>
                <NavLink to={"/admin/dashboard"} className={classes.navLink}>
                    <Dashboard className={classes.listItemIcon}/>
                    <ListItemText
                        primary={"Dashboard"}
                        disableTypography={true}
                        className={classes.listItemText}
                    />
                </NavLink>
            </ListItem>
            <ListItem className={classes.listItem}>
                <NavLink
                    to={"/auth/pricing-page"}
                    className={cx(classes.navLink, {
                        [classes.navLinkActive]: activeRoute("/auth/pricing-page")
                    })}
                >
                    <MonetizationOn className={classes.listItemIcon}/>
                    <ListItemText
                        primary={"Pricing"}
                        disableTypography={true}
                        className={classes.listItemText}
                    />
                </NavLink>
            </ListItem>*/}
      <ListItem className={classes.listItem} onClick={handleDrawerToggle}>
        <NavLink
          to={"/login"}
          className={cx(classes.navLink, {[classes.navLinkActive]: activeRoute("/login")})}
        >
          <Fingerprint className={classes.listItemIcon}/>
          <ListItemText
            primary={"Login"}
            disableTypography={true}
            className={classes.listItemText}
          />
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem} onClick={handleDrawerToggle}>
        <NavLink
          to={"/cadastro"}
          className={cx(classes.navLink, {[classes.navLinkActive]: activeRoute("/cadastro")})}
        >
          <PersonAdd className={classes.listItemIcon}/>
          <ListItemText
            primary={"Cadastro"}
            disableTypography={true}
            className={classes.listItemText}
          />
        </NavLink>
      </ListItem>
      {/*<ListItem className={classes.listItem}>
                <NavLink
                    to={"/auth/lock-screen-page"}
                    className={cx(classes.navLink, {
                        [classes.navLinkActive]: activeRoute("/auth/lock-screen-page")
                    })}
                >
                    <LockOpen className={classes.listItemIcon}/>
                    <ListItemText
                        primary={"Lock"}
                        disableTypography={true}
                        className={classes.listItemText}
                    />
                </NavLink>
            </ListItem>*/}
    </List>
  );

  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={classes.flex}>
            <Button href="#" className={classes.title} color="transparent">
              {brandText}
            </Button>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.flex}>
            <Button href="#" className={classes.title} color="transparent">
              Gefis - Inteligência Fiscal
            </Button>
          </div>
        </Hidden>
        <Hidden smDown>{list}</Hidden>
        <Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu/>
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={open}
              classes={{paper: classes.drawerPaper}}
              onClose={handleDrawerToggle}
              ModalProps={{keepMounted: true}}
            >
              {list}
            </Drawer>
          </Hidden>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string
};
