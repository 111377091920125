import React from "react";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomLinearProgress from "../../components/CustomLinearProgress/CustomLinearProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Button from "../../components/CustomButtons/Button";
import Zoom from "@material-ui/core/Zoom";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Check from "@material-ui/icons/Check";
import Table from "../../components/Table/Table.js";
import extendedTablesStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import {WebService} from "../../components/WebService";

const Transition = props => <Zoom direction="down" {...props} />;

class LiveSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valor: "",
      interval: 100,
      dados: [],
      open: false
    };
  }

  async mudarValor(id, valor) {
    await this.setState({id, valor, open: false});
    const {onChange, clearValueOnChange} = this.props;
    if (clearValueOnChange)
      await this.setState({valor: ""});
    if (onChange)
      onChange(id, valor);
  }

  async pesquisarWS(valor) {
    const url = this.props.url;
    const {ok, dados} = await WebService(url, {q: valor.trim()});
    if (ok) {
      this.setState({dados: dados});
      if (dados.length) {
        if (dados.length === 1) {
          const {id, valor} = dados[0];
          this.mudarValor(id, valor);
        } else {
          this.setState({open: true});
        }
      } else {
        this.mudarValor("", "");
        Swal.fire({
          icon: "info",
          title: "Não encontrado.",
          html: `Não foi encontrado nenhum <b>${this.props.label}</b> para <b>${valor}</b>`
        });
      }
    }
  }

  async pesquisar(e) {
    const tempo = 1500;

    clearTimeout(this.timeout);
    clearInterval(this.interval);

    const valor = e.target.value;
    await this.setState({valor, interval: 100});

    this.interval = setInterval(() => {
      this.setState({interval: this.state.interval - 10});
    }, (tempo / (tempo / 10)) * 10);

    this.timeout = setTimeout(() => {
      clearInterval(this.interval);
      this.setState({interval: 100});
      if (valor)
        this.pesquisarWS(valor);
    }, tempo);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {limpar} = nextProps;
    if (limpar === true) {
      this.setState({id: "", valor: "", open: false});
    }
  }

  render() {
    const {classes} = this.props;
    return (
      <div>
        <Dialog
          fullWidth
          classes={{root: classes.center + " " + classes.modalRoot, paper: classes.modal}}
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            this.setState({open: false});
          }}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
            <h4 className={classes.modalTitle}>Informe o {this.props.label}</h4>
          </DialogTitle>
          <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
            <GridContainer>
              <GridItem md={12} xs={12} sm={12} lg={12}>
                <Table
                  hover
                  tableHead={["#", this.props.label, ""]}
                  tableData={this.state.dados.map(prop => {
                    const {id, valor} = prop;
                    return [
                      id,
                      valor,
                      (
                        <Button
                          simple
                          round
                          className={classes.actionButton}
                          onClick={() => {
                            this.mudarValor(id, valor);
                          }}
                          color={"success"}
                        >
                          <Check/>
                        </Button>
                      )
                    ];
                  })}
                  customCellClasses={[classes.right]}
                  customClassesForCells={[2]}
                  customHeadCellClasses={[classes.right]}
                  customHeadClassesForCells={[2]}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
        <CustomInput
          labelText={<span>{this.props.label}</span>}
          formControlProps={{fullWidth: true}}
          inputProps={{
            type: "text",
            onChange: this.pesquisar.bind(this),
            value: this.state.valor
          }}
        />
        <CustomLinearProgress
          style={{marginTop: -18}}
          variant="determinate"
          color={"info"}
          value={this.state.interval}
        />
      </div>
    );
  }
}

const styles = {
  ...extendedTablesStyle,
  ...buttonStyle,
  ...dashboardStyle,
  // ...modalEstilo
};

LiveSearch.propTypes = {
  onChange: PropTypes.any,
  url: PropTypes.string,
  label: PropTypes.string
};
export default withStyles(styles)(LiveSearch);
