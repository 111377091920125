import React, {useEffect, useState} from "react";
import NavPills from "../../../components/NavPills/NavPills";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CardBody from "../../../components/Card/CardBody";
import Card from "../../../components/Card/Card";
import ResponsiveTable from "../../../components/ResponsiveTable";
import Button from "../../../components/CustomButtons/Button";
import {WebService} from "../../../components/WebService";
import {estados, cidades, enderecos} from "../../../variables/WebISS";
import * as Swal from "sweetalert2";
import CardHeader from "../../../components/Card/CardHeader";
import CardText from "../../../components/Card/CardText";
import {cardTitle} from "../../../assets/jss/material-dashboard-pro-react";
import {makeStyles} from "@material-ui/core/styles";
import Info from "../../../components/Typography/Info";
import Warning from "../../../components/Typography/Warning";
import {Tooltip} from "@material-ui/core";
import Add from "@material-ui/icons/Add";

const styles = {
  cardTitle,
  cardTitleWhite: {...cardTitle, color: "#FFFFFF", marginTop: "0"},
  cardCategoryWhite: {margin: "0", color: "rgba(255, 255, 255, 0.8)", fontSize: ".875rem"}
};

const useStyles = makeStyles(styles);
export default function WebISS() {
  const classes = useStyles();

  const [contas, setContas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [active, setActive] = useState(0);

  const getClientes = async () => {
    if (!contas.length)
      return;
    const {id_empresa_webiss} = contas[active];
    const {ok, clientes} = await WebService(`/webiss/getClienteLocal`, {id_empresa_webiss});
    if (ok) {
      setClientes(clientes);
    }
  };

  const getContasEmpresa = async () => {
    const {ok, contas} = await WebService(`/webiss/getContasEmpresa`);
    if (ok) {
      setContas(contas);
    }
  };

  const adicionarContaWebISS = async () => {
    const estadosSelect = {};
    const cidadesSelect = {};
    estados.map((prop, key) => estadosSelect[key] = `${prop[0]} - ${prop[1]}`);
    const {value: estadoSelecionado} = await Swal.fire({
      title: 'Selecione o estado',
      input: 'select',
      inputOptions: estadosSelect,
      inputPlaceholder: 'Selecione o estado',
      showCancelButton: true,
      inputValidator: value => new Promise((resolve) => resolve(value ? undefined : "Selecione um estado válido"))
    });
    if (!estadoSelecionado)
      return;
    cidades[estadoSelecionado].map((prop, key) => cidadesSelect[key] = prop);
    const {value: cidadeSelecionado} = await Swal.fire({
      title: 'Selecione a cidade',
      input: 'select',
      inputOptions: cidadesSelect,
      inputPlaceholder: 'Selecione o cidade',
      showCancelButton: true,
      inputValidator: value => new Promise((resolve) => resolve(value ? undefined : "Selecione uma cidade válido"))
    });
    if (!cidadeSelecionado)
      return;
    const {value: usuario} = await Swal.fire({
      title: 'Digite seu Login de acesso.',
      input: 'text',
      showCancelButton: true,
      inputValidator: value => value ? undefined : "Digite um login válido"
    });
    if (!usuario)
      return;
    const {value: senha} = await Swal.fire({
      title: 'Digite sua senha de acesso.',
      input: 'password',
      showCancelButton: true,
      inputValidator: value => value ? undefined : "Digite uma senha válida"
    });
    if (!senha)
      return;
    const cidade = cidades[estadoSelecionado][cidadeSelecionado];
    const estado = estados[estadoSelecionado][0];
    const dados = {
      usuario,
      senha,
      url: enderecos[cidades[estadoSelecionado][cidadeSelecionado]],
      local: `${cidade} - ${estado}`
    };

    const {ok} = await WebService(`/webiss/adicionarContaEmpresa`, dados, "POST");
    if (ok) {
      await getContasEmpresa();
      await getClientes();
    }
  };

  const atualizarEmpresasWebISS = async () => {
    const {ok} = await WebService(`/webiss/atualizarEmpresasWebISS`);
    if (ok) {
      await Swal.fire({icon: "success", title: "Empresas atualizadas com sucesso!"});
      await getContasEmpresa();
    }
  };

  const cadastrarEmpresa = async (cnpj = undefined) => {
    if (typeof cnpj !== "string") cnpj = undefined;
    const {ok} = await WebService(`/webiss/cadastrarEmpresas`, {cnpj});
    if (ok) {
      await Swal.fire({icon: "success", title: `Empresa${cnpj ? '' : 's'} cadastradas com sucesso!`});
      await getClientes();
    }
  };

  useEffect(() => {
    getContasEmpresa();
  }, []);

  useEffect(() => {
    getClientes();
    // eslint-disable-next-line
  }, [active, contas]);

  return (
    <div>
      <GridContainer>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <Card>
            <GridContainer>
              <GridItem lg={3} md={4} sm={4} xs={6}>
                <Button color={"info"} simple fullWidth onClick={adicionarContaWebISS.bind(this)}>
                  Adicionar
                </Button>
              </GridItem>
              <GridItem lg={3} md={4} sm={4} xs={6}>
                <Button color={"info"} simple fullWidth onClick={atualizarEmpresasWebISS.bind(this)}>
                  Atualizar Empresas
                </Button>
              </GridItem>
              <GridItem lg={3} md={4} sm={4} xs={6}>
                <Button color={"info"} simple fullWidth onClick={cadastrarEmpresa.bind(this)}>
                  Cadastrar Empresas
                </Button>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <NavPills
            color="info"
            alignCenter
            onChange={setActive}
            tabs={contas.map(prop => ({tabButton: prop.local}))}
          />
        </GridItem>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>{contas.length ? contas[active].local : ""}</h4>
                <h4 className={classes.cardCategoryWhite}>
                  Total: {clientes.length} empresas
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <ResponsiveTable
                colunas={["CNPJ", "Razão Social", "Inscrição Municipal", "Situação", "Status", ""]}
                dados={clientes.map(prop => {
                  const {razao_social, inscricao_municipal, flag_baixado, cnpj, id_empresa} = prop;
                  return [
                    cnpj,
                    razao_social,
                    inscricao_municipal,
                    (flag_baixado ? "Baixado" : "Ativo"),
                    (id_empresa ?
                        <Info>Cadastrado</Info> :
                        <Warning>Sem correspondencia no cadastro de empresa.</Warning>
                    ),
                    (
                      <Tooltip title={"Cadastrar empresa"}>
                        <Button color={"info"} justIcon round simple onClick={() => cadastrarEmpresa(cnpj)}>
                          <Add/>
                        </Button>
                      </Tooltip>
                    )
                  ];
                })}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
