import React, {useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Logo from "../../assets/img/logo.png";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import {FloatingMenu, MainButton} from "react-floating-button-menu";
import {cardTitle, infoColor} from "../../assets/jss/material-dashboard-pro-react";
import {Close, HelpOutline} from "@material-ui/icons";
import Zoom from "@material-ui/core/Zoom";
import extendedTablesStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import {makeStyles} from "@material-ui/core/styles";

const Transition = props => <Zoom direction="down" {...props} />;
const styles = {
  cardIconTitle: {...cardTitle, marginTop: "15px", marginBottom: "0px"},
  ...extendedTablesStyle,
  ...buttonStyle,
  ...dashboardStyle
};

const useStyles = makeStyles(styles);

export default function AjudaSistema({conteudo, slug}) {
  const classes = useStyles();
  slug = `tutorial_${slug}`;

  const [modalTutorialOpen, setModalTutorialOpen] = useState(!localStorage.getItem(slug));

  return (
    <div>
      <Dialog
        fullWidth
        classes={{root: classes.center + " " + classes.modalRoot, paper: classes.modal}}
        open={modalTutorialOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModalTutorialOpen(false)}
      >
        <DialogTitle>
          <img src={Logo} alt={"Logo-Gefis"} style={{height: 140, marginBottom: -40}}/>
        </DialogTitle>
        <DialogContent>
          <div className={classes.typo} style={{textAlign: 'left'}}>
            {conteudo}
          </div>
        </DialogContent>
        <DialogActions>
          <GridContainer style={{width: '100%', margin: 0, padding: 0}}>
            <GridItem lg={6} md={6} sm={6} xs={6}>
              <Button
                color={"danger"}
                simple
                fullWidth
                onClick={() => {
                  localStorage.removeItem(slug);
                  setModalTutorialOpen(false);
                }}
              >
                Me mostre mais tarde.
              </Button>
            </GridItem>
            <GridItem lg={6} md={6} sm={6} xs={6}>
              <Button
                color={"info"}
                simple
                fullWidth
                onClick={() => {
                  localStorage.setItem(slug, "true");
                  setModalTutorialOpen(false);
                }}
              >
                Maravilha, Vamos lá!
              </Button>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </Dialog>
      <FloatingMenu
        slideSpeed={500}
        direction="up"
        spacing={8}
        isOpen={modalTutorialOpen}
        style={{position: 'fixed', zIndex: 10, bottom: 30, right: 30, backgroundColor: '#FFF', borderRadius: '50%'}}
      >
        <MainButton
          style={{color: infoColor[0]}}
          iconResting={<HelpOutline style={{fontSize: 20}} nativeColor="white"/>}
          iconActive={<Close style={{fontSize: 20}} nativeColor="white"/>}
          backgroundColor="black"
          onClick={() => setModalTutorialOpen(!modalTutorialOpen)}
          size={56}
        />
      </FloatingMenu>
    </div>
  )
}
