import Muted from "../Typography/Muted";
import React from "react";

export const AjudaCadastroEmMassa = (
  <>
    <h3>
      <small>
        <strong>É possível sim cadastrar todas as suas empresas de uma vez só!</strong>
      </small>
    </h3>
    <Muted>
      1º - Guarde todos os certificados digitais numa única pasta.
    </Muted>
    <Muted>
      2º - Cada certificado deve conter a senha junto ao nome do arquivo separado por <i>underline</i> "_".
      <br/>
      <small>
        &nbsp;&nbsp;Ex: <strong>NomeArquivoCertificado_minhaSenha.pfx</strong>
      </small>
      <br/>
      <small>
        &nbsp;&nbsp;Obs:
        <strong>Verifique se não contém outro carácter <i>underline</i> "_" no nome do arquivo.</strong>
      </small>
    </Muted>
    <Muted>
      3º - No painel clique no botão "Carregar Certificados" e navegue até a pasta onde estão os mesmos.
    </Muted>
    <Muted>
      4º - Selecione todos e mande abrir.
    </Muted>
    <Muted>
      5º - Após este procedimento o cadastro será realizado de forma automática.
      Todas as informações necessárias serão importadas automaticamente.
    </Muted>
    <Muted>
      6º - Caso a empresa já esteja cadastrado no sistema o certificado e dados da empresa serão atualizados.
    </Muted>
  </>
);

export const AjudaRelatorioSistema = (
  <div>
    <h3 style={{textAlign: 'center'}}>
      <small>
        <strong>Dashboard dos relatórios para seus clientes!</strong>
      </small>
    </h3>
    <Muted>

    </Muted>
  </div>
);
