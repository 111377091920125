/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "../../assets/jss/material-dashboard-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const {fluid, white} = props;
  const container = cx({[classes.container]: !fluid, [classes.containerFluid]: fluid, [classes.whiteColor]: white});
  const anchor = classes.a + cx({[" " + classes.whiteColor]: white});
  const block = cx({[classes.block]: true, [classes.whiteColor]: white});

  if ((window.location.pathname.indexOf("/empresas/") > -1) || (window.location.pathname.indexOf("/relatorio") > -1))
    return <footer/>;

  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="https://gefis.com.br" className={block} target={"_blank"}>
                Site
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="https://gefis.com.br#empresa" className={block}>
                A Empresa
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="https://gefis.com.br#portfolio" className={block}>
                Portfolio
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="https://blog.gefis.com.br" className={block}>
                Blog
              </a>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>
          &copy; {1900 + new Date().getYear()}{" "}
          <a href="https://gefis.com.br" className={anchor} target="_blank">
            Gefis,
          </a>
          {" "} Inteligência Fiscal.
          {" "} <small>v1.0.21</small>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};
