import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import CustomInput from "../../../components/CustomInput/CustomInput";
import {buscarDadosCNPJ, estadosUF} from "../../../components/Funcoes";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Select, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Search} from "@material-ui/icons";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cnpj: "",
      uf: "",
      cnae_fiscal: "",
      razao_social: "",
      nome_fantasia: "",
      importado_certificado: false
    };
  }

  sendState() {
    return this.state;
  }

  async buscarCNPJ(cnpj) {
    const {ok, empresa} = await buscarDadosCNPJ(cnpj);
    if (ok) {
      const {nome_fantasia, cnae_fiscal, uf, razao_social} = empresa;
      this.setState({nome_fantasia, cnae_fiscal, uf, razao_social});
    }
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    const {allStates} = nextProps;
    const {certificado} = allStates;

    if (certificado) {
      const {razao_social, cnpj} = certificado;

      if (razao_social) this.setState({razao_social});
      if (cnpj) this.setState({cnpj});
      this.setState({importado_certificado: !!cnpj});

      if (cnpj !== this.state.cnpj) {

      }
    }
  }

  isValidated() {
    return true;
  }

  render() {
    const {classes} = this.props;
    return (
      <div>
        <h4 className={classes.infoText}>Dados da empresa</h4>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <GridContainer>
              <GridItem lg={4} md={4} sm={6} xs={12}>
                <CustomInput
                  labelText="CNPJ"
                  formControlProps={{fullWidth: true, disabled: this.state.importado_certificado}}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className={classes.inputAdornment}>
                        <Tooltip title={"Buscar CNPJ"}>
                          <IconButton onClick={() => this.buscarCNPJ(this.state.cnpj)}>
                            <Search className={classes.inputAdornmentIcon}/>
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                    type: "text",
                    value: this.state.cnpj,
                    onChange: ({target}) => this.setState({cnpj: target.value})
                  }}
                />
              </GridItem>
              <GridItem lg={4} md={4} sm={6} xs={12}>
                <CustomInput
                  labelText="CNAE"
                  formControlProps={{fullWidth: true, disabled: this.state.importado_certificado}}
                  inputProps={{
                    type: "text",
                    value: this.state.cnae_fiscal,
                    onChange: ({target}) => this.setState({cnae_fiscal: target.value})
                  }}
                />
              </GridItem>
              <GridItem lg={4} md={4} sm={6} xs={12}>
                <InputLabel style={{marginTop: 10}}>UF</InputLabel>
                <Select
                  fullWidth
                  value={this.state.uf}
                  onChange={({target}) => this.setState({uf: target.value})}
                >
                  {Object.keys(estadosUF).map(prop => {
                    return (<MenuItem value={prop} key={prop}>{prop}</MenuItem>);
                  })}
                </Select>
                {/*<CustomInput
                  labelText="UF"
                  formControlProps={{fullWidth: true, disabled: false}}
                  inputProps={{
                    type: "text",
                    value: this.state.uf,
                    onChange: ({target}) => this.setState({uf: target.value})
                  }}
                />*/}
              </GridItem>
              <GridItem lg={6} md={6} sm={6} xs={12}>
                <CustomInput
                  labelText="Razão Social"
                  formControlProps={{fullWidth: true, disabled: this.state.importado_certificado}}
                  inputProps={{
                    type: "text",
                    value: this.state.razao_social,
                    onChange: ({target}) => this.setState({razao_social: target.value})
                  }}
                />
              </GridItem>
              <GridItem lg={6} md={6} sm={6} xs={12}>
                <CustomInput
                  labelText="Nome Fantasia"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    type: "text",
                    value: this.state.nome_fantasia,
                    onChange: ({target}) => this.setState({nome_fantasia: target.value})
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(style)(Step2);
