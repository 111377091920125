import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import PictureUpload from "../../../components/CustomUpload/PictureUpload.js";
import Swal from "sweetalert2";
import {WebService} from "../../../components/WebService";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: "",
      certificado: "",
      data_final: "",
      senha_certificado: "",
      razao_social: "",
      cnpj: ""
    };
  }

  sendState() {
    return this.state;
  }

  change(event, stateName, type, stateNameEqualTo) {

  }

  isValidated() {
    return true;
    /*const valido = (this.state.certificado !== "");
    if (!valido)
      Swal.fire({icon: "warning", title: 'Escolha o certificado digital para continuar.'});
    return valido;*/
  }

  async uploadCertificado(arquivo) {
    const {value: senha} = await Swal.fire({
      type: "question",
      title: "Qual a senha do certificado?",
      input: 'password',
      inputPlaceholder: 'Entre com sua senha.',
      inputAttributes: {
        maxlength: 20,
        autocapitalize: 'off',
        autocorrect: 'off'
      }
    });

    if (senha) {
      const {ok, razao: razao_social, cnpj, flag_assinado, uuid, data_final} = await WebService(`/certificado/upload`, {
        arquivo,
        senha
      }, "POST");
      if (ok) {
        if (flag_assinado) {
          this.setState({razao_social, cnpj, certificado: arquivo, uuid, senha_certificado: senha, data_final});
          this.props.nextButtonClick();
        } else {
          await Swal.fire({
            icon: "warning",
            title: "Certificado incompleto.",
            text: `O certificado informado é válido porém não contem a chave privada. Considere realizar o upload de um certificado com a chave privada.`
          });
        }
      }
    } else {
      await Swal.fire({icon: "warning", title: "A senha não pode ser vazia."});
    }
  }

  render() {
    const {classes} = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Escolha o arquivo do certificado para finalizar o cadastro
          </h4>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <PictureUpload
            label={"Escolher certificado"}
            accept={".pfx"}
            onUpload={this.uploadCertificado.bind(this)}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step1);
