import React, {useEffect, useRef, useState} from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import {downloadFileAsBlob, formatoDinheiro, getColoredNumber, ls} from "../../../components/Funcoes";
import NavPills from "../../../components/NavPills/NavPills";
import CardBody from "../../../components/Card/CardBody";
import CustomInput from "../../../components/CustomInput/CustomInput";

import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Zoom from "@material-ui/core/Zoom";
import LinkOff from "@material-ui/icons/LinkOff";
import ResponsiveTable from "../../../components/ResponsiveTable";
import Warning from "../../../components/Typography/Warning";
import {baseURL, WebService} from "../../../components/WebService";
import Info from "../../../components/Typography/Info";
import Success from "../../../components/Typography/Success";
import Button from "../../../components/CustomButtons/Button";
import {
  Assessment,
  Description,
  FiberManualRecord,
  RestorePage,
  Send,
  Visibility
} from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as Swal from "sweetalert2";
import {Tooltip} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {makeStyles} from "@material-ui/core/styles";
import stylesForm from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {infoColor} from "../../../assets/jss/material-dashboard-pro-react";
import withReactContent from "sweetalert2-react-content";
import ScaleLoader from "react-spinners/ScaleLoader";

const SwalR = withReactContent(Swal);

const useStyles1 = makeStyles(stylesForm);

const [ano, mes] = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split("T")[0].split("-");
export default function DashboardRelatorioCliente({history}) {
  const classes = useStyles1();

  const [mensagemStatusEnvioTodos, setMensagemStatusEnvioTodos] = useState("");
  const [tipoRelatorio, setTipoRelatorio] = useState(0);
  const [onlyData, setOnlyData] = useState("0");
  const [filtroPreenchimento, setFiltroPreenchimento] = useState("0");
  const [empresasResumoEntrada, setEmpresasResumoEntrada] = useState([]);
  const [empresasResumoSaida, setEmpresasResumoSaida] = useState([]);
  const [empresasResumoNaoLancadas, setEmpresasResumoNaoLancadas] = useState([]);
  const [empresasResumoServico, setEmpresasResumoServico] = useState([]);
  const [competencia, setCompetencia] = useState(`${mes}/${ano}`);

  const getEmpresaResumo = async () => {
    if (!competencia)
      return;
    setEmpresasResumoEntrada([]);
    setEmpresasResumoNaoLancadas([]);
    if (competencia && competencia.length < 7) {
      Swal.fire({
        icon: "warning",
        title: 'A competencia informada não é válida.',
        html: `O formato deve ser <strong>mm/aaaa</strong>`
      });
      return false;
    }
    const [mes, ano] = competencia.split("/");

    if (tipoRelatorio === 0) {
      const {ok, empresasResumo} = await WebService(`/relatorio/resumoRelatorioEntrada`, {mes, ano});
      if (ok) setEmpresasResumoEntrada(empresasResumo);
    } else if (tipoRelatorio === 1) {
      const {ok, empresas} = await WebService(`/relatorio/getResumoNotasSaida`, {mes, ano});
      if (ok) setEmpresasResumoSaida(empresas);
    } else if (tipoRelatorio === 2) {
      const {ok, empresas} = await WebService(`/relatorio/getResumoEmpresaNotasNaoLancadas`, {mes, ano});
      if (ok) setEmpresasResumoNaoLancadas(empresas);
    } else if (tipoRelatorio === 3) {
      const {ok, empresas} = await WebService(`/relatorio/getResumoEmpresaServico`, {mes, ano});
      if (ok) setEmpresasResumoServico(empresas);
    }
  };

  useEffect(() => {
    window.jQuery('#competencia').mask('00/0000');
  }, [competencia]);

  const getMensagemStatus = status => {
    let mensagem = <Warning>Não enviado</Warning>;
    if (status) {
      const {ok, descricao, info} = JSON.parse(status);
      const Componente = ok ? Success : (info ? Info : Warning);
      mensagem = <Componente>{descricao}</Componente>
    }
    return mensagem;
  };

  const baixarPlanilhaEntrada = async (uuidEmpresa, uuidCompetencia) => {
    const excelBlob = await WebService(`/nota/getNotasEntrada`, {
      uuidEmpresa,
      uuidCompetencia,
      downloadExcel: true
    }, "GET", true, true, true);

    const {nome_razao_social} = empresasResumoEntrada.find(el => el.uuidCompetencia === uuidCompetencia);

    await downloadFileAsBlob(excelBlob, `Relatório entrada ${nome_razao_social} ${competencia.replace("/", "-")}.xlsx`);
  };

  useEffect(() => {
    getEmpresaResumo();
  }, [tipoRelatorio]);

  const RadioCustom = <Radio
    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
    checkedIcon={<FiberManualRecord className={classes.radioChecked}/>}
    classes={{checked: classes.radio, root: classes.radioRoot}}
  />;

  const enviarRelatorioManual = async (id_empresa, uuidCompetencia, tipoRelatorio, notificar = true) => {
    const p = {id_empresa, uuidCompetencia, tipoRelatorio};
    const {ok} = await WebService(`/relatorio/enviarRelatorioCliente`, p, "POST", notificar, notificar);
    if (ok && notificar) {
      await Swal.fire({icon: "success", title: "Relatório enviado com sucesso!"});
      await getEmpresaResumo();
    }
  };

  const gerarRelatorioCompetenciaManual = async (id_empresa, notificar = true) => {
    const [mes, ano] = competencia.split("/");
    const {ok} = await WebService(`/relatorio/gerarRelatorioCompetenciaManual`, {
      id_empresa,
      mes,
      ano
    }, "POST", notificar, notificar);
    if (ok && notificar) {
      await Swal.fire({icon: "success", title: "Relatório gerado com sucesso!"});
      await getEmpresaResumo();
    }
  };

  const enviarRelatorioTodos = async () => {
    const tiposDesc = ['entrada', 'saída', 'notas não lançadas', 'serviços',];

    const {value} = await Swal.fire({
      icon: "question",
      title: 'Enviar relatórios?',
      text: `Deseja confirmar o envio dos relatórios de ${tiposDesc[tipoRelatorio]}?`,
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    });

    if (!value) return;

    SwalR.fire({
      title: '',
      allowOutsideClick: false,
      showConfirmButton: false,
      html: (
        <div align={"center"}>
          <ScaleLoader size={200} color={infoColor[0]} loading={true}/>
          <div id={"labelStatusEnviandoRelatorio"}></div>
        </div>
      )
    });

    const tipos = ['entrada', 'saida', 'nao_lancadas', 'servico',];
    const tiposValor = [empresasResumoEntrada, empresasResumoSaida, empresasResumoNaoLancadas, empresasResumoServico];

    for (let i = 0; i < tiposValor[tipoRelatorio].length; i++) {
      const {id_empresa, nome_razao_social, uuidCompetencia} = tiposValor[tipoRelatorio][i];
      const divStatus = document.getElementById('labelStatusEnviandoRelatorio');

      if (!uuidCompetencia) {
        if (divStatus) divStatus.innerText = `Gerando relatório de ${nome_razao_social}`;

        await gerarRelatorioCompetenciaManual(id_empresa, false);
      }
      if (divStatus) divStatus.innerText = `Enviando relatório para [${nome_razao_social}]`;
      await enviarRelatorioManual(id_empresa, uuidCompetencia, tipos[tipoRelatorio], false);
    }

    Swal.fire({icon: "success", title: 'Relatórios enviado com sucesso!'});
    getEmpresaResumo();
  };

  const atualizarStatus = ({gerarRelatorio, entrada, saida, naoLancada, servico}) => {
    let tabela = `<table style='width: 100%'>`;
    tabela += `<tr><th style='float: left'>Gerar relatório</th><td>${gerarRelatorio}%</td></tr>`;
    tabela += `<tr><th style='float: left'>Enviar relatório Entrada</th><td>${entrada}%</td></tr>`;
    tabela += `<tr><th style='float: left'>Enviar relatório Saída</th><td>${saida}%</td></tr>`;
    tabela += `<tr><th style='float: left'>Enviar relatório não lançadas</th><td>${naoLancada}%</td></tr>`;
    tabela += `<tr><th style='float: left'>Serviços</th><td>${servico}%</td></tr>`;
    tabela += `</table>`;
    document.getElementById('labelStatusEnviandoRelatorio').innerHTML = tabela;
  };

  const fileInputRelatorio = useRef();

  const classesCustomFormControl = {label: classes.label, root: classes.labelRoot};
  return (
    <div>
      {/*<AjudaSistema slug={'dashboard_relatorio'} conteudo={AjudaRelatorioSistema}/>*/}
      <GridContainer>
        <GridItem xs={12} sm={6} md={4} lg={4}>
          <Card>
            <CardBody>
              <RadioGroup
                row
                value={onlyData}
                onChange={({target}) => setOnlyData(target.value)}
              >
                <FormControlLabel
                  classes={classesCustomFormControl}
                  value="0"
                  control={RadioCustom}
                  label="Todos"
                  labelPlacement="right"
                />
                <FormControlLabel
                  classes={classesCustomFormControl}
                  value="1"
                  control={RadioCustom}
                  label="Com valor"
                  labelPlacement="right"
                />
                <FormControlLabel
                  classes={classesCustomFormControl}
                  value="2"
                  control={RadioCustom}
                  label="Sem valor"
                  labelPlacement="right"
                />
              </RadioGroup>
              <CustomInput
                labelText="Competência"
                formControlProps={{fullWidth: true}}
                inputProps={{
                  type: "text",
                  id: 'competencia',
                  value: competencia,
                  onChange: ({target}) => setCompetencia(target.value)
                }}
              />
              <Button simple color={"info"} fullWidth onClick={getEmpresaResumo.bind(this)}>
                Buscar
              </Button>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={12}>
        </GridItem>
        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Button
            color={"info"}
            fullWidth
            onClick={enviarRelatorioTodos.bind(this)}
          >
            Enviar para todos
          </Button>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <NavPills
            color="info"
            alignCenter
            onChange={setTipoRelatorio}
            tabs={[
              {tabButton: "Entrada", tabIcon: ArrowDownward},
              {tabButton: "Saída", tabIcon: ArrowUpward},
              {tabButton: "Não Lançadas", tabIcon: LinkOff},
              {tabButton: "Serviços", tabIcon: Description},
            ]}
          />
          <div style={{position: 'relative'}}>
            <Zoom in={tipoRelatorio === 0} mountOnEnter unmountOnExit>
              <div style={{position: 'absolute', width: '100%'}}>
                <Card>
                  <CardBody>
                    <h4>Ferramentas</h4>
                    <hr/>
                    <input
                      type="file"
                      onChange={async e => {
                        e.preventDefault();
                        let reader = new FileReader();
                        let [file] = e.target.files;
                        const fileBase64 = await new Promise(resolve => {
                          reader.onloadend = () => resolve(reader.result);

                          reader.readAsDataURL(file);
                        });

                        fileInputRelatorio.current.value = null;

                        const result = await WebService(`/relatorio/comprarRelatorioDominio`, {fileBase64}, 'POST', true, true, true);
                        downloadFileAsBlob(result, `${file.name.split('.')[0]} - Comparação Gefis.xlsx`);
                      }}
                      style={{display: "none"}}
                      accept={".xlsx,.xls"}
                      ref={fileInputRelatorio}
                    />
                    <Button
                      color={'info'}
                      simple
                      onClick={() => {
                        fileInputRelatorio.current.click();
                      }}
                    >
                      Comparar relatório entrada
                    </Button>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <RadioGroup
                      row
                      value={filtroPreenchimento}
                      onChange={({target}) => setFiltroPreenchimento(target.value)}
                    >
                      <FormControlLabel
                        value="0"
                        classes={classesCustomFormControl} control={RadioCustom} label="Todos"
                        labelPlacement="right"
                      />
                      <FormControlLabel
                        value="1"
                        classes={classesCustomFormControl}
                        control={RadioCustom}
                        label="Concluido"
                        labelPlacement="right"
                      />
                      <FormControlLabel
                        value="2"
                        classes={classesCustomFormControl}
                        control={RadioCustom}
                        label="Em aberto"
                        labelPlacement="right"
                      />
                    </RadioGroup>
                    <ResponsiveTable
                      colunas={["#", "Razão Social", "CNPJ", "Total de notas", "Notas Preenchidas", "Notas canceladas", "Status", "Opções"]}
                      dados={empresasResumoEntrada.filter(el => {
                        const od = parseInt(onlyData);
                        const odp = parseInt(filtroPreenchimento);
                        const finalizado = parseInt(el['total_nota_preenchidas']) === parseInt(el['total_nota']);
                        return (
                          ((od === 0) || (od === 1 && el['total_nota']) || (od === 2 && !el['total_nota'])) &&
                          ((odp === 0) || (odp === 1 && finalizado) || (odp === 2 && !finalizado))
                        );
                      }).map(prop => {
                        const {
                          id_empresa,
                          nome_razao_social,
                          cnpj,
                          total_nota,
                          total_nota_preenchidas,
                          total_cancelada,
                          status_entrada,
                          uuidEmpresa,
                          uuidCompetencia
                        } = prop;
                        return [
                          id_empresa,
                          nome_razao_social,
                          cnpj,
                          getColoredNumber(total_nota),
                          getColoredNumber(total_nota_preenchidas),
                          getColoredNumber(total_cancelada, true),
                          getMensagemStatus(status_entrada),
                          ((total_nota + total_cancelada) && status_entrada && uuidCompetencia) ? (
                            <>
                              <Tooltip title={"Visualizar detalhes"}>
                                <Button
                                  simple
                                  round
                                  justIcon
                                  color={"info"}
                                  onClick={() => history.push(`/relatorio/entrada/${uuidEmpresa}/${uuidCompetencia}`)}
                                >
                                  <Assessment/>
                                </Button>
                              </Tooltip>
                              <Tooltip title={"Baixar Excel"}>
                                <Button
                                  simple
                                  round
                                  justIcon
                                  color={"info"}
                                  onClick={() => baixarPlanilhaEntrada(uuidEmpresa, uuidCompetencia)}
                                >
                                  <ArrowDownward/>
                                </Button>
                              </Tooltip>
                              <Tooltip title={"Enviar para cliente"}>
                                <Button
                                  simple
                                  round
                                  justIcon
                                  color={"info"}
                                  onClick={() => enviarRelatorioManual(id_empresa, uuidCompetencia, 'entrada')}
                                >
                                  <Send/>
                                </Button>
                              </Tooltip>
                            </>
                          ) : (!uuidCompetencia && total_nota ? (
                            <Tooltip title={"Gerar relatório"}>
                              <Button
                                simple
                                round
                                justIcon
                                color={"info"}
                                onClick={() => gerarRelatorioCompetenciaManual(id_empresa)}
                              >
                                <RestorePage/>
                              </Button>
                            </Tooltip>
                          ) : "")
                        ];
                      })}
                    />
                  </CardBody>
                </Card>
              </div>
            </Zoom>
            <Zoom in={tipoRelatorio === 1} mountOnEnter unmountOnExit>
              <div style={{position: 'absolute', width: '100%'}}>
                <Card>
                  <CardBody>
                    <ResponsiveTable
                      colunas={["#", "Razão Social", "CNPJ", "NF-e", "NFC-e", "CT-e", "NFS-e", "Status", "Opções"]}
                      dados={empresasResumoSaida.filter(el => {
                        const od = parseInt(onlyData);
                        const cn = (el.nfe || el.nfce || el.cte || el.nfse);
                        return (od === 0) || (od === 1 && cn) || (od === 2 && !cn);
                      }).map(prop => {
                        const {
                          id_empresa,
                          nome_razao_social,
                          cnpj,
                          nfe,
                          nfce,
                          cte,
                          nfse,
                          status_saida,
                          uuidCompetencia
                        } = prop;
                        return [
                          id_empresa,
                          nome_razao_social,
                          cnpj,
                          getColoredNumber(nfe),
                          getColoredNumber(nfce),
                          getColoredNumber(cte),
                          getColoredNumber(nfse),
                          getMensagemStatus(status_saida),
                          ((nfe || nfce || cte || nfse) && uuidCompetencia) ? (
                            <>
                              <Tooltip title={"Visualizar Detalhes"}>
                                <Button
                                  color={"info"}
                                  simple
                                  justIcon
                                  round
                                  onClick={async () => history.push(`/relatorio/saida/${uuidCompetencia}`)}
                                >
                                  <Assessment/>
                                </Button>
                              </Tooltip>
                              <Tooltip title={"Baixar Relatório"}>
                                <Button
                                  color={"info"}
                                  simple
                                  justIcon
                                  round
                                  onClick={async () => {
                                    const [mes, ano] = competencia.split("/");
                                    const excelBlob = await WebService(`/nota/getNotasSaida`, {
                                      id_empresa,
                                      mes,
                                      ano
                                    }, "POST", true, true, true);
                                    await downloadFileAsBlob(excelBlob, `${nome_razao_social}.xlsx`);
                                  }}
                                >
                                  <ArrowDownward/>
                                </Button>
                              </Tooltip>
                              <Tooltip title={"Enviar para cliente"}>
                                <Button
                                  simple
                                  round
                                  justIcon
                                  color={"info"}
                                  onClick={() => enviarRelatorioManual(id_empresa, uuidCompetencia, 'saida')}
                                >
                                  <Send/>
                                </Button>
                              </Tooltip>
                            </>
                          ) : (!uuidCompetencia && (nfe || nfce || cte || nfse) ? (
                            <Tooltip title={"Gerar relatório"}>
                              <Button
                                simple
                                round
                                justIcon
                                color={"info"}
                                onClick={() => gerarRelatorioCompetenciaManual(id_empresa)}
                              >
                                <RestorePage/>
                              </Button>
                            </Tooltip>
                          ) : "")
                        ];
                      })}
                    />
                  </CardBody>
                </Card>
              </div>
            </Zoom>
            <Zoom in={tipoRelatorio === 2} mountOnEnter unmountOnExit>
              <div style={{position: 'absolute', width: '100%'}}>
                <Card>
                  <CardBody>
                    <ResponsiveTable
                      colunas={["#", "Razão Social", "CNPJ", "NF-e N/L", "NFC-e N/L", "CT-e N/L", "Status", "Opções"]}
                      dados={empresasResumoNaoLancadas.filter(el => {
                        const od = parseInt(onlyData);
                        const cn = (el.nfe || el.nfce || el.cte);
                        return (od === 0) || (od === 1 && cn) || (od === 2 && !cn);
                      }).map(prop => {
                        const {
                          id_empresa,
                          nome_razao_social,
                          cnpj,
                          nfe,
                          nfce,
                          cte,
                          status_nao_lancada,
                          uuidCompetencia
                        } = prop;
                        return [
                          id_empresa,
                          nome_razao_social,
                          cnpj,
                          getColoredNumber(nfe),
                          getColoredNumber(nfce),
                          getColoredNumber(cte),
                          getMensagemStatus(status_nao_lancada),
                          ((nfe || nfce || cte) && status_nao_lancada && uuidCompetencia ? (
                            <>
                              <Tooltip title={"Visualizar relatório"}>
                                <Button
                                  color={"info"}
                                  simple
                                  justIcon
                                  round
                                  onClick={() => history.push(`/relatorio/nao_lancadas`, {
                                    id_empresa,
                                    nome_razao_social
                                  })}
                                >
                                  <Visibility/>
                                </Button>
                              </Tooltip>
                              <Tooltip title={"Enviar para cliente"}>
                                <Button
                                  simple
                                  round
                                  justIcon
                                  color={"info"}
                                  onClick={() => enviarRelatorioManual(id_empresa, uuidCompetencia, 'nao_lancadas')}
                                >
                                  <Send/>
                                </Button>
                              </Tooltip>
                            </>
                          ) : "")
                        ];
                      })}
                    />
                  </CardBody>
                </Card>
              </div>
            </Zoom>
          </div>
          <div style={{position: 'relative'}}>
            <Zoom in={tipoRelatorio === 3} mountOnEnter unmountOnExit>
              <div style={{position: 'absolute', width: '100%'}}>
                <Card>
                  <CardBody>
                    <ResponsiveTable
                      colunas={["#", "Razão Social", "CNPJ", "Total Serviços Prestados", "Total Serviços Tomados", "Status", "Opções"]}
                      dados={empresasResumoServico.filter(el => {
                        const od = parseInt(onlyData);
                        const cn = (el.total_valor_prestado || el.total_valor_tomado);
                        return (od === 0) || (od === 1 && cn) || (od === 2 && !cn);
                      }).map(prop => {
                        const {
                          id_empresa,
                          nome_razao_social,
                          cnpj,
                          total_valor_prestado,
                          total_valor_tomado,
                          status_servico,
                          uuidCompetencia
                        } = prop;
                        return [
                          id_empresa,
                          nome_razao_social,
                          cnpj,
                          formatoDinheiro(total_valor_prestado),
                          formatoDinheiro(total_valor_tomado),
                          getMensagemStatus(status_servico),
                          ((total_valor_prestado || total_valor_tomado) && uuidCompetencia) ? (
                            <>
                              <Tooltip title={"Visualizar relatório"}>
                                <Button
                                  color={"info"}
                                  simple
                                  justIcon
                                  round
                                  onClick={() => window.open(`${baseURL}/relatorio/getRelatorioServicoCompetencia?uuidCompetencia=${uuidCompetencia}`)}
                                >
                                  <Visibility/>
                                </Button>
                              </Tooltip>
                              <Tooltip title={"Enviar para cliente"}>
                                <Button
                                  simple
                                  round
                                  justIcon
                                  color={"info"}
                                  onClick={() => enviarRelatorioManual(id_empresa, uuidCompetencia, 'servico')}
                                >
                                  <Send/>
                                </Button>
                              </Tooltip>
                            </>
                          ) : (!uuidCompetencia && (total_valor_prestado || total_valor_tomado) ? (
                            <Tooltip title={"Gerar relatório"}>
                              <Button
                                simple
                                round
                                justIcon
                                color={"info"}
                                onClick={() => gerarRelatorioCompetenciaManual(id_empresa)}
                              >
                                <RestorePage/>
                              </Button>
                            </Tooltip>
                          ) : "")
                        ];
                      })}
                    />
                  </CardBody>
                </Card>
              </div>
            </Zoom>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
