import React from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Info from "../../components/Typography/Info";
import {CloudDone, FileCopy, Update} from "@material-ui/icons";
import Success from "../../components/Typography/Success";
import Warning from "../../components/Typography/Warning";
import CustomLinearProgress from "../../components/CustomLinearProgress/CustomLinearProgress";
import Countdown from "react-countdown";
import {zeroEsquerda} from "../../components/Funcoes";

export default function StatusSincronizacao({slug, totalNotas, totalNotasEnviadas, totalNotasPendentes, percentual, status, time}) {
  return (
    <>
      {slug === "ENVIANDO_NOTA" && (
        <GridContainer>
          <GridItem lg={4} md={4} sm={4} xs={4} align={"center"}>
            <Info><FileCopy/></Info>
            <h4><strong>{totalNotas}</strong></h4>
            Total de notas
          </GridItem>
          <GridItem lg={4} md={4} sm={4} xs={4} align={"center"}>
            <Success><CloudDone/></Success>
            <h4><strong>{totalNotasEnviadas}</strong></h4>
            Notas enviadas
          </GridItem>
          <GridItem lg={4} md={4} sm={4} xs={4} align={"center"}>
            <Warning><Update/></Warning>
            <h4><strong>{totalNotasPendentes}</strong></h4>
            Notas pendentes
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <p>&nbsp;</p>
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            {percentual}%<br/>
            <CustomLinearProgress color={"info"} variant="buffer" value={percentual} valueBuffer={0}/>
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            {status}
          </GridItem>
        </GridContainer>
      )}
      {slug === "AGUARDANDO_PROXIMA_LEITURA" && (
        <GridContainer>
          <GridItem lg={12} md={12} sm={12} xs={12} align={"center"}>
            <h3>Aguardando próxima leitura</h3>
            <Countdown
              date={new Date(time)}
              renderer={({hours, minutes, seconds}) => (
                <span style={{fontSize: 25}}>
                          {zeroEsquerda(hours)}:{zeroEsquerda(minutes)}:{zeroEsquerda(seconds)}
                        </span>
              )}
            />
          </GridItem>
        </GridContainer>
      )}
      {slug === "SALVANDO_NOTAS_BASE_LOCAL" && (
        <GridContainer>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <h4>{status}</h4>
            {percentual}%<br/>
            <CustomLinearProgress color={"info"} variant="buffer" value={percentual} valueBuffer={0}/>
          </GridItem>
        </GridContainer>
      )}
      {slug === "STATUS" && (
        <GridContainer>
          <GridItem lg={12} md={12} sm={12} xs={12} align={"center"}>
            <h3>
              <strong>Status:</strong> {status}
            </h3>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
}
