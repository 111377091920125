import React, {useEffect, useState} from "react";
import {WebService} from "../../components/WebService";
import ResponsiveTable from "../../components/ResponsiveTable";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import Icon from "@material-ui/core/Icon";
import {pontuacaoNumero, tiposNotas} from "../../components/Funcoes";
import CardFooter from "../../components/Card/CardFooter";
import GridItem from "../../components/Grid/GridItem";
import {cardTitle} from "../../assets/jss/material-dashboard-pro-react";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import {makeStyles} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "../../components/CustomButtons/Button";
import {ArrowDownward} from "@material-ui/icons";
import * as Swal from "sweetalert2";

const styles = {
  cardTitle,
  pageSubcategoriesTitle: {color: "#3C4858", textDecoration: "none", textAlign: "center"},
  cardCategory: {margin: "0", color: "#999999"},
  ...extendedFormsStyle,
};
const useStyles = makeStyles(styles);

export default function NotasPendentes({computedMatch, location}) {
  const classes = useStyles();
  const [notasPendentes, setNotasPendentes] = useState([]);

  const getNotasPendentes = async () => {
    const {state} = location;
    let id_empresa;
    if (state)
      id_empresa = state.id_empresa;

    const {ok, notasPendentes} = await WebService(`/nota/getNotasPendentes`, {id_empresa});
    if (ok)
      setNotasPendentes(notasPendentes);
  };

  useEffect(() => {
    getNotasPendentes();
  }, []);

  const downloadManualNotaPendente = async chave => {
    const {ok} = await WebService(`/nota/downloadManualNotaPendente`, {chave});
    if (ok) {
      await Swal.fire({icon: "success", title: 'Nota baixada com sucesso!'});
      await getNotasPendentes();
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={6} lg={4}>
        <Card>
          <CardHeader color="warning" stats icon>
            <CardIcon color="warning">
              <Icon>info_outline</Icon>
            </CardIcon>
            <p className={classes.cardCategory}>Notas pendentes</p>
            <h3 className={classes.cardTitle}>
              {/*<ContadorNumero total={totalNotasPendentes}/>*/}
              {pontuacaoNumero(notasPendentes.length)}
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              <p>&nbsp;</p>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card>
          <CardBody>
            <ResponsiveTable
              colunas={["Chave", "Razão Social Destinatário", "Competência", "Tipo", "Opções"]}
              dados={notasPendentes.map(prop => {
                const {chave, nome_razao_social, ano, mes, modelo, tipo_nota} = prop;
                const tn = parseInt(tipo_nota || modelo);
                return [
                  chave,
                  nome_razao_social,
                  `${mes}/${ano}`,
                  tiposNotas[tn],
                  ((tn === 55) && (
                    <Tooltip title={"Realizar download"}>
                      <Button color={"info"} simple justIcon round onClick={() => downloadManualNotaPendente(chave)}>
                        <ArrowDownward/>
                      </Button>
                    </Tooltip>
                  ))
                ];
              })}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
