import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import {WebService} from "../../../components/WebService";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import ResponsiveTable from "../../../components/ResponsiveTable";
import {convertDate, formatoDinheiro} from "../../../components/Funcoes";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Button from "../../../components/CustomButtons/Button";
import ListAlt from "@material-ui/icons/ListAlt";
import {moduloAtivo} from "../../../components/DataModule";
import NavPills from "../../../components/NavPills/NavPills";
import {Pie} from "react-chartjs-2";

export default ({computedMatch, history}) => {
  const [uuidCompetencia, setUUIDCompetencia] = useState('');
  const [notas, setNotas] = useState([]);
  const [active, setActive] = useState(0);

  const tipoNotaArray = ['55', '65', '57', '99'];

  useEffect(() => {
    if (computedMatch) {
      const {params} = computedMatch;
      if (params) {
        const {uuidCompetencia} = params;
        if (uuidCompetencia) {
          setUUIDCompetencia(uuidCompetencia);
        } else
          Swal.fire({icon: "info", title: "Dados não conferem."});
      }
    }
  }, []);

  useEffect(() => {
    if (uuidCompetencia) getNotas();
  }, [uuidCompetencia]);

  const getNotas = async () => {
    const {ok, notas} = await WebService(`/nota/getNotasSaidaLista`, {uuid: uuidCompetencia});
    if (ok) setNotas(notas);
  };

  return (
    <GridContainer>
      <GridItem lg={3} md={4} sm={6} xs={12}>
        <Button color={"info"} onClick={() => history.push(`/relatorio`)} fullWidth>
          <ArrowBack/>Voltar
        </Button>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardBody>
            <GridContainer justify={"center"}>
              <GridItem lg={3} md={4} sm={6} xs={12}>
                <Pie
                  data={{
                    labels: ['NF-e', 'NFC-e', 'CT-e', 'NFS-e'],
                    datasets: [{
                      data: [
                        notas.filter(el => el.tipo_nota === '55').length,
                        notas.filter(el => el.tipo_nota === '65').length,
                        notas.filter(el => el.tipo_nota === '57').length,
                        notas.filter(el => el.tipo_nota === '99').length,
                      ],
                      borderColor: [
                        'rgba(168, 69, 254, 1)',
                        'rgba(124, 210, 253, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(76, 175, 80, 1)'
                      ],
                      backgroundColor: [
                        'rgba(168, 69, 254, 0.2)',
                        'rgba(124, 210, 253, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(76, 175, 80, 0.2)'
                      ],
                      hoverBackgroundColor: [
                        'rgba(168, 69, 254, 0.4)',
                        'rgba(124, 210, 253, 0.4)',
                        'rgba(255, 99, 132, 0.4)',
                        'rgba(76, 175, 80, 0.4)'
                      ],
                      hoverBorderColor: [
                        'rgba(168, 69, 254, 1)',
                        'rgba(124, 210, 253, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(76, 175, 80, 1)'
                      ]
                    }]
                  }}
                />
              </GridItem>
              <GridItem lg={3} md={4} sm={6} xs={12}>
                <Pie
                  data={{
                    labels: ['Normal', 'Cancelada', 'Outros'],
                    datasets: [{
                      data: [
                        notas.filter(el => !el.flag_cancelada).length,
                        notas.filter(el => el.flag_cancelada).length,
                        notas.filter(el => el.nota_servico_situacao && el.nota_servico_situacao !== 'normal' && !el.flag_cancelada).length,
                      ],
                      borderColor: ['rgba(76, 175, 80, 1)', 'rgba(255, 99, 132, 1)', 'rgba(124, 210, 253, 1)'],
                      backgroundColor: ['rgba(76, 175, 80, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(124, 210, 253, 0.2)'],
                      hoverBackgroundColor: ['rgba(76, 175, 80, 0.4)', 'rgba(255, 99, 132, 0.4)', 'rgba(124, 210, 253, 0.4)'],
                      hoverBorderColor: ['rgba(76, 175, 80, 1)', 'rgba(255, 99, 132, 1)', 'rgba(124, 210, 253, 1)']
                    }]
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <NavPills
          color="info"
          alignCenter
          onChange={setActive}
          tabs={[
            {tabButton: "NF-e", tabIcon: ListAlt, hide: !moduloAtivo('7c901861-abed-11ea-a580-7a791949cbcc')},
            {tabButton: "NFC-e", tabIcon: ListAlt, hide: !moduloAtivo('8015ae8c-abed-11ea-a580-7a791949cbcc')},
            {tabButton: "CT-e", tabIcon: ListAlt, hide: !moduloAtivo('83a61237-abed-11ea-a580-7a791949cbcc')},
            {tabButton: "NFS-e", tabIcon: ListAlt, hide: !moduloAtivo('86740869-abed-11ea-a580-7a791949cbcc')}
          ]}
        />
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardBody>
            <ResponsiveTable
              colunas={['Nº NF', 'Série', 'Valor NF', 'Data Emissão', 'Chave', 'Documento destinatário', 'Observação']}
              dados={notas.filter(el => el.tipo_nota === tipoNotaArray[active]).map(prop => {
                const {
                  chave,
                  data_emissao,
                  documento_destinatario,
                  flag_cancelada,
                  nota_servico_situacao,
                  numero,
                  serie,
                  tipo_nota,
                  valor_total
                } = prop;
                return [
                  numero,
                  serie,
                  formatoDinheiro(valor_total),
                  convertDate(data_emissao, false, true),
                  chave,
                  documento_destinatario,
                  flag_cancelada ? 'Cancelada' : (nota_servico_situacao && nota_servico_situacao !== 'normal' ? nota_servico_situacao : '')
                ];
              })}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};