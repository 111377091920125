import Dashboard from "views/Dashboard/Dashboard.js";
import LoginPage from "views/Pages/LoginPage.js";
import RegisterPage from "views/Pages/RegisterPage.js";

import DashboardIcon from "@material-ui/icons/Dashboard";
import Business from "@material-ui/icons/Business";
import ListarEmpresas from "./views/Empresas/ListarEmpresas";
import Settings from "@material-ui/icons/Settings";
import ListAlt from "@material-ui/icons/ListAlt";
import Bookmarks from "@material-ui/icons/Bookmarks";

import ManagerNotas from "./views/Notas/ManagerNotas";
import CadastrarEmpresa from "./views/Empresas/CadastrarEmpresa";
import CadastrarEmpresaMassa from "./views/Empresas/CadastrarEmpresaMassa";
import UsuarioManager from "./views/Configuracoes/UsuarioManager";
import UsuarioSingle from "./views/Configuracoes/UsuarioSingle";
import WebISS from "./views/Configuracoes/NFSe/WebISS";
import ManagerEmpresa from "./views/Empresas/ManagerEmpresa";
import EmpresaNotasEntradaCliente from "./views/Relatorios/Empresa/EmpresaNotasEntradaCliente";
import DashboardRelatorioCliente from "./views/Relatorios/Empresa/DashboardRelatorioCliente";
import NotasNaoLancadas from "./views/Relatorios/Empresa/NotasNaoLancadas";
import Empresa from "./views/Configuracoes/Empresa";
import NotasPendentes from "./views/Notas/NotasPendentes";
import EmpresaNotasNaoLancadaCliente from "./views/Relatorios/Empresa/EmpresaNotasNaoLancadaCliente";
import ManagerDownloads from "./views/Notas/ManagerDownloads";
import {AssignmentReturned, BusinessCenter, Email, CompareArrows} from "@material-ui/icons";
import Downloads from "./views/Configuracoes/Downloads";
import AtivacaoPage from "./views/Pages/AtivacaoPage";
import DashboardSincronizadorAdm from "./views/Dashboard/DashboardSincronizador";
import DashboardSincronizador from "./views/Sincronizador/Dashboard";
import {moduloAtivo} from "./components/DataModule";
import EnviarEmail from "./views/Email/EnviarEmail";
import PricingPage from "./views/Pages/PricingPage";
import ChangeLogs from "./views/Changelogs/ChangeLogs";
import EmpresaNotasSaida from "./views/Relatorios/Empresa/EmpresaNotasSaida";
import NFSe from "./views/Configuracoes/NFSe";

const dashRoutes = [];

if (moduloAtivo('5f8c178e-ab5c-11ea-a580-7a791949cbcc')) {
  dashRoutes.push({path: "/painel", name: "Dashboard", icon: DashboardIcon, component: Dashboard, layout: "/admin"});
}

if (moduloAtivo('c918db08-abeb-11ea-a580-7a791949cbcc')) {
  dashRoutes.push({
    path: "/painelSincronizador",
    name: "Dashboard",
    icon: DashboardIcon,
    component: DashboardSincronizadorAdm,
    layout: "/admin",
    hide: true
  });

  dashRoutes.push({
    path: "/empresas/cadastrarMassa",
    name: "Cadastro de empresas em massa",
    icon: Business,
    component: CadastrarEmpresaMassa,
    layout: "/admin",
    hide: true
  });

  dashRoutes.push({
    path: "/empresas/cadastrar",
    name: "Cadastrar Empresa",
    icon: Business,
    component: CadastrarEmpresa,
    layout: "/admin",
    hide: true
  });

  dashRoutes.push({
    path: "/empresas/:idEmpresa",
    name: "Empresas",
    icon: Business,
    component: ManagerEmpresa,
    layout: "/admin",
    hide: true
  });

  dashRoutes.push({
    path: "/empresas",
    name: "Empresas",
    icon: Business,
    component: ListarEmpresas,
    layout: "/admin"
  });
}
// * * * * * * * * * * Contabilidade * * * * * * * * * * * *
if (moduloAtivo("99d0ae3b-c46d-11ea-90fa-484d7efc9f1a")) {
  dashRoutes.push({
    path: "/contabilidades/cadastrarMassa",
    name: "Cadastro de contabilidades em massa",
    icon: Business,
    component: CadastrarEmpresaMassa,
    params: {flag_contabilidade: true},
    layout: "/admin",
    hide: true
  });

  dashRoutes.push({
    path: "/contabilidades/cadastrar",
    name: "Cadastrar Contabilidade",
    icon: Business,
    component: CadastrarEmpresa,
    params: {flag_contabilidade: true},
    layout: "/admin",
    hide: true
  });

  dashRoutes.push({
    path: "/contabilidades/:idEmpresa",
    name: "Contabilidade",
    icon: Business,
    component: ManagerEmpresa,
    params: {flag_contabilidade: true},
    layout: "/admin",
    hide: true
  });

  dashRoutes.push({
    path: "/contabilidades",
    name: "Contabilidades",
    icon: BusinessCenter,
    component: ListarEmpresas,
    params: {flag_contabilidade: true},
    layout: "/admin"
  });
}
// * * * * * * * * * * Contabilidade * * * * * * * * * * * *

if (moduloAtivo('5d6950f6-ab5c-11ea-a580-7a791949cbcc')) {
  dashRoutes.push({
    path: "/notas/pendentes",
    name: "Notas",
    icon: ListAlt,
    component: NotasPendentes,
    layout: "/admin",
    hide: true
  });
}

if (moduloAtivo('5df1ff10-ab5c-11ea-a580-7a791949cbcc')) {
  dashRoutes.push({
    path: "/notas/download",
    name: "Notas",
    icon: ListAlt,
    component: ManagerDownloads,
    layout: "/admin",
    hide: true
  });
}

dashRoutes.push({
  path: "/notas",
  name: "Notas",
  icon: ListAlt,
  component: ManagerNotas,
  layout: "/admin"
});

if (moduloAtivo('5eedb2aa-ab5c-11ea-a580-7a791949cbcc')) {
  dashRoutes.push({
    path: "/relatorio/entrada/:uuidEmpresa/:uuidCompetencia",
    name: "Relatório notas de entrada",
    component: EmpresaNotasEntradaCliente,
    layout: "/admin",
    hide: true
  });

  dashRoutes.push({
    path: "/relatorio/saida/:uuidCompetencia",
    name: "Relatório notas de saída",
    component: EmpresaNotasSaida,
    layout: "/admin",
    hide: true
  });

  dashRoutes.push({
    path: "/relatorio/nao_lancadas/:uuidEmpresa",
    name: "Relatório notas não lançadas",
    component: EmpresaNotasNaoLancadaCliente,
    layout: "/admin",
    hide: true
  });

  dashRoutes.push({
    path: "/relatorio/nao_lancadas",
    name: "Relatório notas de entrada",
    component: NotasNaoLancadas,
    layout: "/admin",
    hide: true
  });

  dashRoutes.push({
    path: "/relatorio",
    name: "Relatórios",
    icon: Bookmarks,
    component: DashboardRelatorioCliente,
    layout: "/admin"
  });
}

const subMenuConfiguracoes = [
  {
    path: "/configuracoes/usuario/:id",
    name: "Usuários",
    mini: "US",
    component: UsuarioSingle,
    hide: true,
    layout: "/admin"
  },
  {
    path: "/configuracoes/usuario",
    name: "Usuários",
    mini: "US",
    component: UsuarioManager,
    layout: "/admin"
  },
  {
    path: "/configuracoes/empresa",
    name: "Empresa",
    mini: "EP",
    component: Empresa,
    layout: "/admin"
  }
];

/*if (moduloAtivo('5ff031bf-ab5c-11ea-a580-7a791949cbcc')) {
  subMenuConfiguracoes.push({
    path: "/configuracoes/webiss",
    name: "WebISS",
    mini: "WI",
    component: WebISS,
    layout: "/admin"
  });
}*/

subMenuConfiguracoes.push({
  path: "/configuracoes/nfse",
  name: "NFS-e",
  mini: "NS",
  component: NFSe,
  layout: "/admin"
});

dashRoutes.push({
  name: "Configurações",
  icon: Settings,
  collapse: true,
  views: subMenuConfiguracoes
});

dashRoutes.push({
  path: "/ativacao",
  name: "Ativação do sistema",
  component: AtivacaoPage,
  layout: "/auth",
  hide: true
});


dashRoutes.push({
  path: "/changelog",
  name: "Atualizações do sistema",
  icon: CompareArrows,
  component: ChangeLogs,
  layout: "/admin",
  hide: false
});


dashRoutes.push({
  path: "/relatorio/entrada/:uuidEmpresa/:uuidCompetencia",
  name: "Relatório notas de entrada",
  component: EmpresaNotasEntradaCliente,
  layout: "/auth",
  hide: true
});

dashRoutes.push({
  path: "/relatorio/nao_lancadas/:uuidEmpresa",
  name: "Relatório notas não lançadas",
  component: EmpresaNotasNaoLancadaCliente,
  layout: "/auth",
  hide: true
});

dashRoutes.push({
  path: "/email",
  name: "Email",
  icon: Email,
  component: EnviarEmail,
  active: true,
  layout: "/admin"
});

dashRoutes.push({
  path: "/arquivos",
  name: "Arquivos",
  icon: AssignmentReturned,
  component: Downloads,
  active: true,
  layout: "/admin"
});

dashRoutes.push({
  path: "/sincronizador",
  name: "Sincronizador",
  icon: AssignmentReturned,
  component: DashboardSincronizador,
  hide: true,
  layout: "/sincronizador"
});

dashRoutes.push({path: "/login", name: "Acesso ao sistema", component: LoginPage, layout: "/auth", hide: true});
dashRoutes.push({path: "/cadastro", name: "Cadastre-se", component: RegisterPage, layout: "/auth", hide: true});
dashRoutes.push({path: "/precos", name: "Preços", component: PricingPage, layout: "/auth", hide: true});

export default dashRoutes;
