import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import {Tooltip} from "@material-ui/core";
import {buscarDadosCNPJ, nomeProprio} from "../../components/Funcoes";
import {infoColor} from "../../assets/jss/material-dashboard-pro-react";
import {Business, Phone, Search, Storefront} from "@material-ui/icons";
import * as Swal from "sweetalert2";
import {baseURL, WebService} from "../../components/WebService";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(styles);

export default function RegisterPage({history}) {
  const [nomeRazaoSocial, setNomeRazaoSocial] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [cnpj, setCNPJ] = useState("");

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [termoAceito, setTermoAceito] = useState(false);

  const buscarDadosEmpresaCNPJ = async cnpj => {
    const {empresa: {email, telefone1, telefone2, telefone3, nome_fantasia, nome: razao_social}, socios} = await buscarDadosCNPJ(cnpj);

    if (!nomeRazaoSocial) setNomeRazaoSocial(razao_social);
    setEmail(email || "");
    setTelefone(telefone1 || telefone2 || telefone3 || "");
    setNomeFantasia(nome_fantasia);

    let [{source: nome}] = socios;

    if (socios.length > 1) {
      const perfil = {};
      socios.map((prop, key) => {
        const {source} = prop;
        const [, , , , , nome] = source.split("*");
        perfil[key] = nomeProprio(nome);
      });

      const {value} = await Swal.fire({
        title: 'Selecione seu nome',
        input: 'select',
        inputOptions: perfil,
        inputPlaceholder: 'Selecione seu nome',
        showCancelButton: true,
        cancelButtonText: "Não estou na lista"
      });

      nome = "";
      if (value) {
        const {source} = socios[value];
        nome = source;
      }
    }

    setNome(nome.split("**")[2] || "");
  };

  const requisitarCertificado = async () => {
    const {ok, empresa: {cnpj, razao}} = await (await fetch(`https://${baseURL.split(":")[1]}:4433`)).json();
    if (ok) {
      setNomeRazaoSocial(razao);
      setCNPJ(cnpj);
      await buscarDadosEmpresaCNPJ(cnpj);
    }
  };

  const realizaCadastroEmpresa = async () => {
    if (!termoAceito) {
      await Swal.fire({icon: "info", title: "Aceite os termos de uso antes de continuar."});
      return false;
    }

    const cadastro = {razao_social: nomeRazaoSocial, nome_fantasia: nomeFantasia, cnpj, nome, email, telefone};
    const {ok} = await WebService(`/login/novaEmpresa`, cadastro, "POST");
    if (ok) {
      await Swal.fire({
        icon: "success",
        title: "Cadastro realizado com sucesso!",
        text: "Verifique sua caixa de email e informe a senha de confirmação enviada."
      });
      history.push(`/login`);
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.container} style={{marginTop: -130}}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <h3 className={classes.cardTitle}>Usar certificado digital</h3>
            <CardBody>
              <GridContainer justify="center">
                <GridItem lg={12} md={12} sm={12} xs={12}>
                  <div className={classes.center}>
                    <Tooltip title={"Certificado digital"}>
                      <Button justIcon round color="info" onClick={requisitarCertificado.bind(this)}>
                        {/*<i className="fas fa-file-invoice"/>*/}
                        <img
                          alt={"acessar-certificado-digital"}
                          src="https://www1.sped.fazenda.gov.br/login/Content/LoginTemplates/SPED/imagens/certificado.gif"
                        />
                      </Button>
                    </Tooltip>
                    <h4 className={classes.socialTitle}>ou preecha os dados abaixo</h4>
                  </div>
                </GridItem>
                <GridItem lg={6} md={6} sm={6} xs={12}>
                  <CustomInput
                    formControlProps={{fullWidth: true, className: classes.customFormControlClasses}}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className={classes.inputAdornment}>
                          <Icon className={classes.inputAdornmentIcon}>
                            filter_9_plus
                          </Icon>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" className={classes.inputAdornment}>
                          <Tooltip title={"Pesquisar"}>
                            <IconButton onClick={() => buscarDadosEmpresaCNPJ(cnpj)}>
                              <Search className={classes.inputAdornmentIcon}/>
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                      value: cnpj,
                      placeholder: "CNPJ",
                      onChange: ({target: {value}}) => setCNPJ(value)
                    }}
                  />
                  <CustomInput
                    formControlProps={{fullWidth: true, className: classes.customFormControlClasses}}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className={classes.inputAdornment}>
                          <Business className={classes.inputAdornmentIcon}/>
                        </InputAdornment>
                      ),
                      placeholder: "Razão Social",
                      value: nomeRazaoSocial,
                      onChange: ({target: {value}}) => setNomeRazaoSocial(value)
                    }}
                  />
                  <CustomInput
                    formControlProps={{fullWidth: true, className: classes.customFormControlClasses}}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className={classes.inputAdornment}>
                          <Storefront className={classes.inputAdornmentIcon}/>
                        </InputAdornment>
                      ),
                      placeholder: "Nome Fantasia",
                      value: nomeFantasia,
                      onChange: ({target: {value}}) => setNomeFantasia(value)
                    }}
                  />
                </GridItem>
                <GridItem lg={6} md={6} sm={6} xs={12}>
                  <CustomInput
                    formControlProps={{fullWidth: true, className: classes.customFormControlClasses}}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className={classes.inputAdornment}>
                          <Face className={classes.inputAdornmentIcon}/>
                        </InputAdornment>
                      ),
                      placeholder: "Nome",
                      value: nome,
                      onChange: ({target: {value}}) => setNome(value)
                    }}
                  />
                  <CustomInput
                    formControlProps={{fullWidth: true, className: classes.customFormControlClasses}}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className={classes.inputAdornment}>
                          <Email className={classes.inputAdornmentIcon}/>
                        </InputAdornment>
                      ),
                      placeholder: "Email",
                      value: email,
                      onChange: ({target: {value}}) => setEmail(value)
                    }}
                  />
                  <CustomInput
                    formControlProps={{fullWidth: true, className: classes.customFormControlClasses}}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className={classes.inputAdornment}>
                          <Phone className={classes.inputAdornmentIcon}/>
                        </InputAdornment>
                      ),
                      placeholder: "Telefone",
                      value: telefone,
                      onChange: ({target: {value}}) => setTelefone(value)
                    }}
                  />
                  {/************************************************************/}
                  <FormControlLabel
                    classes={{root: classes.checkboxLabelControl, label: classes.checkboxLabel}}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={termoAceito}
                        onClick={() => setTermoAceito(ta => !ta)}
                        checkedIcon={<Check className={classes.checkedIcon}/>}
                        icon={<Check className={classes.uncheckedIcon}/>}
                        classes={{checked: classes.checked, root: classes.checkRoot}}
                      />
                    }
                    label={
                      <span>
                        Aceito os {" "}
                        <a
                          href="https://gefis.com.br/termos-e-condicoes-de-uso-gefis"
                          target={"_blank"}
                          style={{color: infoColor[0]}}
                        >
                          termos de uso.
                        </a>.
                      </span>
                    }
                  />
                  <div className={classes.center}>
                    <Button round color="primary" onClick={realizaCadastroEmpresa.bind(this)}>
                      Começar agora!
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
