import React from "react";
import PropTypes from "prop-types";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";

class SpanH2 extends React.Component {
    render() {
        return (
            <h2
                style={{
                    color: this.props.mainColor ? primaryColor[0] : (this.props.color || '#9D9EA0'),
                    // fontFamily: 'Asap-Light',
                    textTransform: this.props.lower || 'uppercase',
                    fontWeight: this.props.simple || 700,
                    marginBottom: 0,
                    fontSize: '0.9rem',
                    width: this.props.fullWidth ? "100%" : null,
                    textAlign: this.props.fullWidth ? "center" : null,
                    ...this.props.style
                }}
                {...this.props.outros}
            >
                {this.props.children}
            </h2>
        );
    }
}

SpanH2.propTypes = {
    lower: PropTypes.bool,
    mainColor: PropTypes.bool,
    fullWidth: PropTypes.bool,
    simple: PropTypes.bool
};
export default SpanH2;


