import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Router, Switch, Redirect} from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import SincronizadorLayout from "layouts/Sincronizador.js";
import "../src/assets/scss/gefis.scss?v=1.8.0";
import {ls} from "./components/Funcoes";
import * as serviceWorker from './serviceWorker';
import {LoadRouterProps} from "./components/DataModule";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import Swal from "sweetalert2";
import "react-step-progress-bar/styles.css";
import {WsSincronizador} from "./components/WebService";

const hist = createBrowserHistory();
require('moment/locale/pt-br');

const App = () => {
  const [token, setToken] = useState(ls('token'));

  useEffect(() => {
    ReactGA.initialize('UA-159474742-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    ReactPixel.init('213840093069131');
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    localStorage.setItem("token", token.toString() || "");
  }, [token]);

  useEffect(() => {
    verificarSincronizador();
  }, []);

  const verificarSincronizador = async () => {
    if (window.location.pathname !== '/sincronizador') {
      const {ok} = await WsSincronizador('/v1/sistema/status', {}, "GET", false, false);
      if (ok) {
        const {value} = await Swal.fire({
          title: 'Sincronizador encontrado',
          text: "Deseja acessar as configurações?",
          icon: "question",
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
        });
        if (value) hist.push(`/sincronizador`);
      }
    }
  };

  return (
    <Router history={hist}>
      <Switch>
        <LoadRouterProps path="/sincronizador" component={SincronizadorLayout}/>
        {token ? (
          <LoadRouterProps path="/" component={AdminLayout} setToken={setToken} token={token}/>
        ) : (
          <LoadRouterProps path="/" component={AuthLayout} setToken={setToken}/>
        )}
        <Redirect from="/" to={ls('token') ? "/painel" : "/login"}/>
      </Switch>
    </Router>
  );
};

ReactDOM.render(<App/>, document.getElementById("root"));

serviceWorker.unregister();
/*serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          Swal.fire({
            icon: "info",
            title: 'Uma nova versão do sistema está disponível.',
            text: 'A página será atualizada.'
          }).then(() => window.location.reload());
        }
      });
      waitingServiceWorker.postMessage({type: "SKIP_WAITING"});
    }
  }
});*/
