import React, {useEffect, useState} from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Button from "../../components/CustomButtons/Button";
import {WebService} from "../../components/WebService";
import * as Swal from "sweetalert2";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Switch from "@material-ui/core/Switch";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {FiberManualRecord} from "@material-ui/icons";
import Checkbox from "../../components/CustomInput/CustomCheckbox";

const useStyles = makeStyles(styles);

const Title = ({label}) => (
  <GridItem lg={12} md={12} sm={12} xs={12}>
    <h3 style={{marginBottom: -20}}><small>{label}</small></h3>
    <hr/>
  </GridItem>
);

export default function Empresa() {
  const classes = useStyles();
  const [diaEnvioRelatorio, setDiaEnvioRelatorio] = useState("0");
  const [baixarCTeTomador, setBaixarCTeTomador] = useState(false);

  const [separar_documentos_ie, set_separar_documentos_ie] = useState(false);

  const [modeloFormacaoDiretorioDownload, setModeloFormacaoDiretorioDownload] = useState(false);
  const [modelo_formacao_nome_arquivo_download, set_modelo_formacao_nome_arquivo_download] = useState("chave");

  const [enviar_relatorio_automatico_entrada, set_enviar_relatorio_automatico_entrada] = useState(false);
  const [enviar_relatorio_automatico_saidas, set_enviar_relatorio_automatico_saidas] = useState(false);
  const [enviar_relatorio_automatico_notas_nao_lancadas, set_enviar_relatorio_automatico_notas_nao_lancadas] = useState(false);
  const [enviar_relatorio_automatico_servico, set_enviar_relatorio_automatico_servico] = useState(false);

  const [baixar_pdf_danfe, set_baixar_pdf_danfe] = useState(false);
  const [baixar_pdf_dacte, set_baixar_pdf_dacte] = useState(false);
  const [baixar_pdf_danfse, set_baixar_pdf_danfse] = useState(false);

  const salvarConfiguracoes = async () => {
    const configuracoes = {
      baixar_apenas_cte_tomador: baixarCTeTomador,
      dia_envio_relatorio_cliente: diaEnvioRelatorio,
      modelo_formacao_diretorio_download: modeloFormacaoDiretorioDownload,
      enviar_relatorio_automatico_entrada,
      enviar_relatorio_automatico_saidas,
      enviar_relatorio_automatico_servico,
      enviar_relatorio_automatico_notas_nao_lancadas,
      baixar_pdf_danfe,
      baixar_pdf_dacte,
      baixar_pdf_danfse,
      modelo_formacao_nome_arquivo_download,
      separar_documentos_ie
    };

    const {ok} = await WebService(`/empresa/atualizaConfigEmpresa`, {configuracoes}, "POST");
    if (ok)
      await Swal.fire({icon: "success", title: "Configurações salvas com sucesso!"});
  };

  const getConfiguracaoEmpresa = async () => {
    const {ok, configuracoesEmpresa} = await WebService(`/empresa/getEmpresa`, {config: true});
    if (ok) {
      const {
        baixar_apenas_cte_tomador,
        dia_envio_relatorio_cliente,
        modelo_formacao_diretorio_download,
        enviar_relatorio_automatico_entrada,
        enviar_relatorio_automatico_servico,
        enviar_relatorio_automatico_saidas,
        enviar_relatorio_automatico_notas_nao_lancadas,
        baixar_pdf_danfe,
        baixar_pdf_dacte,
        baixar_pdf_danfse,
        modelo_formacao_nome_arquivo_download,
        separar_documentos_ie
      } = configuracoesEmpresa;
      setBaixarCTeTomador(baixar_apenas_cte_tomador);
      set_separar_documentos_ie(separar_documentos_ie);
      setDiaEnvioRelatorio(dia_envio_relatorio_cliente.toString());
      setModeloFormacaoDiretorioDownload(modelo_formacao_diretorio_download);
      set_enviar_relatorio_automatico_notas_nao_lancadas(enviar_relatorio_automatico_notas_nao_lancadas);
      set_enviar_relatorio_automatico_entrada(enviar_relatorio_automatico_entrada);
      set_enviar_relatorio_automatico_servico(enviar_relatorio_automatico_servico);
      set_enviar_relatorio_automatico_saidas(enviar_relatorio_automatico_saidas);
      set_baixar_pdf_danfe(baixar_pdf_danfe);
      set_baixar_pdf_dacte(baixar_pdf_dacte);
      set_baixar_pdf_danfse(baixar_pdf_danfse);
      set_modelo_formacao_nome_arquivo_download(modelo_formacao_nome_arquivo_download);
    }
  };

  useEffect(() => {
    getConfiguracaoEmpresa();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <Title label={"Downloads - Documentos Fiscais"}/>
                <GridItem lg={3} md={4} sm={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={separar_documentos_ie}
                        onChange={() => set_separar_documentos_ie(!separar_documentos_ie)}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar
                        }}
                      />
                    }
                    classes={{label: classes.label}}
                    label="Seprar documentos por IE"
                  />
                  <hr style={{marginTop: 5, marginBottom: 5, width: '50%', float: 'left'}}/>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={baixarCTeTomador}
                        onChange={() => setBaixarCTeTomador(!baixarCTeTomador)}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar
                        }}
                      />
                    }
                    classes={{label: classes.label}}
                    label="Baixar apenas CT-e tomador"
                  />
                  <hr style={{marginTop: 5, marginBottom: 5, width: '50%', float: 'left'}}/>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={baixar_pdf_danfe}
                        onChange={() => set_baixar_pdf_danfe(!baixar_pdf_danfe)}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar
                        }}
                      />
                    }
                    classes={{label: classes.label}}
                    label="Baixar PDF DANFE (NFe - 55)"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={baixar_pdf_dacte}
                        onChange={() => set_baixar_pdf_dacte(!baixar_pdf_dacte)}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar
                        }}
                      />
                    }
                    classes={{label: classes.label}}
                    label="Baixar PDF DACTE (CTe - 67)"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={baixar_pdf_danfse}
                        onChange={() => set_baixar_pdf_danfse(!baixar_pdf_danfse)}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar
                        }}
                      />
                    }
                    classes={{label: classes.label}}
                    label="Baixar PDF DANFSE (NFSe)"
                  />
                </GridItem>
                <GridItem lg={3} md={4} sm={6} xs={12}>
                  <h5>Modelo da formação de nomes e diretório</h5>
                  <RadioGroup
                    row
                    value={modeloFormacaoDiretorioDownload}
                    onChange={({target}) => setModeloFormacaoDiretorioDownload(target.value)}
                  >
                    {['razao_cnpj', 'cnpj_razao'].map((prop, key) => (
                      <FormControlLabel
                        key={key}
                        control={
                          <Radio
                            value={prop.toLowerCase()}
                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                            checkedIcon={<FiberManualRecord className={classes.radioChecked}/>}
                            classes={{checked: classes.radio, root: classes.radioRoot}}
                          />
                        }
                        classes={{label: classes.label, root: classes.labelRoot}}
                        label={prop.replace("_", " - ").toUpperCase()}
                        labelPlacement="left"
                      />
                    ))}
                  </RadioGroup>
                  <FormControl fullWidth>
                    <InputLabel>Nome do arquivo ao baixar</InputLabel>
                    <Select
                      value={modelo_formacao_nome_arquivo_download || "chave"}
                      onChange={({target}) => set_modelo_formacao_nome_arquivo_download(target.value)}
                    >
                      <MenuItem value={"chave"}>Chave</MenuItem>
                      {[
                        'cnpj_serie_numeronf', 'cnpj_numeronf_serie', 'numeronf_serie_cnpj', 'numeronf_cnpj_serie',
                        'serie_numeronf_cnpj', 'serie_cnpj_numeronf'
                      ].map((prop, key) => (
                        <MenuItem key={key} value={prop}>
                          {prop.split("_").join(" - ").toUpperCase()}
                        </MenuItem>
                      ))}

                      {/*<MenuItem value={"numeronf_serie_cnpj"}>NumeroNF - Série - CNPJ</MenuItem>*/}
                    </Select>
                  </FormControl>
                </GridItem>
                <Title label={"Relatórios"}/>
                <GridItem lg={3} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Dia do envio automático dos relatórios</InputLabel>
                    <Select
                      value={diaEnvioRelatorio || "0"}
                      onChange={({target}) => setDiaEnvioRelatorio(target.value)}
                    >
                      <MenuItem value={"0"}>Não enviar</MenuItem>
                      {new Array(31).fill(null).map((prop, key) => (
                        <MenuItem key={key} value={key + 1}>{key + 1}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                {!!parseInt(diaEnvioRelatorio) && (
                  <GridItem lg={4} md={5} sm={6} xs={12}>
                    <FormControlLabel
                      control={<Checkbox
                        checked={enviar_relatorio_automatico_entrada}
                        onChange={() => set_enviar_relatorio_automatico_entrada(!enviar_relatorio_automatico_entrada)}
                      />}
                      label={"Enviar relatório automático de entradas"}
                    />
                    <FormControlLabel
                      control={<Checkbox
                        checked={enviar_relatorio_automatico_saidas}
                        onChange={() => set_enviar_relatorio_automatico_saidas(!enviar_relatorio_automatico_saidas)}
                      />}
                      label={"Enviar relatório automático de saídas"}
                    />
                    <FormControlLabel
                      control={<Checkbox
                        checked={enviar_relatorio_automatico_notas_nao_lancadas}
                        onChange={() => set_enviar_relatorio_automatico_notas_nao_lancadas(!enviar_relatorio_automatico_notas_nao_lancadas)}
                      />}
                      label={"Enviar relatório automático de notas não lançadas"}
                    />
                    <FormControlLabel
                      control={<Checkbox
                        checked={enviar_relatorio_automatico_servico}
                        onChange={() => set_enviar_relatorio_automatico_servico(!enviar_relatorio_automatico_servico)}
                      />}
                      label={"Enviar relatório automático de notas de serviço"}
                    />
                  </GridItem>
                )}
                <GridItem lg={12} md={12} sm={12} xs={12}>
                  <br/><br/><br/>
                  <GridContainer justify={"space-between"}>
                    <GridItem lg={1} md={1} sm={1} xs={1}/>
                    <GridItem lg={3} md={4} sm={6} xs={12}>
                      <Button color={"info"} onClick={salvarConfiguracoes.bind(this)} fullWidth>
                        Salvar
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
