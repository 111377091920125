import React, {useEffect, useState} from "react";
import {WebService} from "../../components/WebService";
import Swal from "sweetalert2";
import ModalEnviarEmail from "../Components/ModalEnviarEmail";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import CustomInput from "../../components/CustomInput/CustomInput";

export default function EnviarEmail() {

  const [openModal, setOpenModal] = useState(false);
  const [conteudoEmail, setConteudoEmail] = useState("");

  const [email_nome_exibicao, set_email_nome_exibicao] = useState("");
  const [email_protocolo_endereco_hostpedagem, set_email_protocolo_endereco_hostpedagem] = useState("");
  const [email_protocolo_porta, set_email_protocolo_porta] = useState("");
  const [email_protocolo_email, set_email_protocolo_email] = useState("");
  const [email_protocolo_senha, set_email_protocolo_senha] = useState("");

  const getDadosEmail = async () => {
    const {ok, dadosEmail} = await WebService(`/empresa/getDadosEmail`);
    if (ok) {
      const {email_nome_exibicao, email_protocolo_endereco_hostpedagem, email_protocolo_porta, email_protocolo_email, email_protocolo_senha} = dadosEmail;

      set_email_nome_exibicao(email_nome_exibicao);
      set_email_protocolo_endereco_hostpedagem(email_protocolo_endereco_hostpedagem);
      set_email_protocolo_porta(email_protocolo_porta);
      set_email_protocolo_email(email_protocolo_email);
      set_email_protocolo_senha(email_protocolo_senha);
    }
  };

  const testarDadosEmail = async () => {
    const dadosTeste = {
      email_nome_exibicao,
      email_protocolo_endereco_hostpedagem,
      email_protocolo_porta,
      email_protocolo_email,
      email_protocolo_senha
    };

    const {ok} = await WebService(`/empresa/testarDadosEmail`, dadosTeste, "POST");
    if (ok) {
      await Swal.fire({icon: "success", title: "Email testado com sucesso!"});
      const {value} = await Swal.fire({
        icon: "question",
        title: "Deseja salvar as informações?",
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
      });

      if (value)
        await salvarDadosEmail();
    }
  };

  const salvarDadosEmail = async () => {
    const dadosTeste = {
      email_nome_exibicao,
      email_protocolo_endereco_hostpedagem,
      email_protocolo_porta,
      email_protocolo_email,
      email_protocolo_senha
    };

    const {ok} = await WebService(`/empresa/salvarDadosEmail`, dadosTeste, "POST");
    if (ok) {
      await Swal.fire({icon: "success", title: "Dados do email atualizados com sucesso!"});
      await getDadosEmail();
    }
  };

  useEffect(() => {
    getDadosEmail();
  }, []);

  return (
    <div>
      <ModalEnviarEmail
        open={openModal}
        onCloseModal={() => setOpenModal(false)}
        conteudoEmail={conteudoEmail}
        onConteudoEmailChange={texto => setConteudoEmail(texto)}
        onSendMail={() => ""}
      />
      {/*<Button
        color={"info"}
        onClick={() => setOpenModal(true)}
      >
        Enviar Email para todos os clientes
      </Button>*/}
      <GridContainer>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem lg={4} md={4} sm={6} xs={12}>
                  <CustomInput
                    labelText="Servidor SMTP"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      onChange: ({target: {value}}) => set_email_protocolo_endereco_hostpedagem(value),
                      value: email_protocolo_endereco_hostpedagem,
                      endAdornment: (<InputAdornment position="end"> <Email/> </InputAdornment>)
                    }}
                  />
                </GridItem>
                <GridItem lg={2} md={2} sm={4} xs={12}>
                  <CustomInput
                    labelText="Porta"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      onChange: ({target: {value}}) => set_email_protocolo_porta(value),
                      value: email_protocolo_porta,
                      endAdornment: (<InputAdornment position="end"> <Email/> </InputAdornment>)
                    }}
                  />
                </GridItem>
                <GridItem lg={12} md={12} sm={12} xs={12}/>
                <GridItem lg={4} md={4} sm={6} xs={12}>
                  <CustomInput
                    labelText="Nome de Exibição"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      onChange: ({target: {value}}) => set_email_nome_exibicao(value),
                      value: email_nome_exibicao,
                      endAdornment: (<InputAdornment position="end"> <Email/> </InputAdornment>)
                    }}
                  />
                </GridItem>
                <GridItem lg={4} md={4} sm={6} xs={12}>
                  <CustomInput
                    labelText="Endereço de email"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      onChange: ({target: {value}}) => set_email_protocolo_email(value),
                      value: email_protocolo_email,
                      endAdornment: (<InputAdornment position="end"> <Email/> </InputAdornment>)
                    }}
                  />
                </GridItem>
                <GridItem lg={3} md={3} sm={4} xs={12}>
                  <CustomInput
                    labelText="Senha"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      type: 'password',
                      onChange: ({target: {value}}) => set_email_protocolo_senha(value),
                      value: email_protocolo_senha,
                      endAdornment: (<InputAdornment position="end"> <Email/> </InputAdornment>)
                    }}
                  />
                </GridItem>
                <GridItem lg={12} md={12} sm={12} xs={12}>
                  <Button color={"info"} onClick={testarDadosEmail} simple>
                    Testar
                  </Button>
                  <Button color={"info"} onClick={salvarDadosEmail}>
                    Salvar
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}
