import React, {useEffect} from "react";

import defaultImage from "../../assets/img/certificado.jpg";
import Dialog from "@material-ui/core/Dialog";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {makeStyles} from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import modalStyle from "../../assets/jss/material-dashboard-pro-react/modalStyle";

const useStyles = makeStyles(modalStyle);
let imageRef;
let fileUrl;

export default function PictureUpload({label, accept, onUpload, preview, recortar}) {
  const classes = useStyles();
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(preview || defaultImage);
  const [crop, setCrop] = React.useState({aspect: 1, width: 50, x: 0, y: 0,});
  const [croppedImageUrl, setCroppedImageUrl] = React.useState("");
  const [base64corte, setBase64corte] = React.useState("");

  useEffect(() => {
    if (preview)
      setImagePreviewUrl(preview);
  }, [preview]);

  const makeClientCrop = async (crop, c1) => {
    if (imageRef && crop.width && crop.height)
      setCroppedImageUrl(await getCroppedImg(imageRef, crop, 'newFile.jpeg'));
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
    const base64 = canvas.toDataURL();
    setBase64corte(base64);

    return new Promise(resolve => canvas.toBlob(blob => {
      if (!blob)
        return;
      blob.name = fileName;
      window.URL.revokeObjectURL(fileUrl);
      fileUrl = window.URL.createObjectURL(blob);
      resolve(fileUrl);
    }, 'image/jpeg'));
  };

  const handleImageChange = e => {
    e.preventDefault();
    const reader = new FileReader();
    const newFile = e.target.files[0];
    if (newFile) {
      reader.onloadend = () => {
        setFile(reader.result);
        if (onUpload && !recortar) {
          // onUpload(reader.result.split(";")[1].slice(7));
          onUpload(reader.result);
        }
        // setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(newFile);
    }
  };

  // eslint-disable-next-line
  return (
    <div className="picture-container">
      <Dialog
        fullWidth
        fullScreen={true}
        classes={{root: classes.center + " " + classes.modalRoot, paper: classes.modal + " " + classes.modal80}}
        open={file && recortar}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => {

        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <div style={{maxWidth: '100%'}}>
          <ReactCrop
            src={file ? file : null}
            crop={crop}
            onImageLoaded={image => imageRef = image}
            onComplete={makeClientCrop.bind(this)}
            onChange={crop => setCrop(crop)}
          />
        </div>
        <Button
          color={"info"}
          simple
          onClick={() => {
            setFile(null);
            setImagePreviewUrl(croppedImageUrl);
            if (onUpload && recortar)
              onUpload(base64corte, croppedImageUrl);
          }}
        >
          Salvar
        </Button>
      </Dialog>
      <div className="picture">
        <img src={imagePreviewUrl} className="picture-src" alt="..."/>
        <input
          type={"file"}
          onChange={handleImageChange.bind(this)}
          accept={accept}
          onClick={e => e.target.value = null}
        />
      </div>
      <h6 className="description">{label}</h6>
    </div>
  );
}
