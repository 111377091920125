import React, {useEffect, useState} from "react";
import CardBody from "../../../components/Card/CardBody";
import Card from "../../../components/Card/Card";
import LiveSearch from "../../Components/LiveSearch";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import ResponsiveTable from "../../../components/ResponsiveTable";
import CustomInput from "../../../components/CustomInput/CustomInput";
import Button from "../../../components/CustomButtons/Button";
import {WebService} from "../../../components/WebService";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import * as Swal from "sweetalert2";
import {ArrowBack, CallMissedOutgoing} from "@material-ui/icons";

const modeloLabel = {55: 'NF-e', 65: 'NFC-e'};

export default function NotasNaoLancadas({location, history}) {
  const [inicio, setInicio] = useState(1);
  const [final, setFinal] = useState("");
  const [tipo_nota, setTipoNota] = useState("");
  const [serie, setSerie] = useState("");
  const [series, setSeries] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [maioresNumeracoes, setMaioresNumeracoes] = useState([]);
  const [id_empresa, setIdEmpresa] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [notasNaoLancadas, setNotasNaoLancadas] = useState([]);
  const [buscar, setBuscar] = useState(false);

  useEffect(() => {
    buscarNotas();
  }, [buscar]);

  const buscarNotas = async () => {
    const pesquisa = {inicio, final, id_empresa, tipo_nota, serie};
    const {ok, notasNaoLancadasLabel} = await WebService(`/relatorio/getNotasNaoLancadas`, pesquisa);
    if (ok) {
      setNotasNaoLancadas(notasNaoLancadasLabel);
      if (!notasNaoLancadasLabel.length)
        await Swal.fire({icon: "success", title: "Nenhuma nota não lançada para a numeração informada!"});
    }
  };

  const getResumosEmpresa = async () => {
    if (id_empresa) {
      const {ok, maior_numeracao, series, modelos} = await WebService(`/empresa/getResumoNotaEmpresa`, {id_empresa});
      if (ok) {
        setMaioresNumeracoes(maior_numeracao);
        setInicio(1);
        setModelos(modelos);
        setSeries(series);
        if (razaoSocial && modelos.length && series.length) {
          setTipoNota(modelos[0].tipo_nota);
          setSerie(series[0].serie);
          setBuscar(!buscar);
        }
      }
    }
  };

  const organizarUltimoNumero = () => {
    if (serie && tipo_nota)
      setFinal(maioresNumeracoes[tipo_nota][serie] || 1);
  };

  useEffect(() => {
    const {state} = location;
    const {id_empresa, nome_razao_social} = state;
    if (id_empresa)
      setIdEmpresa(id_empresa);
    if (nome_razao_social)
      setRazaoSocial(nome_razao_social);
  }, [location]);

  useEffect(() => {
    getResumosEmpresa();
    // eslint-disable-next-line
  }, [id_empresa]);

  useEffect(() => {
    organizarUltimoNumero();
    // eslint-disable-next-line
  }, [serie, tipo_nota]);

  return (
    <div>
      <Button color={"info"} onClick={() => history.goBack()}>
        <ArrowBack/> Voltar
      </Button>
      <Button color={"info"} onClick={() => history.push(`/relatorio/nao_lancadas/${id_empresa}`)}>
        <CallMissedOutgoing/> Lançar notas
      </Button>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem lg={4} md={4} sm={6} xs={12}>
              {(
                <div>
                  <p>&nbsp;</p>
                  <h4>{razaoSocial}</h4>
                </div>
              ) || (
                <LiveSearch label={"Empresa"} url={"/filtro/empresa"} onChange={setIdEmpresa}/>
              )}
            </GridItem>
            <GridItem lg={3} md={4} sm={6} xs={12}>
              <p/>
              <FormControl fullWidth>
                <InputLabel>Espécie</InputLabel>
                <Select value={tipo_nota} onChange={({target}) => setTipoNota(target.value)}>
                  {modelos.map((prop, key) => (
                    <MenuItem key={key} value={prop.tipo_nota}>{modeloLabel[prop.tipo_nota]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem lg={3} md={4} sm={6} xs={12}>
              <p/>
              <FormControl fullWidth>
                <InputLabel>Série</InputLabel>
                <Select value={serie} onChange={({target}) => setSerie(target.value)}>
                  {series.map((prop, key) => <MenuItem key={key} value={prop.serie}>{prop.serie}</MenuItem>)}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem lg={3} md={4} sm={6} xs={12}>
              <CustomInput
                labelText="Intervalo numeração de início"
                formControlProps={{fullWidth: true}}
                inputProps={{type: "number", value: inicio, onChange: ({target}) => setInicio(target.value)}}
              />
            </GridItem>
            <GridItem lg={3} md={4} sm={6} xs={12}>
              <CustomInput
                labelText="Intervalo numeração final"
                formControlProps={{fullWidth: true}}
                inputProps={{type: "number", value: final, onChange: ({target}) => setFinal(target.value)}}
              />
            </GridItem>
            <GridItem lg={12} md={12} sm={12} xs={12}>
              <GridContainer justify={"center"}>
                <GridItem lg={3} md={4} sm={6} xs={12}>
                  <Button fullWidth color={"info"} onClick={buscarNotas}>
                    Buscar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem lg={12} md={12} sm={12} xs={12}>
              <ResponsiveTable
                colunas={["Numeração", "Série", "Espécie"]}
                dados={notasNaoLancadas.map(prop => [prop, serie, modeloLabel[tipo_nota]])}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
};
