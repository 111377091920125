import React, {useEffect, useState} from "react";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import {infoColor, whiteColor} from "../../assets/jss/material-dashboard-pro-react";
import NavPills from "../../components/NavPills/NavPills";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import {
  Add,
  ArrowBack, AttachFile,
  Business,
  Close,
  Description,
  Lock,
  Person,
  Search,
  Store,
  Timeline
} from "@material-ui/icons";
import CustomInput from "../../components/CustomInput/CustomInput";
import PictureUpload from "../../components/CustomUpload/PictureUpload";
import UsuarioManager from "../Configuracoes/UsuarioManager";
import {WebService} from "../../components/WebService";
import Swal from "sweetalert2";
import Button from "../../components/CustomButtons/Button";
import Dashboard from "../Dashboard/Dashboard";
import UsuarioSingle from "../Configuracoes/UsuarioSingle";
import Grow from "@material-ui/core/Grow";
import CardHeader from "../../components/Card/CardHeader";
import CardText from "../../components/Card/CardText";
import {cardTitle} from "../../assets/jss/material-dashboard-pro-react.js";
import {buscarDadosCNPJ, estadosUF, formataCPFCNPJ, ls, nomeProprio, somenteNumero} from "../../components/Funcoes";
import ResponsiveTable from "../../components/ResponsiveTable";
import CheckboxAPI from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Select, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CustomTable from "../../components/Table/Table";

const ie = require("inscricaoestadual");

const styles1 = {
  cardTitle,
  cardTitleWhite: {...cardTitle, color: "#FFFFFF", marginTop: "0"},
  cardCategoryWhite: {margin: "0", color: "rgba(255, 255, 255, 0.8)", fontSize: ".875rem"}
};

const useStyles = makeStyles({...styles, ...extendedFormsStyle, selectWhite: {color: whiteColor}, ...styles1});
const Checkbox = withStyles({root: {color: infoColor[0], '&$checked': {color: infoColor[0]}}})(props => (
  <CheckboxAPI color="default" {...props} />
));

export default function ManagerEmpresa({computedMatch, history, flag_contabilidade}) {
  const classes = useStyles();

  const [idEmpresa, setIdEmpresa] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [cnpj, setCNPJ] = useState("");
  const [CNAEFiscal, setCNAEFiscal] = useState("");
  const [UF, setUF] = useState("");
  const [UUID, setUUID] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");
  const [inscricaoMunicipal, setInscricaoMunicipal] = useState("");
  const [, setVencimentoCertificado] = useState("");
  const [idContatoEditar, setIdContatoEditar] = useState("");
  const [empresasRelacionadas, setEmpresasRelacionadas] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [inscricoes, setIncricoes] = useState([]);

  /*const [status] = useState([
    {descricao: 'NF-e Entrada', percentual: 56, status: 'Sincronizando'},
    {descricao: 'CT-e Entrada', percentual: 80, status: 'Sincronizando'},
    {descricao: 'NF-e Pendentes', percentual: 13, status: 'Sincronizando'}
  ]);

  const [statusSaida] = useState([
    {nomeComputados: 'CAIXA-01', percentual: 1, status: 'Enviando notas'},
    {nomeComputados: 'CAIXA-02', percentual: 32, status: 'Enviando notas'},
    {nomeComputados: 'CAIXA-03', percentual: 93, status: 'Enviando notas'},
    {nomeComputados: 'CAIXA-04', percentual: 67, status: 'Enviando notas'},
    {nomeComputados: 'Administração', percentual: 100, status: 'Aguardando próxima leitura'},
  ]);

  const [statusServicos] = useState([
    {descricao: 'NFS-e Prestado', percentual: 5, status: 'Sincronizando'},
    {descricao: 'NFS-e Tomado', percentual: 0, status: 'Sincronizando'}
  ]);*/

  const getDadosEmpresa = async () => {
    if (!idEmpresa) return;
    const {
      ok,
      nome_razao_social,
      nome_fantasia,
      cnpj,
      inscricao_estadual,
      inscricao_municipal,
      cnae_fiscal,
      uf,
      data_vencimento_certificado,
      uuid,
      empresasRelacionadas,
      inscricoes
    } = await WebService(`/empresa/getEmpresa`, {id_empresa: idEmpresa});
    if (ok) {
      setRazaoSocial(nome_razao_social);
      setNomeFantasia(nome_fantasia);
      setCNPJ(cnpj);
      setCNAEFiscal(cnae_fiscal);
      setUF(uf);
      setInscricaoEstadual(inscricao_estadual);
      setInscricaoMunicipal(inscricao_municipal);
      setVencimentoCertificado(new Date(data_vencimento_certificado));
      setUUID(uuid);
      setEmpresasRelacionadas(empresasRelacionadas);
      setIncricoes(inscricoes);
    }
  };

  const buscarCNPJ = async cnpj => {
    const {ok, empresa} = await buscarDadosCNPJ(cnpj);
    if (ok) {
      const {nome_fantasia, cnae_fiscal, uf, razao_social} = empresa;
      setNomeFantasia(nome_fantasia);
      setRazaoSocial(razao_social);
      setCNAEFiscal(cnae_fiscal);
      setUF(uf);
      // this.setState({nome_fantasia, cnae_fiscal, uf, razao_social});
    }
  }

  const getModulos = async () => {
    if (!idEmpresa || (ls('empresa_uuid') === '1443e267-5b3b-11ea-975c-028f0502a388')) return;
    const {ok, modulos} = await WebService(`/sistema/getModulos`, {id_empresa: idEmpresa});
    if (ok) setModulos(modulos);
  };

  const atualizaEmpresa = async () => {
    const dadosEmpresa = {
      nome_razao_social: razaoSocial,
      nome_fantasia: nomeFantasia,
      inscricao_estadual: inscricaoEstadual,
      inscricao_municipal: inscricaoMunicipal,
      cnae_fiscal: CNAEFiscal,
      uf: UF,
      uuid: UUID
    };
    const {ok} = await WebService(`/empresa/atualizaEmpresa`, dadosEmpresa, "POST");
    if (ok) {
      await Swal.fire({icon: "success", title: "Empresa atualizada com sucesso!"});
    }
  };

  const alterarCertificado = async arquivo => {
    const {value: senha} = await Swal.fire({
      type: "question",
      title: "Qual a senha do certificado?",
      input: 'password',
      inputPlaceholder: 'Entre com sua senha.',
      inputAttributes: {maxlength: 20, autocapitalize: 'off', autocorrect: 'off'}
    });

    if (senha) {
      const {ok, cnpj: cnpjCertificado, flag_assinado, uuid, data_final} = await WebService(`/certificado/upload`, {
        arquivo,
        senha,
        ignora_verificacao_empresa: true
      }, "POST");
      if (ok) {
        if (flag_assinado) {
          if (cnpjCertificado.toString().slice(0, 8) === cnpj.toString().slice(0, 8)) {
            const {ok} = await WebService(`/empresa/atualizaCertificado`, {
              uuid,
              certificado: arquivo,
              senha,
              data_final,
              id_empresa: idEmpresa
            }, "POST");
            if (ok) {
              await Swal.fire({icon: "success", title: "Certificado digital atualizado com sucesso!"});
            }
          } else
            await Swal.fire({icon: "warning", title: "O CNPJ do certificado não é igual ao cadastrado na empresa."});
        } else {
          await Swal.fire({
            icon: "warning",
            title: "Certificado incompleto.",
            text: `O certificado informado é válido porém não contem a chave privada. Considere realizar o upload de um certificado com a chave privada.`
          });
        }
      }
    } else {
      await Swal.fire({icon: "warning", title: "A senha não pode ser vazia."});
    }
  };

  const alterarStatusModuloEmpresa = async (id_sistema_modulo, flag_ativo, key) => {
    const {ok} = await WebService(`/sistema/alterarStatusModuloEmpresa`, {
      id_sistema_modulo,
      flag_ativo,
      id_empresa: idEmpresa
    });
    if (ok) {
      const tempModulos = [...modulos];
      tempModulos[key].flag_ativo = flag_ativo;
      setModulos(tempModulos);

      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      });
      await Toast.fire({icon: 'success', title: 'Módulo atualizado com sucesso!'});
    }
  };

  useEffect(() => {
    const {params} = computedMatch;
    let id_empresa;
    if (params) {
      let {idEmpresa} = params;
      id_empresa = !isNaN(parseInt(idEmpresa)) ? idEmpresa : null;
      setIdEmpresa(id_empresa);
    }
  }, [computedMatch]);

  useEffect(() => {
    getDadosEmpresa();
    getModulos();
  }, [idEmpresa]);

  return (
    <div>
      <Button color={"info"} onClick={() => history.goBack()} style={{marginLeft: 15}}>
        <ArrowBack/> Voltar
      </Button>
      <GridContainer>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <Card plain>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>{nomeProprio(razaoSocial)}</h4>
                <h4 className={classes.cardCategoryWhite}>
                  {nomeProprio(nomeFantasia)} - {formataCPFCNPJ(cnpj)}
                </h4>
              </CardText>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <NavPills
            color="info"
            horizontal={{tabsGrid: {xs: 12, sm: 12, md: 3, lg: 2}, contentGrid: {xs: 12, sm: 12, md: 9, lg: 10}}}
            withAnimation
            // active={4}
            tabs={[
              {
                tabButton: "Perfil",
                tabIcon: Business,
                tabContent: (
                  <Card>
                    <CardBody>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12} lg={10}>
                          <GridContainer>
                            <GridItem lg={6} md={6} sm={6} xs={12}>
                              <CustomInput
                                labelText="Razão Social"
                                formControlProps={{fullWidth: true}}
                                inputProps={{
                                  type: "text",
                                  value: razaoSocial,
                                  onChange: ({target}) => setRazaoSocial(target.value)
                                }}
                              />
                            </GridItem>
                            <GridItem lg={6} md={6} sm={6} xs={12}>
                              <CustomInput
                                labelText="Nome Fantasia"
                                formControlProps={{fullWidth: true}}
                                inputProps={{
                                  type: "text",
                                  value: nomeFantasia,
                                  onChange: ({target}) => setNomeFantasia(target.value)
                                }}
                              />
                            </GridItem>
                            <GridItem lg={4} md={4} sm={6} xs={12}>
                              <CustomInput
                                labelText="CNPJ"
                                formControlProps={{fullWidth: true, disabled: true}}
                                inputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end" className={classes.inputAdornment}>
                                      <Tooltip title={"Buscar CNPJ"}>
                                        <IconButton onClick={() => buscarCNPJ(cnpj)}>
                                          <Search style={{color: infoColor[0]}} className={classes.inputAdornmentIcon}/>
                                        </IconButton>
                                      </Tooltip>
                                    </InputAdornment>
                                  ),
                                  type: "text",
                                  value: cnpj,
                                  onChange: ({target}) => setCNPJ(target.value)
                                }}
                              />
                            </GridItem>
                            <GridItem lg={4} md={4} sm={6} xs={12}>
                              <CustomInput
                                labelText="CNAE"
                                formControlProps={{fullWidth: true}}
                                inputProps={{
                                  type: "text",
                                  value: CNAEFiscal,
                                  onChange: ({target}) => setCNAEFiscal(target.value)
                                }}
                              />
                            </GridItem>
                            <GridItem lg={4} md={4} sm={6} xs={12}>
                              <InputLabel style={{marginTop: 10}}>UF</InputLabel>
                              <Select
                                fullWidth
                                value={UF}
                                onChange={({target}) => setUF(target.value)}
                              >
                                {Object.keys(estadosUF).map(prop => {
                                  return (<MenuItem value={prop} key={prop}>{prop}</MenuItem>);
                                })}
                              </Select>
                              {/*<CustomInput
                                labelText="UF"
                                formControlProps={{fullWidth: true}}
                                inputProps={{
                                  type: "text",
                                  value: UF,
                                  onChange: ({target}) => setUF(target.value)
                                }}
                              />*/}
                            </GridItem>
                            {/* <GridItem lg={3} md={4} sm={6} xs={12}>
                              <CustomInput
                                labelText="Inscrição Estadual"
                                formControlProps={{fullWidth: true}}
                                inputProps={{
                                  type: "text",
                                  value: inscricaoEstadual,
                                  onChange: ({target}) => setInscricaoEstadual(target.value)
                                }}
                              />
                            </GridItem>*/}
                            <GridItem lg={3} md={4} sm={6} xs={12}>
                              <CustomInput
                                labelText="Inscrição Municipal"
                                formControlProps={{fullWidth: true}}
                                inputProps={{
                                  type: "text",
                                  value: inscricaoMunicipal,
                                  onChange: ({target}) => setInscricaoMunicipal(target.value)
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem lg={12} md={12} sm={12} xs={12}>
                          <Button color={"info"} onClick={atualizaEmpresa.bind(this)}>
                            Salvar
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                )
              },
              {
                tabButton: "Certificado",
                tabIcon: Description,
                tabContent: (
                  <Card>
                    <CardBody>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12}>
                          <h4 className={classes.infoText}>
                            Escolha o arquivo do certificado digital
                          </h4>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <PictureUpload
                            label={"Escolher certificado"}
                            accept={".pfx"}
                            onUpload={alterarCertificado.bind(this)}
                          />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                )
              },
              {
                tabButton: "Contatos",
                tabIcon: Person,
                tabContent: (
                  <Card plain>
                    <CardBody>
                      <div className={classes.contentWrapper} style={{position: 'relative'}}>
                        <Grow direction="up" in={!idContatoEditar} unmountOnExit mountOnEnter>
                          <div className={classes.tabContent} style={{position: 'absolute', width: '100%'}}>
                            <UsuarioManager id_empresa={idEmpresa} history={{push: (a, b) => setIdContatoEditar(b)}}/>
                          </div>
                        </Grow>
                        <Grow direction="up" in={!!idContatoEditar} unmountOnExit mountOnEnter>
                          <div className={classes.tabContent} style={{position: 'absolute', width: '100%'}}>
                            <Button color={"info"} onClick={() => setIdContatoEditar(null)} simple>
                              <ArrowBack/> Voltar
                            </Button>
                            <UsuarioSingle
                              computedMatch={{params: {id: idContatoEditar}}}
                              location={{state: {id_empresa: idEmpresa}}}
                            />
                          </div>
                        </Grow>
                      </div>
                    </CardBody>
                  </Card>
                )
              },
              flag_contabilidade ? {
                tabButton: "Empresas", tabIcon: Store, tabContent: (
                  <Card>
                    <CardBody>
                      <ResponsiveTable
                        colunas={["#", "Razão Social", "Nome Fantasia", "CNPJ", "Opções"]}
                        dados={empresasRelacionadas.map(prop => {
                          const {id_empresa, nome_razao_social, nome_fantasia, cnpj} = prop;
                          return [
                            id_empresa,
                            nome_razao_social,
                            nome_fantasia,
                            cnpj,
                            (
                              <>
                                <Button color={"danger"} simple justIcon round style={{margin: 0}}>
                                  <Close/>
                                </Button>
                              </>
                            )
                          ];
                        })}
                      />
                    </CardBody>
                  </Card>
                )
              } : undefined,
              {
                tabButton: "Estatísticas", tabIcon: Timeline, tabContent: (
                  <Card plain>
                    <CardBody>
                      <Dashboard id_empresa={idEmpresa} history={history}/>
                    </CardBody>
                  </Card>
                )
              },
              ls('empresa_uuid') === '1443e267-5b3b-11ea-975c-028f0502a388' ? {
                tabButton: "Módulos", tabIcon: Lock, tabContent: (
                  <Card>
                    <CardBody>
                      <GridContainer>
                        {modulos.map((prop, key) => {
                          const {id_sistema_modulo, modulo_nome, flag_ativo} = prop;
                          return (
                            <GridItem lg={2} md={3} sm={4} xs={6} key={key}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={flag_ativo}
                                    onChange={() => alterarStatusModuloEmpresa(id_sistema_modulo, !flag_ativo, key)}
                                  />
                                }
                                label={modulo_nome}
                              />
                            </GridItem>
                          );
                        })}
                      </GridContainer>
                    </CardBody>
                  </Card>
                )
              } : undefined,
              {
                tabButton: "Inscrição Estadual", tabIcon: AttachFile, tabContent: (
                  <>
                    <Card>
                      <CardBody>
                        <Button
                          color={'info'}
                          onClick={async () => {
                            if (!UF) {
                              return Swal.fire({
                                icon: "warning",
                                title: 'O cadastro de cliente não contém informação da UF.'
                              });
                            }

                            let {value: IE} = await Swal.fire({
                              icon: 'question',
                              title: 'Qual o número da inscrição estadual?',
                              input: "text"
                            });

                            const {value: senha} = await Swal.fire({
                              icon: 'question',
                              title: 'Qual a senha do portal estadual?',
                              text: '(Deixe em branco para nenhuma)',
                              input: 'password'
                            });

                            IE = somenteNumero(IE);
                            if (!ie(IE, UF))
                              return Swal.fire({icon: "warning", title: 'A inscrição estadual digitada não é válida.'});

                            const {ok, id_empresa_inscricao} = await WebService(`/empresa/adicionarIE`, {
                              id_empresa: idEmpresa,
                              IE,
                              senha
                            }, 'POST');
                            if (ok) {
                              setIncricoes(i => {
                                i.push({id_empresa_inscricao, inscricao_estadual: IE, senha_portal_estadual: senha});
                                return [...i];
                              });
                              await Swal.fire({icon: "success", title: 'Inscrição estadual adicionada com sucesso!'});
                            }
                          }}
                        >
                          <Add/> Adicionar Inscrição
                        </Button>
                        <CustomTable
                          tableHead={['#', 'Inscrição', 'Senha', '']}
                          hover
                          tableData={inscricoes.map((prop, key) => ([
                            prop.id_empresa_inscricao,
                            prop.inscricao_estadual,
                            prop.senha_portal_estadual,
                            (
                              <Tooltip title={'Excluir Inscrição'}>
                                <Button color={'danger'} round simple onClick={async () => {
                                  const {value} = await Swal.fire({
                                    icon: "question",
                                    title: 'Deseja realmente excluir esta inscrição?',
                                    showCancelButton: true,
                                    cancelButtonText: 'Não',
                                    confirmButtonText: 'Sim'
                                  });

                                  if (value) {
                                    const {ok} = await WebService(`/empresa/removerIE`, {
                                      id_empresa_inscricao: prop.id_empresa_inscricao,
                                      id_empresa: idEmpresa
                                    });
                                    if (ok) {
                                      setIncricoes(i => {
                                        delete i[key];
                                        i = i.filter(el => el);
                                        return [...i];
                                      })
                                      await Swal.fire({
                                        icon: "success",
                                        title: 'Inscrição Estadual removida com sucesso!'
                                      });
                                    }
                                  }
                                }}>
                                  <Close/>
                                </Button>
                              </Tooltip>
                            )
                          ]))}
                        />
                      </CardBody>
                    </Card>
                  </>
                )
              }
              /*{
                tabButton: "Status serviço", tabIcon: CloudUpload, tabContent: (
                  <>
                    <Card>
                      <CardHeader color="info" text>
                        <CardText color="info">
                          <h4 className={classes.cardTitleWhite}>Entrada</h4>
                          <h4 className={classes.cardCategoryWhite}/>
                        </CardText>
                      </CardHeader>
                      <CardBody>
                        <Table
                          tableHead={["Serviço", "Percentual", "Status", ""]}
                          tableData={status.map(prop => {
                            const {descricao, percentual, status} = prop;
                            return [
                              descricao,
                              <>
                                {percentual}%<br/>
                                <CustomLinearProgress color={"info"} variant="buffer" value={percentual}
                                                      valueBuffer={0}/>
                              </>
                              ,
                              status
                            ];
                          })}
                          hover
                        />
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader color="info" text>
                        <CardText color="info">
                          <h4 className={classes.cardTitleWhite}>Serviços</h4>
                          <h4 className={classes.cardCategoryWhite}/>
                        </CardText>
                      </CardHeader>
                      <CardBody>
                        <Table
                          tableHead={["Serviço", "Percentual", "Status", ""]}
                          tableData={statusServicos.map(prop => {
                            const {descricao, percentual, status} = prop;
                            return [
                              descricao,
                              <>
                                {percentual}%<br/>
                                <CustomLinearProgress color={"info"} variant="buffer" value={percentual}
                                                      valueBuffer={0}/>
                              </>
                              ,
                              status
                            ];
                          })}
                          hover
                        />
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader color="info" text>
                        <CardText color="info">
                          <h4 className={classes.cardTitleWhite}>Saídas</h4>
                          <h4 className={classes.cardCategoryWhite}/>
                        </CardText>
                      </CardHeader>
                      <CardBody>
                        <Table
                          tableHead={["Nome computador", "Percentual", "Status", ""]}
                          tableData={statusSaida.map(prop => {
                            const {nomeComputados, percentual, status} = prop;
                            return [
                              nomeComputados,
                              <>
                                {percentual}%<br/>
                                <CustomLinearProgress color={"info"} variant="buffer" value={percentual}
                                                      valueBuffer={0}/>
                              </>
                              ,
                              status
                            ];
                          })}
                          hover
                        />
                      </CardBody>
                    </Card>
                  </>
                )
              }*/
            ].filter(el => el)}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
