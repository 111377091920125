import React, {useEffect, useState} from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import stylesLogin from "../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import {Pie, defaults} from "react-chartjs-2";
import {baseURL, WebService} from "../../../components/WebService";
import {
  convertDate, estadosUF, filtrarDados,
  formatoDinheiro,
  getDistinctFromArray, imprimirTabela,
  isValidDate,
  nomeProprio
} from "../../../components/Funcoes";
import {makeStyles} from "@material-ui/core/styles";
import * as Swal from "sweetalert2";
import {ArrowBack, Close, FiberManualRecord, FindInPage, Save, Search} from "@material-ui/icons";
import Button from "../../../components/CustomButtons/Button";
import {IconButton, Tooltip} from "@material-ui/core";
import stylesForm from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Warning from "../../../components/Typography/Warning";
import Info from "../../../components/Typography/Info";
import ListAlt from "@material-ui/icons/ListAlt";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import modalStyle from "../../../assets/jss/material-dashboard-pro-react/modalStyle";
import {infoColor} from "../../../assets/jss/material-dashboard-pro-react";
import Check from "@material-ui/icons/Check";
import Print from "@material-ui/icons/Print";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";

const substituicaoCFOP = CFOP => {
  CFOP = CFOP.toString();
  const CFOPSubstituicao = {7: 3, 6: 2, 5: 1, 1: 1, 2: 2, 3: 3};
  CFOP = CFOPSubstituicao[CFOP[0]].toString() + CFOP.slice(1);
  return CFOP;
};

const getCFOPSituacao = (situacao, CFOP) => {
  if (!CFOP) return '';
  CFOP = substituicaoCFOP(CFOP);

  const CFOPCodigos = {};
  CFOPCodigos['Consumo'] = 556;
  CFOPCodigos['Imobilizado'] = 551;
  CFOPCodigos['Revenda Tributada'] = 102;
  CFOPCodigos['Revenda Retida'] = 403;
  CFOPCodigos['Revenda Combustível'] = 652;
  CFOPCodigos['Industrialização'] = 101;
  CFOPCodigos['Frete'] = 353;
  CFOPCodigos['Consumo Combustível'] = 653;
  CFOPCodigos['Bonificação'] = 910;
  CFOPCodigos['Devolução Venda Tributado'] = 202;
  CFOPCodigos['Devolução Venda Retida'] = 411;
  CFOPCodigos['Conserto'] = 915;
  CFOPCodigos['Outras Entradas'] = 949;
  CFOPCodigos['Consumo na prestação de Serviço'] = 156;

  CFOPCodigos['556'] = 'Consumo';
  CFOPCodigos['551'] = 'Imobilizado';
  CFOPCodigos['102'] = 'Revenda Tributada';
  CFOPCodigos['403'] = 'Revenda Retida';
  CFOPCodigos['652'] = 'Revenda Combustível';
  CFOPCodigos['101'] = 'Industrialização';
  CFOPCodigos['353'] = 'Frete';
  CFOPCodigos['653'] = 'Consumo Combustível';
  CFOPCodigos['910'] = 'Bonificação';
  CFOPCodigos['202'] = 'Devolução Venda Tributado';
  CFOPCodigos['156'] = 'Consumo na prestação de Serviço';
  CFOPCodigos['411'] = 'Devolução Venda Retida';
  CFOPCodigos['915'] = 'Conserto';
  CFOPCodigos['949'] = 'Outras Entradas';

  const finalCFOP = (CFOPCodigos[situacao || CFOP.slice(1)] || '');

  return finalCFOP ? (((situacao && CFOP[0]) || '') + (CFOPCodigos[situacao || CFOP.slice(1)] || '')) : CFOP;
};

let timeout;

defaults.global.defaultFontColor = '#333';

const useStyles = makeStyles(stylesLogin);
const useStyles1 = makeStyles(stylesForm);
const useStyles2 = makeStyles(modalStyle);

const filtrarSelects = (dados, filtro) => {
  const {status, situacao, tipo, estado} = filtro;

  if (status) {
    dados = dados.filter(({produtos}) => {
      const situacao = getDistinctFromArray(produtos.map(prop => prop.situacao)).filter(el => el).length >= 1;
      return status === 1 && situacao ? true : (status === 2 && !situacao);
    });
  }

  if (situacao) {
    dados = dados.filter(({flag_cancelada}) => {
      return situacao === 1 && !flag_cancelada ? true : (situacao === 2 && flag_cancelada);
    });
  }

  if (tipo) {
    dados = dados.filter(({uf_emitente, uf_destinatario}) => {
      return tipo === 1 && (uf_emitente === uf_destinatario) ? true : (tipo === 2 && (uf_emitente !== uf_destinatario));
    });
  }

  if (estado)
    dados = dados.filter(({uf_emitente}) => estado && (uf_emitente === estado));

  return dados;
};

export default function EmpresaNotasEntradaCliente({computedMatch, history}) {
  const classes = {...useStyles1(), ...useStyles(), ...useStyles2()};

  const [modalStatusItemNota, setModalStatusItemNota] = useState({open: false, produtos: [], numero: '', key: 0});
  const [uuidEmpresa, setUUIDEmpresa] = useState("");
  const [uuidCompetencia, setUUIDCompetencia] = useState("");
  const [filtro, setFiltro] = useState("");

  const [filtroStatus, setFiltroStatus] = useState({status: 0, situacao: 0, tipo: 0, estado: ''});

  const [notas, setNotas] = useState([]);
  const getNotas = async () => {
    if (uuidEmpresa && uuidCompetencia) {
      const p = {uuidEmpresa, uuidCompetencia, buscarItens: true};
      const {ok, notasEntrada} = await WebService(`/nota/getNotasEntrada`, p, "GET");
      if (ok) setNotas(notasEntrada);
    }
  };

  const alterarSituacaoNota = async (key, valor) => {
    const {id_nota} = notas[key];
    // console.log(id_nota);
    await alterarSituacaoItem(key, valor, id_nota);
  };

  const alterarSituacaoItem = async (key, valor, id_nota = null, loading = true) => {
    const {produtos, key: keyNota} = modalStatusItemNota;
    const {id_nota_item} = key !== null ? (produtos[key] || {}) : {};
    // console.log(id_nota, id_nota_item);
    // return;
    const {ok} = await WebService(`/nota/alterarSituacaoNotaEntradaItemCliente`, {
      id_nota,
      id_nota_item,
      valor,
      uuidEmpresa,
      uuidCompetencia
    }, "POST", loading, loading);

    if (ok) {
      if (modalStatusItemNota.open) {
        setModalStatusItemNota(msin => {
          msin.produtos[key].situacao = valor;
          return {...msin};
        });
      }

      setNotas(n => {
        if (id_nota) {
          n[key].produtos = n[key].produtos.map(prop => {
            prop.situacao = valor;
            return prop;
          });
        } else
          n[keyNota].produtos[key].situacao = valor;
        return [...n];
      });

      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      });
      Toast.fire({icon: 'success', title: 'Salvo com sucesso!'});
    }
  };

  const alterarSituacao = async (key, valor, forcarSalvar = false) => {
    /*if ((['revenda', 'consumo', 'imobilizado'].indexOf(valor) === -1) && !forcarSalvar) {
      const tempNotas = [...notas];
      tempNotas[key].situacao = valor;
      setNotas(tempNotas);
      return;
    }

    const {id_nota, outrosDescricao} = notas[key];
    const {ok} = await WebService(`/nota/alterarSituacaoNotaEntradaCliente`, {
      id_nota,
      valor: valor === "outros" ? outrosDescricao : valor,
      uuidEmpresa,
      uuidCompetencia
    }, "POST");

    if (ok) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      });
      Toast.fire({icon: 'success', title: 'Salvo com sucesso!'});
      const tempNotas = [...notas];
      tempNotas[key].situacao = valor;
      setNotas(tempNotas);

      if (tempNotas.filter(el => el.situacao).length === tempNotas.length) {
        await Swal.fire({
          icon: "success",
          title: "Relatório preenchido com sucesso!",
          text: "Caso haja necessidade de alterar algum valor entre em contato com a contabilidade."
        });
      }
    }*/
  };

  const getParametros = () => {
    if (computedMatch) {
      const {params} = computedMatch;
      if (params) {
        const {uuidEmpresa, uuidCompetencia} = params;
        if (uuidEmpresa && uuidCompetencia) {
          setUUIDEmpresa(uuidEmpresa);
          setUUIDCompetencia(uuidCompetencia);
        } else
          Swal.fire({icon: "info", title: "Dados não conferem."});
      }
    }
  };

  const enviarSituacaoItem = async () => {
    const itens = [...document.getElementsByClassName('situacao_item_linha')];
    const itensStatus = [];
    let allChecked = true;
    for (let i = 0; i < itens.length; i++) {
      const inputs = [...itens[i].getElementsByTagName('input')];
      const [{id}] = inputs;
      const input = inputs.find(el => el.checked);
      itensStatus.push({checked: !!input, status: input && input.value, sequencia: parseInt(id.split('_')[2])});
      if (!itensStatus[i].checked) allChecked = false;
    }

    if (!allChecked) {
      Swal.fire({
        icon: "warning",
        title: 'Itens desmarcados',
        text: 'Para salvar a situação por item, todos precisam estar preenchidos.'
      });
      return false;
    }
  };

  const alterarDataEntradaNota = async (key, data_entrada) => {
    const {id_nota} = notas[key];
    const {ok} = await WebService(`/nota/alterarDataEntradaNota`, {id_nota, uuidCompetencia, data_entrada}, 'POST');
    if (ok) {
      setNotas(n => {
        n[key].data_entrada = data_entrada;
        return [...n];
      });
      await Swal.fire({icon: "success", title: 'Data da entrada da nota alterado com sucesso!'});
    }
  };

  const aplicarSugestaoRelatorio = async () => {
    const {produtos} = modalStatusItemNota;
    // await Swal.fire({loa})
    for (let i = 0; i < produtos.length; i++) {
      const {cfop} = produtos[i];
      const situacao = getCFOPSituacao(null, cfop || '');
      if (situacao)
        await alterarSituacaoItem(i, situacao, null, false);
    }
    await Swal.fire({icon: "success", title: 'Sugestão aplicada com sucesso!'});
  };

  const removerSugestaoRelatorio = async () => {
    const {produtos} = modalStatusItemNota;
    for (let i = 0; i < produtos.length; i++) {
      await alterarSituacaoItem(i, '', null, false);
    }
    await Swal.fire({icon: "success", title: 'Sugestão removida com sucesso!'});
  };

  useEffect(() => {
    getParametros();
  }, []);

  /* useEffect(() => {
       if (!modalStatusItemNota.open) {
           modalStatusItemNota.produtos = [];
           modalStatusItemNota.numero = '';
       }
   }, [modalStatusItemNota]);*/

  useEffect(() => {
    getNotas();
    // eslint-disable-next-line
  }, [uuidEmpresa, uuidCompetencia]);

  return (
    <div className={classes.container}>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal80 + " " + classes.modal
        }}
        onClose={() => setModalStatusItemNota({open: false, produtos: [], numero: ''})}
        open={modalStatusItemNota.open}
        fullWidth
        fullScreen
      >
        <DialogTitle>
          <Button
            justIcon
            round
            className={classes.modalCloseButton}
            aria-label="Close"
            color="transparent"
            onClick={() => setModalStatusItemNota({open: false, produtos: [], numero: ''})}
          >
            <Close className={classes.modalClose}/>
          </Button>
          <h4 className={classes.modalTitle} style={{textAlign: 'center'}}>
            Fornecedor: {modalStatusItemNota.fornecedor} | Nota fiscal: {modalStatusItemNota.numero}
          </h4>
        </DialogTitle>
        <DialogContent>
          <table style={{width: '100%'}}>
            <tr>
              <th>Descrição</th>
              <th>Quantidade</th>
              <th>Valor Un</th>
              <th>Valor</th>
              <th>NCM</th>
              <th>CFOP</th>
              <th>
                Sugestão
                <Tooltip title={"Aplicar sugestões"}>
                  <Button simple round justIcon color={'info'} onClick={aplicarSugestaoRelatorio.bind(this)}>
                    <Check/>
                  </Button>
                </Tooltip>
                <Tooltip title={"Remover sugestões"}>
                  <Button simple round justIcon color={'danger'} onClick={removerSugestaoRelatorio.bind(this)}>
                    <Close/>
                  </Button>
                </Tooltip>
              </th>
              <th>Situação</th>
            </tr>
            {modalStatusItemNota.produtos.map((prop, key) => {
              const {
                ean,
                sequencia,
                descricao,
                quantidade,
                valor_total,
                valor_unitario,
                tipo_unidade,
                ncm,
                cest,
                cfop,
                situacao
              } = prop;

              return (
                <tr align={"center"}>
                  <td align={"left"}>{descricao.toUpperCase()}</td>
                  <td>{quantidade} {tipo_unidade && tipo_unidade.toUpperCase()}</td>
                  <td>{formatoDinheiro(valor_unitario)}</td>
                  <td>{formatoDinheiro(valor_total)}</td>
                  <td>{ncm || ''}</td>
                  <td>{situacao ? getCFOPSituacao(situacao, cfop) : substituicaoCFOP(cfop || '')}</td>
                  <td>{getCFOPSituacao(null, cfop || '')}</td>
                  <td>
                    <select
                      style={{width: '100%', height: 25, borderRadius: 5, border: `1px solid ${infoColor[0]}`}}
                      onChange={({target: {value}}) => alterarSituacaoItem(key, value)}
                    >
                      <option></option>
                      {[
                        'Revenda Tributada', 'Revenda Retida', 'Revenda Combustível', 'Industrialização',
                        'Frete', 'Imobilizado', 'Consumo Combustível', 'Consumo', 'Consumo na prestação de Serviço',
                        'Bonificação', 'Devolução Venda Tributado', 'Devolução Venda Retida', 'Conserto', 'Outras Entradas'
                      ].map(prop => (
                        <option
                          selected={(prop || '').toLowerCase() === (situacao || '').toLowerCase()}
                          name={prop}
                        >
                          {prop}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              );
            })}
          </table>
        </DialogContent>
      </Dialog>
      {/**************************************************************************************************************/}
      <GridContainer>
        {localStorage.getItem("token") ? (
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Button color={"info"} onClick={() => history.goBack()}>
              <ArrowBack/> Voltar
            </Button>
          </GridItem>
        ) : ""}
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardBody>
              <GridContainer justify={"center"}>
                <GridItem lg={4} md={4} sm={6} xs={12}>
                  <Pie
                    data={{
                      labels: ['Valor definido', 'Em aberto', 'Cancelada'],
                      datasets: [{
                        data: [
                          notas.filter(el => !el.flag_cancelada).filter(el => el.situacao).length,
                          notas.filter(el => !el.flag_cancelada).filter(el => !el.situacao).length,
                          notas.filter(el => el.flag_cancelada).length
                        ],
                        borderColor: ['rgba(76, 175, 80, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
                        backgroundColor: ['rgba(76, 175, 80, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
                        hoverBackgroundColor: ['rgba(76, 175, 80, 0.4)', 'rgba(54, 162, 235, 0.4)', 'rgba(255, 99, 132, 0.4)'],
                        hoverBorderColor: ['rgba(76, 175, 80, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)']
                      }]
                    }}
                  />
                </GridItem>
                <GridItem lg={4} md={4} sm={6} xs={12}>
                  <Pie
                    data={{
                      labels: ['Revenda', 'Consumo', 'Imobilizado', 'Outros'],
                      datasets: [{
                        data: [
                          notas.filter(el => !el.flag_cancelada).filter(el => el.situacao === 'revenda').length,
                          notas.filter(el => !el.flag_cancelada).filter(el => el.situacao === 'consumo').length,
                          notas.filter(el => !el.flag_cancelada).filter(el => el.situacao === 'imobilizado').length,
                          notas.filter(el => !el.flag_cancelada).filter(el => el.situacao && ['revenda', 'consumo', 'imobilizado'].indexOf(el.situacao) === -1).length,
                        ],
                        borderColor: [
                          'rgba(168, 69, 254, 1)',
                          'rgba(124, 210, 253, 1)',
                          'rgba(255, 99, 132, 1)',
                          'rgba(76, 175, 80, 1)'
                        ],
                        backgroundColor: [
                          'rgba(168, 69, 254, 0.2)',
                          'rgba(124, 210, 253, 0.2)',
                          'rgba(255, 99, 132, 0.2)',
                          'rgba(76, 175, 80, 0.2)'
                        ],
                        hoverBackgroundColor: [
                          'rgba(168, 69, 254, 0.4)',
                          'rgba(124, 210, 253, 0.4)',
                          'rgba(255, 99, 132, 0.4)',
                          'rgba(76, 175, 80, 0.4)'
                        ],
                        hoverBorderColor: [
                          'rgba(168, 69, 254, 1)',
                          'rgba(124, 210, 253, 1)',
                          'rgba(255, 99, 132, 1)',
                          'rgba(76, 175, 80, 1)'
                        ]
                      }]
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card style={{transition: '.3s'}}>
            <CardBody>
              <Paper component="form" style={{padding: '2px 4px', display: 'flex', alignItems: 'center', width: 400}}>
                <InputBase
                  style={{flex: 1,}}
                  placeholder="Pesquisar"
                  inputProps={{'aria-label': 'Pesquisar'}}
                  onChange={({target: {value}}) => {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => setFiltro(value), 800);
                  }}
                />
                <IconButton aria-label="Pesquisar" style={{padding: 10}}>
                  <Search/>
                </IconButton>
              </Paper>
              {/***************************************/}
              <hr/>
              <GridContainer>
                <GridItem lg={2} md={3} sm={6} xs={12}>
                  Status
                  <select
                    style={{width: '100%', height: 25, borderRadius: 5, border: `1px solid ${infoColor[0]}`}}
                    onChange={({target: {value}}) => setFiltroStatus({...filtroStatus, status: parseFloat(value)})}
                    value={filtroStatus.status.toString()}
                  >
                    <option value={'0'}>Todas</option>
                    <option value={'1'}>Com resposta</option>
                    <option value={'2'}>Sem resposta</option>
                  </select>
                </GridItem>
                <GridItem lg={2} md={3} sm={6} xs={12}>
                  Situação
                  <select
                    style={{width: '100%', height: 25, borderRadius: 5, border: `1px solid ${infoColor[0]}`}}
                    onChange={({target: {value}}) => setFiltroStatus({...filtroStatus, situacao: parseFloat(value)})}
                    value={filtroStatus.situacao.toString()}
                  >
                    <option value={'0'}>Todas</option>
                    <option value={'1'}>Normal</option>
                    <option value={'2'}>Cancelada</option>
                  </select>
                </GridItem>
                <GridItem lg={2} md={3} sm={6} xs={12}>
                  Tipo
                  <select
                    style={{width: '100%', height: 25, borderRadius: 5, border: `1px solid ${infoColor[0]}`}}
                    onChange={({target: {value}}) => setFiltroStatus({...filtroStatus, tipo: parseFloat(value)})}
                    value={filtroStatus.tipo.toString()}
                  >
                    <option value={'0'}>Todas</option>
                    <option value={'1'}>Dentro do estado</option>
                    <option value={'2'}>Fora do estado</option>
                  </select>
                </GridItem>
                <GridItem lg={2} md={3} sm={6} xs={12}>
                  Estado
                  <select
                    style={{width: '100%', height: 25, borderRadius: 5, border: `1px solid ${infoColor[0]}`}}
                    onChange={({target: {value}}) => setFiltroStatus({...filtroStatus, estado: value})}
                    value={filtroStatus.estado.toString()}
                  >
                    <option value={''}>Todos</option>
                    {Object.keys(estadosUF).filter(el => notas.map(prop => prop.uf_emitente).indexOf(el) > -1).map(prop => (<option value={prop} key={prop}>{prop}</option>))}
                  </select>
                </GridItem>
                <GridItem lg={2} md={3} sm={6} xs={12}>
                  <Button color={'info'} fullWidth onClick={() => setFiltroStatus({situacao: 0, status: 0, estado: '', tipo: 0})}>
                    Limpar filtros
                  </Button>
                </GridItem>
              </GridContainer>
              <hr/>
              {/***************************************/}
              <Button
                color={"info"}
                style={{float: 'right', marginTop: -10}}
                simple
                onClick={() => imprimirTabela({
                  titlePrint: 'Relatório notas fiscais de entrada',
                  colunas: ["Nº Nota", "Valor NF", "Data Emissão", "Data Entrada", "Razão Social Emitente", "Situação"],
                  dados: notas.map((prop, key) => {
                      let {
                        id_nota,
                        numero,
                        valor_nota,
                        data_emissao,
                        situacao,
                        nome_razao_social,
                        flag_cancelada,
                        outrosDescricao,
                        produtos,
                        data_entrada
                      } = prop;
                     return [
                       numero,
                       formatoDinheiro(valor_nota),
                       convertDate(data_emissao),
                       convertDate(data_entrada || data_emissao),
                       nome_razao_social,
                       flag_cancelada ? "Nota cancelada" : (
                         getDistinctFromArray(produtos.map(prop => prop.situacao)).join(", ")
                       )
                     ];
                    }),
                    // printOrder:
                })}
              >
                <Print/> Imprimir
              </Button>
              <table style={{width: '100%'}}>
                <tr>
                  {["Nº Nota", "Valor NF", "Data Emissão", "Data Entrada", "Razão Social Emitente","UF", "Situação", "Opções"].map(prop => (
                    <th>{prop}</th>))}
                </tr>
                {filtrarDados(filtrarSelects(notas, filtroStatus), filtro).map((prop, key) => {
                  let {
                    id_nota,
                    numero,
                    valor_nota,
                    data_emissao,
                    uf_emitente,
                    uf_destinatario,
                    situacao,
                    nome_razao_social,
                    flag_cancelada,
                    outrosDescricao,
                    produtos,
                    data_entrada
                  } = prop;

                  return (
                    <tr align={"center"}>
                      <td>{numero}</td>
                      <td>{formatoDinheiro(valor_nota)}</td>
                      <td>{convertDate(data_emissao)}</td>
                      <td>
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={async e => {
                              const confirmarAlteracao = async () => {
                                const {value} = await Swal.fire({
                                  icon: "question",
                                  title: 'Deseja alterar a data de entrada da nota?',
                                  showCancelButton: true,
                                  confirmButtonText: 'Sim',
                                  cancelButtonText: 'Não'
                                });
                                return !!value;
                              }
                              if (typeof e === 'object') {
                                if (await confirmarAlteracao())
                                  await alterarDataEntradaNota(key, e.toDate());
                              } else {
                                if (e.length === 10 && isValidDate(new Date(e.split("/").reverse().join("-")))) {
                                  if (await confirmarAlteracao())
                                    await alterarDataEntradaNota(key, new Date(e.split("/").reverse().join("-")));
                                }
                              }
                              // ;
                            }}
                            // onChange={() => ""}
                            value={new Date(data_entrada || data_emissao)}
                            inputProps={{placeholder: "Data Entrada", id: 'data-entrada', style: {textAlign: 'center'}}}
                          />
                        </FormControl>
                      </td>
                      <td>{nome_razao_social}</td>
                      <td>{uf_emitente} - {uf_destinatario === uf_emitente ? 'Dentro' : 'Fora'} do estado</td>
                      <td>
                        {
                          flag_cancelada ?
                            (<Warning>Nota cancelada</Warning>) :
                            (
                              getDistinctFromArray(produtos.map(prop => prop.situacao)).length > 1 ? getDistinctFromArray(produtos.map(prop => prop.situacao)).join(", ") : (
                                <select
                                  style={{
                                    width: '100%',
                                    height: 25,
                                    borderRadius: 5,
                                    border: `1px solid ${infoColor[0]}`
                                  }}
                                  onChange={({target: {value}}) => alterarSituacaoNota(key, value)}
                                >
                                  <option></option>
                                  {[
                                    'Revenda Tributada', 'Revenda Retida', 'Revenda Combustível', 'Industrialização',
                                    'Frete', 'Imobilizado', 'Consumo Combustível', 'Consumo', 'Consumo na prestação de Serviço',
                                    'Bonificação', 'Devolução Venda Tributado', 'Devolução Venda Retida', 'Conserto', 'Outras Entradas'
                                  ].map(prop => (
                                    <option
                                      selected={getDistinctFromArray(produtos.map(prop => (prop.situacao || '').toLowerCase()))[0] === (prop || '').toLowerCase()}
                                      name={prop}
                                    >
                                      {prop}
                                    </option>
                                  ))}
                                </select>
                              )
                            )
                        }
                      </td>
                      <td>
                        <Tooltip title={"Visualizar DANFE"}>
                          <Button
                            simple
                            justIcon
                            round
                            color={"info"}
                            onClick={() => window.open(`${baseURL}/nota/DAFENotaID?id_nota=${id_nota}&uuid=${uuidEmpresa}`)}
                          >
                            <FindInPage/>
                          </Button>
                        </Tooltip>
                        <Tooltip title={"Visualizar itens da nota"}>
                          <Button
                            simple
                            justIcon
                            und
                            color={"info"}
                            onClick={() => {
                              setModalStatusItemNota({
                                open: true,
                                numero,
                                id_nota,
                                produtos,
                                fornecedor: nome_razao_social,
                                key
                              });
                            }}>
                            <ListAlt/>
                          </Button>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
