import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardFooter from "../../components/Card/CardFooter.js";
import stylesLogin from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import stylesLock from "../../assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.js";
import FacebookProvider, {Login} from 'react-facebook-sdk';
import * as Swal from "sweetalert2";
import {baseURL, WebService} from "../../components/WebService";
import {alterarSenhaUsuario, convertBlobToBase64, fetchAsBlob, ls} from "../../components/Funcoes";
import CardAvatar from "../../components/Card/CardAvatar";
import defaultAvatar from "../../assets/img/faces/avatar.jpg"

const useStylesLogin = makeStyles(stylesLogin);
const useStylesLock = makeStyles(stylesLock);

export default function LoginPage({setToken}) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [lock, setLock] = useState(false);
  const [visualizarSenha, setVisualizarSenha] = useState(false);
  const [recuperarSenha, setRecuperarSenha] = useState(false);

  const classes = (lock ? useStylesLock : useStylesLogin)();

  useEffect(() => {
    setCardAnimation("cardHidden");
    setTimeout(() => setCardAnimation(""), 400);
  }, [lock]);

  useEffect(() => {
    setLock(!!ls('usuario_email'));
  }, []);

  const realizaLoginFacebook = async ({profile, tokenDetail}) => {
    const {name, id, email} = profile;

    const {accessToken} = tokenDetail;
    localStorage.setItem('usuario_nome', name);
    localStorage.setItem('usuario_email', email);
    await realizaLogin(accessToken, id);
  };

  const realizaLogin = async (tokenFacebook = null, idFacebook = null) => {
    tokenFacebook = typeof tokenFacebook !== "string" ? undefined : tokenFacebook;
    const dados = {email, senha, tokenFacebook};
    if (lock)
      dados.email = ls('usuario_email');
    let {ok, token, nome, foto_perfil, email: emailUsuario, flag_redefinir_senha, id_usuario, modulosAtivos, uuid} = await WebService(`/login/login`, dados, "POST");
    if (ok) {
      localStorage.setItem('usuario_nome', nome);
      localStorage.setItem('empresa_uuid', uuid);
      localStorage.setItem('usuario_id', id_usuario);
      localStorage.setItem('usuario_email', emailUsuario);
      localStorage.setItem('modulos_ativo_sistema', JSON.stringify(modulosAtivos));

      if (!foto_perfil && tokenFacebook) {
        const base64Perfil = await fetchAsBlob(`https://graph.facebook.com/v5.0/${idFacebook}/picture?width=640`).then(convertBlobToBase64);
        const dadosFoto = {imagem: base64Perfil, token};
        const {ok, nomeArquivo} = await WebService(`/usuario/alterarFotoPerfil`, dadosFoto, "POST", true, false);
        if (ok)
          foto_perfil = nomeArquivo;
      }
      localStorage.setItem('usuario_foto', foto_perfil ? `${baseURL}/usuario/getFotoPerfil?foto_perfil=${foto_perfil}` : "");
      if (flag_redefinir_senha) await alterarSenhaUsuario({token}, false);

      localStorage.setItem('token', token);
      window.location.reload();
      // setToken(token);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    await realizaLogin();
  };

  const recuperarAcesso = async () => {
    const {ok, mensagem_usuario} = await WebService(`/login/recuperarSenha`, {email}, "POST");
    if (ok) {
      await Swal.fire({icon: "success", title: "Recuperação realizada com sucesso!", text: mensagem_usuario});
      setRecuperarSenha(false);
    }
  };

  if (lock) {
    return (
      <div className={classes.container}>
        <form onSubmit={onSubmit}>
          <Card profile className={classes.customCardClass + " " + classes[cardAnimaton]}>
            <CardAvatar profile className={classes.cardAvatar}>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={ls('usuario_foto') || defaultAvatar} alt="..."/>
              </a>
            </CardAvatar>
            <CardBody profile>
              <h4 className={classes.cardTitle}>{ls('usuario_nome')}</h4>
              <CustomInput
                labelText="Senha"
                formControlProps={{fullWidth: true}}
                inputProps={{
                  type: visualizarSenha ? 'text' : 'password',
                  autoComplete: "off",
                  onChange: ({target}) => setSenha(target.value),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon
                        className={classes.inputAdornmentIcon}
                        onClick={() => setVisualizarSenha(!visualizarSenha)}
                        style={{cursor: 'pointer'}}
                      >
                        {visualizarSenha ? 'remove_red_eye' : 'lock_outline'}
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer justify={"center"}>
                <GridItem lg={12} md={12} sm={12} xs={12}>
                  <Button color="info" round onClick={realizaLogin.bind(this)}>
                    Acessar
                  </Button>
                  <Button
                    color="info"
                    round
                    simple
                    fullWidth
                    onClick={() => {
                      localStorage.setItem('usuario_email', "");
                      setLock(false)
                    }}
                  >
                    Outro usuário
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </form>
      </div>
    );
  }

  if (recuperarSenha) {
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={recuperarAcesso}>
              <Card login className={classes[cardAnimaton]}>
                <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="info">
                  <h4 className={classes.cardTitle}>Recuperar Senha</h4>
                  <p>&nbsp;</p>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="Usuário ou email"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      onChange: ({target}) => setEmail(target.value),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon}/>
                        </InputAdornment>
                      )
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <GridContainer>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <Button color="info" round fullWidth block onClick={recuperarAcesso.bind(this)}>
                        Recuperar
                      </Button>
                      <Button color="info" simple fullWidth block onClick={() => setRecuperarSenha(false)}>
                        Voltar para login
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={onSubmit}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
                <h4 className={classes.cardTitle}>Acessar o sistema</h4>
                <div className={classes.socialLine}>
                  <FacebookProvider appId="211779299985941">
                    <Login
                      scope={"email"}
                      onResponse={realizaLoginFacebook}
                      onError={() => Swal.fire({icon: "warning", title: "Ocorreu um erro ao entrar com o Facebook"})}
                    >
                      <Button color="transparent" justIcon className={classes.customButtonClass}>
                        <i className={`fab fa-facebook-square`}/>
                      </Button>
                    </Login>
                  </FacebookProvider>
                </div>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Usuário ou email"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    onChange: ({target}) => setEmail(target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon}/>
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Senha"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    onChange: ({target}) => setSenha(target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon
                          className={classes.inputAdornmentIcon}
                          onClick={() => setVisualizarSenha(!visualizarSenha)}
                          style={{cursor: 'pointer'}}
                        >
                          {visualizarSenha ? 'remove_red_eye' : 'lock_outline'}
                        </Icon>
                      </InputAdornment>
                    ),
                    type: visualizarSenha ? 'text' : 'password',
                    autoComplete: "off"
                  }}
                />
                <input type="submit" style={{visibility: 'hidden'}}/>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <GridContainer>
                  <GridItem lg={12} md={12} sm={12} xs={12}>
                    <Button color="info" round fullWidth block onClick={realizaLogin.bind(this)}>
                      Acessar
                    </Button>
                    <Button color="info" round simple fullWidth onClick={() => setRecuperarSenha(true)}>
                      Esqueci minha senha
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
