import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import NavPills from "../../components/NavPills/NavPills.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import {cardTitle} from "../../assets/jss/material-dashboard-pro-react.js";
import ListAlt from "@material-ui/icons/ListAlt";
import CardIcon from "../../components/Card/CardIcon";
import Icon from "@material-ui/core/Icon";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import LiveSearch from "../Components/LiveSearch";
import Datetime from "react-datetime";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import {moduloAtivo, traducaoReactTable} from "../../components/DataModule";
import ReactTable from "react-table";
import TagsInput from "react-tagsinput";
import {baseURL, WebService} from "../../components/WebService";
import {
  dataHumano,
  downloadFileAsBlob,
  Filter, FilterSelect,
  formatoDinheiro,
  isValidDate,
  ls,
  nomeProprio, tiposNotas
} from "../../components/Funcoes";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Visibility from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import * as Swal from "sweetalert2";
import matchSorter from "match-sorter";
import Dialog from "@material-ui/core/Dialog";
import Checkbox from "../../components/CustomInput/CustomCheckbox";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import {DialogContent} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Zoom from "@material-ui/core/Zoom";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  cardTitle,
  pageSubcategoriesTitle: {color: "#3C4858", textDecoration: "none", textAlign: "center"},
  cardCategory: {margin: "0", color: "#999999"},
  ...extendedFormsStyle,
};

const useStyles = makeStyles(styles);

export default function ManagerNotas({history}) {
  const classes = useStyles();

  const [active, setActive] = useState(0);
  const [notasPesquisa, setNotasPesquisa] = useState(['entrada', "saida"]);
  const [empresas, setEmpresas] = useState([]);
  const [tipoNotasDownload, setTipoNotasDownload] = useState([55, 57, 65, 99]);
  const [competencia, setCompetencia] = useState("");
  const [openModalTipoNotaDownload, setOpenModalTipoNotaDownload] = useState(false);
  const [periodoInicial, setPeriodoInicial] = useState(new Date(new Date().setDate(1)));
  const [periodoFinal, setPeriodoFinal] = useState(new Date());

  const [notas, setNotas] = useState([]);

  useEffect(() => {
    buscarNotas();
    // eslint-disable-next-line
  }, [active, empresas, notasPesquisa]);

  const adicionarEmpresa = async (id, nome) => {
    if (id && (empresas.map(prop => prop.id).indexOf(id) === -1))
      setEmpresas([...empresas, {id, nome}]);
  };

  const removerEmpresa = async (a, b, c) => {
    const [key] = c;
    const tempEmpresas = [...empresas];
    delete tempEmpresas[key];
    setEmpresas(tempEmpresas.filter(el => el))
  };

  const getFilto = (download = false) => {
    const tipo = {0: 'nfe', 1: 'nfce', 2: 'cte', 3: 'nfse'};
    const f = {
      tipo: tipo[active],
      empresas: empresas.map(prop => prop.id),
      notasPesquisa,
      periodoInicial,
      periodoFinal,
      competencia
    };
    if (download)
      f.tipoNotasDownload = tipoNotasDownload.filter(el => el);
    return f;
  };

  const buscarNotas = async () => {
    if (competencia && competencia.length < 7) {
      Swal.fire({
        icon: "warning",
        title: 'A competencia informada não é válida.',
        html: `O formato deve ser <strong>mm/aaaa</strong>`
      });
      return false;
    }

    setNotas([]);
    const filtro = getFilto();
    const {ok, notas} = await WebService(`/nota/getNotas`, filtro, "POST");
    if (ok)
      setNotas(notas);
  };

  const baixarNotasFiltro = async () => {
    setOpenModalTipoNotaDownload(false);
    if (competencia && competencia.length < 7) {
      Swal.fire({
        icon: "warning",
        title: 'A competencia informada não é válida.',
        html: `O formato deve ser <strong>mm/aaaa</strong>`
      });
      return false;
    }

    const filtro = getFilto(true);
    if (!filtro.tipoNotasDownload.length) {
      Swal.fire({
        icon: "warning",
        title: 'Deve selecionar pelo menos um tipo de documento para download.',
        html: `O formato deve ser <strong>mm/aaaa</strong>`
      });
      return false;
    }

    const zipBlob = await WebService(`/nota/downloadNotas`, filtro, "POST", true, true, true);
    if (zipBlob instanceof Blob)
      await downloadFileAsBlob(zipBlob, `${new Date().toISOString().split("T")[0]}.zip`);
  };

  const baixarNotaID = async (id_nota, chave) => {
    const xmlBlob = await WebService(`/nota/downloadNotaID`, {id_nota}, "GET", true, true, true);
    await downloadFileAsBlob(xmlBlob, `${chave}.xml`);
  };

  useEffect(() => {
    window.jQuery('#periodo-final').mask('00/00/0000');
    window.jQuery('#periodo-inicial').mask('00/00/0000');
    window.jQuery('#competencia').mask('00/0000');
  }, [periodoFinal, periodoInicial, competencia]);

  const baixarDANFEID = async id_nota => window.open(`${baseURL}/nota/DAFENotaID?id_nota=${id_nota}&token=${ls('token')}`);

  const alteraTipoNotasDownload = tipoNota => {
    const tempTipoNotasDownload = [...tipoNotasDownload];
    const posicao = tempTipoNotasDownload.indexOf(tipoNota);
    if (posicao > -1)
      delete tempTipoNotasDownload[posicao];
    else
      tempTipoNotasDownload.push(tipoNota);
    setTipoNotasDownload(tempTipoNotasDownload);
  };

  return (
    <div>
      <Dialog open={openModalTipoNotaDownload} fullWidth onClose={() => setOpenModalTipoNotaDownload(false)}>
        <DialogTitle>
          <h4 className={classes.modalTitle} style={{textAlign: 'center'}}>
            Selecione os tipo de notas que gostaria de realizar o download.
          </h4>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            {[55, 57, 65, 99].map((prop, key) => (
              <GridItem lg={3} md={3} sm={3} xs={12} key={key}>
                <div className={classes.choiche}>
                  <Checkbox
                    checked={tipoNotasDownload.indexOf(prop) > -1}
                    tabIndex={-1}
                    onClick={() => alteraTipoNotasDownload(prop)}
                    checkedIcon={<i className={"fas fa-file " + classes.iconCheckboxIcon}/>}
                    icon={<i className={"fas fa-file " + classes.iconCheckboxIcon}/>}
                    classes={{checked: classes.iconCheckboxChecked, root: classes.iconCheckbox}}
                  />
                  <h6>{tiposNotas[prop]}</h6>
                </div>
              </GridItem>
            ))}
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button color={"info"} fullWidth onClick={baixarNotasFiltro.bind(this)}>
            Baixar
          </Button>
        </DialogActions>
      </Dialog>
      <Button color={"info"} onClick={() => history.push(`/notas/download`)}>
        Downloads
      </Button>
      <Card>
        <CardHeader color="info" stats icon>
          <CardIcon color="info">
            <Icon>search</Icon>
          </CardIcon>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem lg={12} md={12} sm={12} xs={12}>
              <TagsInput
                value={empresas.map(prop => prop.nome)}
                onChange={removerEmpresa}
                tagProps={{className: "react-tagsinput-tag info"}}
                inputProps={{placeholder: ''}}
              />
            </GridItem>
            <GridItem lg={4} md={4} sm={6} xs={12}>
              <LiveSearch label={"Empresa"} url={"/filtro/empresa"} onChange={adicionarEmpresa} clearValueOnChange/>
            </GridItem>
            <GridItem lg={2} md={4} sm={6} xs={12}>
              <CustomInput
                labelText="Competência"
                formControlProps={{fullWidth: true}}
                inputProps={{
                  type: "text",
                  value: competencia,
                  onChange: ({target}) => setCompetencia(target.value),
                  id: 'competencia'
                }}
              />
            </GridItem>
            <GridItem lg={6} md={8} sm={12} xs={12}>
              <Zoom in={competencia} mountOnEnter unmountOnExit>
                <div>
                  Pesquisa sendo realizada por competência, para habilidar o período favor limpar o campo "Competência".
                </div>
              </Zoom>
              <Zoom in={!competencia} mountOnEnter unmountOnExit>
                <GridContainer>
                  <GridItem lg={3} md={4} sm={6} xs={12}>
                    <InputLabel className={classes.label} style={{marginBottom: -10}}>
                      Período Inicial
                    </InputLabel>
                    <br/>
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        onChange={e => typeof e === 'object' ? setPeriodoInicial(e.toDate()) : (e.length === 10 ? (isValidDate(new Date(e.split("/").reverse().join("-"))) ? setPeriodoInicial(new Date(e.split("/").reverse().join("-"))) : "") : "")}
                        value={periodoInicial}
                        inputProps={{placeholder: "Período Inicial", id: 'periodo-inicial'}}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem lg={3} md={4} sm={6} xs={12}>
                    <InputLabel className={classes.label} style={{marginBottom: -10}}>
                      Período Final
                    </InputLabel>
                    <br/>
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        value={periodoFinal}
                        onChange={e => typeof e === 'object' ? setPeriodoFinal(e.toDate()) : (e.length === 10 ? (isValidDate(new Date(e.split("/").reverse().join("-"))) ? setPeriodoFinal(new Date(e.split("/").reverse().join("-"))) : "") : "")}
                        inputProps={{placeholder: "Período Final", id: 'periodo-final'}}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </Zoom>
            </GridItem>
            <GridItem lg={12} md={12} sm={12} xs={12}/>
            <GridItem lg={4} md={4} sm={6} xs={12}>
              <InputLabel className={classes.label} style={{marginBottom: -10}}>
                Tipo
              </InputLabel>
              <br/>
              <Select
                multiple
                MenuProps={{className: classes.selectMenu}}
                classes={{select: classes.select}}
                inputProps={{name: "multipleSelect", id: "multiple-select"}}
                value={notasPesquisa}
                style={{width: '100%'}}
                onChange={async ({target}) => {
                  if (target.value.length === 0) {
                    Swal.fire({icon: "info", title: "É preciso selecionar pelo menos um tipo de nota."});
                    return;
                  }
                  setNotasPesquisa(target.value)
                }}
              >
                {(moduloAtivo('5d6950f6-ab5c-11ea-a580-7a791949cbcc') || moduloAtivo('5e773062-ab5c-11ea-a580-7a791949cbcc')) && (
                  <MenuItem
                    classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}}
                    value="entrada"
                  >
                    Entrada
                  </MenuItem>
                )}
                {(moduloAtivo('5df1ff10-ab5c-11ea-a580-7a791949cbcc') || moduloAtivo('5e773062-ab5c-11ea-a580-7a791949cbcc')) && (
                  <MenuItem
                    classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}}
                    value="saida"
                  >
                    Saída
                  </MenuItem>
                )}
              </Select>
            </GridItem>
            {/*<GridItem lg={3} md={4} sm={6} xs={12}>
              <CustomInput
                labelText="Intervalo numeração de início"
                formControlProps={{fullWidth: true}}
                inputProps={{type: "number"}}
              />
            </GridItem>
            <GridItem lg={3} md={4} sm={6} xs={12}>
              <CustomInput
                labelText="Intervalo numeração final"
                formControlProps={{fullWidth: true}}
                inputProps={{type: "number"}}
              />
            </GridItem>*/}
            <GridItem lg={12} md={12} sm={12} xs={12}>
              <GridContainer justify={"center"}>
                <GridItem lg={3} md={4} sm={6} xs={12}>
                  <Button fullWidth color={"info"} onClick={buscarNotas}>
                    Buscar
                  </Button>
                </GridItem>
                <GridItem lg={3} md={4} sm={6} xs={12}>
                  <Button fullWidth color={"info"} onClick={() => setOpenModalTipoNotaDownload(true)}>
                    Baixar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <NavPills
        color="info"
        alignCenter
        onChange={setActive}
        tabs={[
          {tabButton: "NF-e", tabIcon: ListAlt, hide: !moduloAtivo('7c901861-abed-11ea-a580-7a791949cbcc')},
          {tabButton: "NFC-e", tabIcon: ListAlt, hide: !moduloAtivo('8015ae8c-abed-11ea-a580-7a791949cbcc')},
          {tabButton: "CT-e", tabIcon: ListAlt, hide: !moduloAtivo('83a61237-abed-11ea-a580-7a791949cbcc')},
          {tabButton: "NFS-e", tabIcon: ListAlt, hide: !moduloAtivo('86740869-abed-11ea-a580-7a791949cbcc')}
        ]}
      />
      <Card>
        <CardBody>
          <ReactTable
            {...traducaoReactTable}
            data={notas.map(prop => {
              const p = {...prop};
              p.data_emissao = dataHumano(p.data_emissao, false, true);
              p.data_competencia = p.data_competencia ? p.data_competencia.split("-").reverse().slice(1, 3).join("/") : "";
              p.tipo = p.tipo.toUpperCase();
              if (parseInt(p.tipo_nota) !== 65) {
                p.actions = (
                  <div className="actions-right">
                    <Tooltip title="Baixar XML">
                      <Button justIcon round simple onClick={() => baixarNotaID(p.id_nota, p.chave)} color="info">
                        <ArrowDownward/>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Visualizar nota">
                      <Button justIcon round simple onClick={() => baixarDANFEID(p.id_nota, p.chave)} color="info">
                        <Visibility/>
                      </Button>
                    </Tooltip>
                  </div>
                );
              }
              return p;
            })}
            filterable
            getTrProps={(state, rowInfo) => (rowInfo && rowInfo.original.flag_cancelada) ? {style: {background: 'rgba(255,0,0,0.1)'}} : {}}
            columns={[
              {Header: "Empresa", accessor: "nome_razao_social", Filter},
              {Header: "Num. da Nota", accessor: "numero", Filter},
              {Header: "Serie", accessor: "serie", Filter},
              {Header: "Data Emissão", accessor: "data_emissao", Filter},
              {Header: "Competência", accessor: "data_competencia", Filter},
              {Header: "Tipo", accessor: "tipo", Filter},
              {Header: "Valor", accessor: "valor_total", Cell: ({value}) => formatoDinheiro(value), Filter},
              {
                Header: "Situação",
                accessor: "situacao",
                Cell: ({value}) => nomeProprio(value),
                Filter: a => FilterSelect(a, a => a, Array.from(new Set(notas.map(prop => prop.situacao))))
              },
              {Header: "Chave", accessor: "chave", Filter},
              {Header: "Origem Serviço", accessor: "nota_servico_origem", Filter},
              {Header: "Opções", accessor: "actions", sortable: false, filterable: false}
            ].filter(el => {
              if (active !== 3 && el.accessor === 'nota_servico_origem') return false;
              if (active === 3)
                return (['serie'].indexOf(el.accessor) === -1);
              else
                return ([active === 0 ? undefined : 'situacao', 'data_competencia'].filter(el => el).indexOf(el.accessor) === -1)
              // return active === 3 ? (['serie'].indexOf(el.accessor) === -1) : /
            })}
            defaultPageSize={20}
            defaultFilterMethod={(filter, row) => matchSorter([row[filter.id]], filter.value, {threshold: matchSorter.rankings.WORD_STARTS_WITH}).length !== 0}
            showPaginationBottom={true}
            className="-striped -highlight"
          />
        </CardBody>
      </Card>
    </div>
  );
};
