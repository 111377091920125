import React, {useEffect, useState} from "react";
import {VectorMap} from "react-jvectormap";
import {makeStyles} from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Store from "@material-ui/icons/Store";
import WarningIcon from "@material-ui/icons/Warning";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Language from "@material-ui/icons/Language";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Table from "../../components/Table/Table.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import {mesesAno, moduloAtivo} from "../../components/DataModule";
import styles from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import {WebService} from "../../components/WebService";
import {pontuacaoNumero} from "../../components/Funcoes";
import CloudDone from "@material-ui/icons/CloudDone";
import Business from "@material-ui/icons/Business";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import CompareArrows from "@material-ui/icons/CompareArrows";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import {infoColor, whiteColor} from "../../assets/jss/material-dashboard-pro-react";
import Slide from "@material-ui/core/Slide";
import Warning from "../../components/Typography/Warning";
import withStyles from "@material-ui/core/styles/withStyles";
import CheckboxAPI from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NavPills from "../../components/NavPills/NavPills";
import Zoom from "@material-ui/core/Zoom";
import {Description} from "@material-ui/icons";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {Bar, defaults} from "react-chartjs-2";
import Button from "../../components/CustomButtons/Button";

defaults.global.defaultFontColor = "white";

const chartOptions = {
  scales: {
    yAxes: [
      {
        gridLines: {color: "rgba(255 ,255, 255, 0.3)", drawBorder: true},
        ticks: {color: "#FFF", fontColor: "#FFF"}
      }
    ],
    xAxes: [{gridLines: {display: false}, ticks: {fontColor: "#FFF"}}]
  },
};

const anoZerado = new Array(12).fill(0);
const useStyles = makeStyles({...styles, ...extendedFormsStyle, selectWhite: {color: whiteColor}});

const Checkbox = withStyles({root: {color: infoColor[0], '&$checked': {color: infoColor[0]}}})(props => (
  <CheckboxAPI color="default" {...props} />
));

let iniciado = false;
let ultimaMensagem = new Date();

export default function Dashboard({webSocket, id_empresa, history}) {
  const classes = useStyles();

  const [filtroAtivo, setFiltroAtivo] = useState(0);
  const [estadosEstatistica, setEstadosEstatisticas] = useState({});
  const [anosDisponiveis, setAnosDisponiveis] = useState([]);
  const [anosSelecionados, setAnosSelecionados] = useState([]);

  const [totalNotas, setTotalNotas] = useState(0);
  const [mesSelecionadoFiltro, setMesSelecionadoFiltro] = useState("0");
  const [graficoNotasMesTipo, setGraficoNotasMesTipo] = useState({entrada: anoZerado, saida: anoZerado});
  const [graficoNotasMesTipoNota, setGraficoNotasMesTipoNota] = useState({55: anoZerado, 65: anoZerado, 57: anoZerado});
  const [totalEmpresas, setTotalEmpresas] = useState(0);
  const [totalNotasPendentes, setTotalNotasPendentes] = useState(0);

  const getDashboardDados = async () => {
    const {ok, total_empresa, estadosEstatistica, total_pendentes, total_salvo, graficoNotasMesTipo, anos, graficoNotasMesTipoNota} = await WebService(`/empresa/getDashboard`, {
      filtroAtivo,
      anosSelecionados,
      id_empresa,
      mesSelecionadoFiltro
    }, "POST");
    if (ok) {
      setAnosDisponiveis(anos);
      setGraficoNotasMesTipoNota(graficoNotasMesTipoNota);
      setTotalEmpresas(total_empresa);
      setTotalNotasPendentes(total_pendentes);
      setTotalNotas(total_salvo);
      setEstadosEstatisticas(estadosEstatistica);
      setGraficoNotasMesTipo(graficoNotasMesTipo);
    }
  };

  const atualizaInformacoesTempoReal = (props) => {
    let {data} = props;
    if ((new Date() - ultimaMensagem) > 500) {
      ultimaMensagem = new Date();
      data = JSON.parse(data);
      const {total_empresa, estadosEstatistica, total_pendentes, total_salvo, graficoNotasMesTipo, anos, graficoNotasMesTipoNota} = data;
      setAnosDisponiveis(anos);
      setGraficoNotasMesTipoNota(graficoNotasMesTipoNota);
      setTotalEmpresas(total_empresa);
      setTotalNotasPendentes(total_pendentes);
      setTotalNotas(total_salvo);
      setMesSelecionadoFiltro("0");
      setEstadosEstatisticas(estadosEstatistica);
      setGraficoNotasMesTipo(graficoNotasMesTipo);
    }
  };

  useEffect(() => {
    if (webSocket && (webSocket.readyState === webSocket.OPEN)) {
      webSocket.send(JSON.stringify({metodo: 'adicionarEvento', evento: 'monitorarDashboard'}));
      webSocket.addEventListener("message", atualizaInformacoesTempoReal);
    }
    return () => {
      if (webSocket && (webSocket.readyState === webSocket.OPEN)) {
        webSocket.send(JSON.stringify({metodo: 'removerEvento', evento: 'monitorarDashboard'}));
        webSocket.removeEventListener("message", atualizaInformacoesTempoReal);
      }
    };
  }, [webSocket]);

  useEffect(() => {
    setGraficoNotasMesTipoNota({55: anoZerado, 65: anoZerado, 57: anoZerado});
    setGraficoNotasMesTipo({entrada: anoZerado, saida: anoZerado});
    // setTimeout(() => getDashboardDados(), iniciado ? 0 : 300);
    getDashboardDados();
    if (!iniciado)
      iniciado = true;
    // eslint-disable-next-line
  }, [filtroAtivo, anosSelecionados, mesSelecionadoFiltro]);

  return (
    <div>
      {/*<Joyride steps={steps} run={true} continuous={true}/>*/}
      <GridContainer>
        <GridItem xs={12} sm={4} md={3} lg={3}>
          <Button color={"info"} onClick={() => history.push(`/painelSincronizador`)}>
            Sincronizador Saídas
            <Icon>wifi_protected_setup</Icon>
          </Button>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}/>
        <GridItem xs={12} sm={12} md={12} lg={12} className={`my-first-step`}>
          <div className={'wizard-step-1'}>
            <NavPills
              color="info"
              alignCenter
              onChange={setFiltroAtivo}
              tabs={[
                {tabButton: "Todos", tabIcon: CompareArrows},
                {
                  tabButton: "Entrada",
                  tabIcon: ArrowDownward,
                  hide: !moduloAtivo('5d6950f6-ab5c-11ea-a580-7a791949cbcc')
                },
                {tabButton: "Saída", tabIcon: ArrowUpward, hide: !moduloAtivo('5df1ff10-ab5c-11ea-a580-7a791949cbcc')},
                {tabButton: "Serviço", tabIcon: Description, hide: !moduloAtivo('5e773062-ab5c-11ea-a580-7a791949cbcc')}
              ]}
            />
          </div>
          <Zoom in={!!anosDisponiveis.length}>
            <Card className={'wizard-step-2'}>
              <CardBody>
                <GridContainer>
                  <GridItem lg={12} md={12} sm={12} xs={12}>
                    {anosDisponiveis.map((prop, key) => (
                      <FormControlLabel
                        key={key}
                        control={<Checkbox
                          checked={anosSelecionados.indexOf(prop) > -1}
                          onChange={() => {
                            const tempAnosSelecionados = [...anosSelecionados];
                            const posicao = anosSelecionados.indexOf(prop);
                            if (posicao === -1)
                              tempAnosSelecionados.push(prop);
                            else
                              delete tempAnosSelecionados[posicao];
                            setAnosSelecionados(tempAnosSelecionados.filter(el => el));
                            setMesSelecionadoFiltro("0");
                          }}
                        />}
                        label={prop}
                      />
                    ))}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={anosSelecionados.length === anosDisponiveis.length}
                          onChange={() => setAnosSelecionados(anosSelecionados.length === anosDisponiveis.length ? [] : anosDisponiveis)}
                        />}
                      label={"Todos"}
                    />
                  </GridItem>
                  {anosSelecionados.length === 1 ? (
                    <GridItem lg={4} md={4} sm={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Mês de {anosSelecionados[0]}</InputLabel>
                        <Select
                          value={mesSelecionadoFiltro}
                          onChange={({target: {value}}) => setMesSelecionadoFiltro(value)}
                        >
                          <MenuItem value={"0"}>Todos</MenuItem>
                          {mesesAno.map((prop, key) => (
                            <MenuItem key={key} value={key + 1}>{prop}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  ) : ""}
                </GridContainer>
              </CardBody>
            </Card>
          </Zoom>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {!id_empresa && (
          <GridItem xs={12} sm={6} md={6} lg={4} className={'wizard-step-3'}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Store/>
                </CardIcon>
                <p className={classes.cardCategory}>Empresas</p>
                <h3 className={classes.cardTitle}>{pontuacaoNumero(totalEmpresas)}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Business/>
                  Empresas cadastradas
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        )}
        <GridItem xs={12} sm={6} md={6} lg={4} className={'wizard-step-4'}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Total de notas</p>
              <h3 className={classes.cardTitle}>
                {pontuacaoNumero(totalNotas)} <small>XML</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <CloudDone/>
                Disponível online
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {moduloAtivo('5d6950f6-ab5c-11ea-a580-7a791949cbcc') && (
          <GridItem xs={12} sm={6} md={6} lg={4} className={'wizard-step-5'}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Icon>info_outline</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Notas pendentes</p>
                <h3 className={classes.cardTitle}>
                  {/*<ContadorNumero total={totalNotasPendentes}/>*/}
                  {pontuacaoNumero(totalNotasPendentes)}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Warning>
                    <WarningIcon/>
                  </Warning>
                  <a
                    style={{cursor: "pointer"}}
                    onClick={() => history.push(`/notas/pendentes`, {id_empresa})}
                  >
                    Notas que ainda estão sendo processadas pelo sistema
                  </a>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        )}
      </GridContainer>
      <div className={'wizard-step-6'}>
        <Slide direction="left" in={filtroAtivo === 0 || filtroAtivo === 3} mountOnEnter unmountOnExit>
          <div>
            <GridContainer>
              {(moduloAtivo('5d6950f6-ab5c-11ea-a580-7a791949cbcc') || moduloAtivo('5e773062-ab5c-11ea-a580-7a791949cbcc')) && (
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <Card chart className={classes.cardHover}>
                    <CardHeader color={filtroAtivo ? "warning" : "info"}>
                      <Bar
                        data={{
                          labels: mesesAno,
                          datasets: [
                            {
                              highlight: "red",
                              label: (filtroAtivo === 0 ? "Notas Fiscais de Entrada" : "Serviços Prestados"),
                              backgroundColor: 'rgba(255, 255, 255, 0.6)',
                              borderColor: 'rgba(255, 255, 255, 1)',
                              borderWidth: 1,
                              hoverBackgroundColor: 'rgba(255, 255, 255, 0.8)',
                              hoverBorderColor: 'rgba(255, 255, 255, 1)',
                              data: [...graficoNotasMesTipo[!filtroAtivo ? 'entrada' : 'saida'], ([...graficoNotasMesTipo.entrada, ...graficoNotasMesTipo.saida].reduce((a, b) => Math.max(a, b)) || 10)]
                            }
                          ]
                        }}
                        options={chartOptions}
                      />
                    </CardHeader>
                    <CardBody>
                      <h4 className={classes.cardTitle}>
                        {filtroAtivo === 0 ? "Notas Fiscais de Entrada" : "Serviços Prestados"}
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>
              )}
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <Card chart className={classes.cardHover}>
                  <CardHeader color={filtroAtivo ? "info" : "warning"}>
                    <Bar
                      data={{
                        labels: mesesAno,
                        datasets: [
                          {
                            label: (filtroAtivo === 0 ? "Notas Fiscais de Saída" : "Serviços Tomados"),
                            backgroundColor: 'rgba(255, 255, 255, 0.6)',
                            borderColor: 'rgba(255, 255, 255, 1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(255, 255, 255, 0.8)',
                            hoverBorderColor: 'rgba(255, 255, 255, 1)',
                            data: [...graficoNotasMesTipo[!filtroAtivo ? 'saida' : 'entrada'], ([...graficoNotasMesTipo.entrada, ...graficoNotasMesTipo.saida].reduce((a, b) => Math.max(a, b)) || 10)]
                          }
                        ]
                      }}
                      options={chartOptions}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle}>
                      {filtroAtivo === 0 ? "Notas Fiscais de Saída" : "Serviços Tomados"}
                    </h4>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </Slide>
        <Slide direction="left" in={filtroAtivo === 1} mountOnEnter unmountOnExit>
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <Card chart className={classes.cardHover}>
                  <CardHeader color="success">
                    <Bar
                      data={{
                        labels: mesesAno,
                        datasets: [
                          {
                            label: 'NF-e Entrada',
                            backgroundColor: 'rgba(255, 255, 255, 0.6)',
                            borderColor: 'rgba(255, 255, 255, 1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(255, 255, 255, 0.8)',
                            hoverBorderColor: 'rgba(255, 255, 255, 1)',
                            data: [...graficoNotasMesTipoNota[55], ([
                              ...graficoNotasMesTipoNota[55],
                              ...graficoNotasMesTipoNota[57]
                            ].reduce((a, b) => Math.max(a, b) || 10))]
                          }
                        ]
                      }}
                      options={chartOptions}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle}>NF-e Entrada</h4>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <Card chart className={classes.cardHover}>
                  <CardHeader color="success">
                    <Bar
                      data={{
                        labels: mesesAno,
                        datasets: [
                          {
                            label: 'CT-e Entrada',
                            backgroundColor: 'rgba(255, 255, 255, 0.6)',
                            borderColor: 'rgba(255, 255, 255, 1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(255, 255, 255, 0.8)',
                            hoverBorderColor: 'rgba(255, 255, 255, 1)',
                            data: [...graficoNotasMesTipoNota[57], ([
                              ...graficoNotasMesTipoNota[55],
                              ...graficoNotasMesTipoNota[57]
                            ].reduce((a, b) => Math.max(a, b) || 10))]
                          }
                        ]
                      }}
                      options={chartOptions}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle}>CT-e Entrada</h4>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </Slide>
        <Slide direction="left" in={filtroAtivo === 2} mountOnEnter unmountOnExit>
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} lg={4}>
                <Card chart className={classes.cardHover}>
                  <CardHeader color="warning">
                    <Bar
                      data={{
                        labels: mesesAno,
                        datasets: [
                          {
                            label: 'NF-e Saída',
                            backgroundColor: 'rgba(255, 255, 255, 0.6)',
                            borderColor: 'rgba(255, 255, 255, 1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(255, 255, 255, 0.8)',
                            hoverBorderColor: 'rgba(255, 255, 255, 1)',
                            data: [...graficoNotasMesTipoNota[55], ([
                              ...graficoNotasMesTipoNota[55],
                              ...graficoNotasMesTipoNota[65],
                              ...graficoNotasMesTipoNota[57]
                            ].reduce((a, b) => Math.max(a, b) || 10))]
                          }
                        ]
                      }}
                      options={chartOptions}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle}>NF-e Saída</h4>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} lg={4}>
                <Card chart className={classes.cardHover}>
                  <CardHeader color="warning">
                    <Bar
                      data={{
                        labels: mesesAno,
                        datasets: [
                          {
                            label: 'NFC-e Saída',
                            backgroundColor: 'rgba(255, 255, 255, 0.6)',
                            borderColor: 'rgba(255, 255, 255, 1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(255, 255, 255, 0.8)',
                            hoverBorderColor: 'rgba(255, 255, 255, 1)',
                            data: [...graficoNotasMesTipoNota[65], ([
                              ...graficoNotasMesTipoNota[55],
                              ...graficoNotasMesTipoNota[65],
                              ...graficoNotasMesTipoNota[57]
                            ].reduce((a, b) => Math.max(a, b) || 10))]
                          }
                        ]
                      }}
                      options={chartOptions}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle}>NFC-e Saída</h4>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} lg={4}>
                <Card chart className={classes.cardHover}>
                  <CardHeader color="warning">
                    <Bar
                      data={{
                        labels: mesesAno,
                        datasets: [
                          {
                            label: 'CT-e Saída',
                            backgroundColor: 'rgba(255, 255, 255, 0.6)',
                            borderColor: 'rgba(255, 255, 255, 1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(255, 255, 255, 0.8)',
                            hoverBorderColor: 'rgba(255, 255, 255, 1)',
                            data: [...graficoNotasMesTipoNota[57], ([
                              ...graficoNotasMesTipoNota[55],
                              ...graficoNotasMesTipoNota[65],
                              ...graficoNotasMesTipoNota[57]
                            ].reduce((a, b) => Math.max(a, b) || 10))]
                          }
                        ]
                      }}
                      options={chartOptions}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle}>CT-e Saída</h4>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </Slide>
      </div>
      <GridContainer className={"wizard-step-7"}>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Language/>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Notas por estado
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={5}>
                  <Table
                    tableData={Object.keys(estadosEstatistica).map(prop => [prop.toUpperCase(), estadosEstatistica[prop]])}
                    hover
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <VectorMap
                    map={'brazil'}
                    backgroundColor="transparent"
                    zoomOnScroll={false}
                    containerStyle={{width: "100%", height: "100%", minHeight: 260, minWidth: 250}}
                    containerClassName="map"
                    regionStyle={{
                      initial: {
                        fill: "#e4e4e4",
                        "fill-opacity": 0.9,
                        stroke: "none",
                        "stroke-width": 0,
                        "stroke-opacity": 0
                      }
                    }}
                    onRegionTipShow={(e, el, code) => el.html(`${el.html()} - ${pontuacaoNumero(estadosEstatistica[code])}`)}
                    series={{
                      regions: [{
                        values: estadosEstatistica,
                        scale: ["#cdecfc", "#247589"],
                        normalizeFunction: "polynomial"
                      }]
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
