import React, {useEffect, useState} from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import Person from "@material-ui/icons/Person";
import Button from "../../components/CustomButtons/Button";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import CardAvatar from "../../components/Card/CardAvatar";
import Clearfix from "../../components/Clearfix/Clearfix";
import PictureUpload from "../../components/CustomUpload/PictureUpload";
import DefaultAvatar from "../../assets/img/faces/avatar.jpg";
import {baseURL, WebService} from "../../components/WebService";
import CheckboxAPI from "@material-ui/core/Checkbox";
import {permicoesUsuario} from "../../variables/usuario";
import withStyles from "@material-ui/core/styles/withStyles";
import {infoColor} from "../../assets/jss/material-dashboard-pro-react";
import * as Swal from "sweetalert2";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {ArrowBack} from "@material-ui/icons";
import {alterarSenhaUsuario, nomeProprio} from "../../components/Funcoes";

const Checkbox = withStyles({root: {color: infoColor[0], '&$checked': {color: infoColor[0]}}})(props => (
  <CheckboxAPI color="default" {...props} />
));

export default function UsuarioSingle({computedMatch, location, history}) {
  const classes = {};
  const [usuario, setUsuario] = useState({id_usuario: null, nome: "", email: "", foto_perfil: DefaultAvatar});

  const [idEmpresa, setIdEmpresa] = useState("");
  const [idUsuario, setIdUsuario] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [fotoPerfil, setFotoPerfil] = useState("");
  const [flagNotificacaoCertificado, setFlagNotificacaoCertificado] = useState(true);

  const [permicoes, setPermicoes] = useState(permicoesUsuario);

  const getUsuario = async id_usuario => {
    const {params} = computedMatch;
    const {state} = location;
    if (state) {
      const {id_empresa} = state;
      setIdEmpresa(id_empresa);
    }
    if (params && !id_usuario) {
      let {id} = params;
      id_usuario = !isNaN(parseInt(id)) ? id : null;
      setUsuario({...usuario, id_usuario});
    }
    if (id_usuario) {
      const {ok, usuario} = await WebService(`/usuario/getUsuarioID`, {id_usuario});
      if (ok) {
        const {id_usuario, nome, email, foto_perfil, permicoes: permicoesUsuario, telefone, flag_notificacao_certificado} = usuario;
        const tempPermicoes = [...permicoes];
        for (let i = 0; i < tempPermicoes.length; i++) {
          const {slug} = tempPermicoes[i];
          tempPermicoes[i] = {...tempPermicoes[i], ...permicoesUsuario[slug]};
        }
        setPermicoes(tempPermicoes);
        setIdUsuario(id_usuario);
        setTelefone(telefone);
        setNome(nome);
        setEmail(email);
        setFotoPerfil(foto_perfil);
        setFlagNotificacaoCertificado(flag_notificacao_certificado);
      }
    }
  };

  useEffect(() => {
    window.jQuery('#dado-telefone').mask("(00) 0 0000-0000");
  }, [telefone]);

  const alteraPermissao = async (tipo, key) => {
    const tempPermicoes = [...permicoes];
    const {slug} = tempPermicoes[key];
    tempPermicoes[key][tipo] = !tempPermicoes[key][tipo];
    const {ok} = await WebService(`/usuario/alteraPermissao`, {
      slug,
      tipo,
      id_usuario: idUsuario,
      valor: tempPermicoes[key][tipo]
    }, "POST");
    if (ok) {
      Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      }).fire({icon: 'success', title: 'Salvo com sucesso!'});
      setPermicoes(tempPermicoes);
    }
  };

  const atualizaPerfilUsuario = async () => {
    const usuario = {
      id_usuario: idUsuario,
      nome,
      email,
      telefone,
      id_empresa: idEmpresa,
      flag_notificacao_certificado: flagNotificacaoCertificado
    };
    const {ok, id_usuario} = await WebService(`/usuario/atualizaPerfilUsuario`, usuario, "POST");
    if (ok) {
      await Swal.fire({icon: "success", title: `Usuário ${idUsuario ? 'atualizado' : 'cadastrado'} com sucesso!`});
      if (id_usuario) {
        setIdUsuario(id_usuario);
        await getUsuario(id_usuario);
        if (!idEmpresa)
          history.push(`/configuracoes/usuario/${id_usuario}`);
      }
    }
  };

  const alterarSenha = async () => {
    await alterarSenhaUsuario({id_usuario: idUsuario});
  };

  useEffect(() => {
    getUsuario();
  }, []);

  return (
    <div>
      <GridContainer justify={"center"}>
        {!idEmpresa && (
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Button color={"info"} onClick={() => history.push(`/configuracoes/usuario`)}>
              <ArrowBack/> Voltar
            </Button>
            <p>&nbsp;</p>
          </GridItem>
        )}
        {!idEmpresa && idUsuario && (
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardAvatar profile style={{height: 111, width: 106}}>
                <PictureUpload
                  recortar
                  accept={"image/*"}
                  preview={fotoPerfil ? `${baseURL}/usuario/getFotoPerfil?foto_perfil=${fotoPerfil}` : DefaultAvatar}
                  onUpload={async imagem => {
                    const {ok, nomeArquivo} = await WebService(`/usuario/alterarFotoPerfil`, {
                      imagem,
                      id_usuario: idUsuario
                    }, "POST");
                    if (ok) {
                      localStorage.setItem("usuario_foto", `${baseURL}/usuario/getFotoPerfil?foto_perfil=${nomeArquivo}`);
                      await Swal.fire({icon: "success", title: `Foto do perfil atualizada com sucesso!`});
                    }
                  }}
                />
              </CardAvatar>
              <CardBody>
                <table style={{width: '100%', textAlign: 'center'}}>
                  <tr style={{color: '#555'}}>
                    <th>Descrição</th>
                    <th>Pode ver</th>
                    <th>Pode Criar</th>
                    <th>Pode Editar</th>
                    <th>Pode Excluir</th>
                  </tr>
                  <tr>
                    <td><small>Marcar todos</small></td>
                    <td>
                      <Checkbox
                        checked={permicoes.filter(el => el.pode_ver).length === permicoes.length}
                        onChange={() => permicoes.map((prop, key) => alteraPermissao('pode_ver', key))}
                      />
                    </td>
                    <td>
                      <Checkbox
                        checked={permicoes.filter(el => el.pode_criar).length === permicoes.length}
                        onChange={() => permicoes.map((prop, key) => alteraPermissao('pode_criar', key))}
                      />
                    </td>
                    <td>
                      <Checkbox
                        checked={permicoes.filter(el => el.pode_editar).length === permicoes.length}
                        onChange={() => permicoes.map((prop, key) => alteraPermissao('pode_editar', key))}
                      />
                    </td>
                    <td>
                      <Checkbox
                        checked={permicoes.filter(el => el.pode_excluir).length === permicoes.length}
                        onChange={() => permicoes.map((prop, key) => alteraPermissao('pode_excluir', key))}
                      />
                    </td>
                  </tr>
                  {permicoes.map((prop, key) => {
                    const {descricao, pode_ver, pode_criar, pode_editar, pode_excluir} = prop;
                    return (
                      <tr key={key}>
                        <td>{descricao}</td>
                        <td>
                          <Checkbox checked={pode_ver} onChange={() => alteraPermissao('pode_ver', key)}/>
                        </td>
                        <td>
                          <Checkbox checked={pode_criar} onChange={() => alteraPermissao('pode_criar', key)}/>
                        </td>
                        <td>
                          <Checkbox checked={pode_editar} onChange={() => alteraPermissao('pode_editar', key)}/>
                        </td>
                        <td>
                          <Checkbox checked={pode_excluir} onChange={() => alteraPermissao('pode_excluir', key)}/>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </CardBody>
            </Card>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={idEmpresa ? 12 : 8} lg={idEmpresa ? 12 : 8}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color={"info"}>
                <Person/>
              </CardIcon>
              {!idEmpresa && idUsuario && (
                <h4 className={classes.cardIconTitle}>
                  <GridContainer justify={"flex-end"}>
                    <Button simple color={"info"} style={{marginTop: -30}} onClick={alterarSenha.bind(this)}>
                      Alterar Senha
                    </Button>
                  </GridContainer>
                </h4>
              )}
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="Nome"
                    inputProps={{onChange: ({target}) => setNome(nomeProprio(target.value)), value: nome}}
                    formControlProps={{fullWidth: true}}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="Endereço de Email"
                    formControlProps={{fullWidth: true}}
                    inputProps={{value: email, onChange: ({target}) => setEmail(target.value.toLowerCase())}}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={4}>
                  <CustomInput
                    labelText="Telefone"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: telefone,
                      onChange: ({target}) => setTelefone(target.value),
                      id: 'dado-telefone'
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={flagNotificacaoCertificado}
                        onChange={() => setFlagNotificacaoCertificado(!flagNotificacaoCertificado)}
                      />
                    }
                    label={"Notificações de certificado digital"}
                  />
                </GridItem>
              </GridContainer>
              <Button
                color={"info"}
                className={classes.updateProfileButton}
                onClick={atualizaPerfilUsuario.bind(this)}
                style={{float: 'right'}}
              >
                {idUsuario ? 'Atualizar' : 'Cadastrar'} Perfil
              </Button>
              <Clearfix/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
