import React, {useEffect, useState} from "react";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import Table from "../../components/Table/Table";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {
  Add, Build, Business, Close, CloudDone, Dns, FileCopy, Filter9Plus, HowToReg, Loupe, NoteAdd, VpnKey,
} from "@material-ui/icons";
import NavPills from "../../components/NavPills/NavPills";
import CompareArrows from "@material-ui/icons/CompareArrows";
import Button from "../../components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import notificationsStyle from "../../assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CustomInput from "../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import {WsSincronizador} from "../../components/WebService";
import Checkbox from "../../components/CustomInput/CustomCheckbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Treebeard} from 'react-treebeard';
import * as Swal from "sweetalert2";
import {Tooltip} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import StatusSincronizacao from "../Components/StatusSincronizacao";
import {ls} from "../../components/Funcoes";

const useStyles1 = makeStyles(notificationsStyle);

export default function Dashboard({history}) {
  const classes = useStyles1();
  const hostSincronizador = localStorage.getItem('hostSincronizador');

  const [hostConectado, setHostConectado] = useState(`${hostSincronizador || '127.0.0.1'}`)

  const [tabActive, setTabActive] = useState(0);
  const [tipoFonte, setTipoFonte] = useState(0);
  const [tipoBancoDados, setTipoBancoDados] = useState(0);

  const [diretorios, setDiretorios] = useState([]);
  const [cursor, setCursor] = useState(false);

  const [configDB, setConfigDB] = useState({
    host: '127.0.0.1',
    usuario: '',
    senha: '',
    porta: '',
    instancia: "",
    bancos: []
  });

  const [versao, setVersao] = useState("AGUARDANDO");

  const [slug, setSlug] = useState("AGUARDANDO");
  const [percentual, setPercentual] = useState(0);
  const [time, setTime] = useState(new Date());
  const [totalNotas, setTotalNotas] = useState(0);
  const [modalConfigurarOpen, setModalConfigurarOpen] = useState(false);
  const [totalNotasEnviadas, setTotalNotasEnviadas] = useState(0);
  const [totalNotasPendentes, setTotalNotasPendentes] = useState(0);
  const [status, setStatus] = useState("");
  const [cnpjEmpresa, setCnpjEmpresa] = useState("");

  const [fonteDados, setFonteDados] = useState([]);

  const getFonteDados = async () => {
    const {ok, fonteDados} = await WsSincronizador(`/v1/configuracoes/getFonteDados`);
    if (ok) setFonteDados(fonteDados);
  };

  const conectarWebSocket = () => {
    const ws = new WebSocket(`ws://${ls('hostSincronizador', '127.0.0.1')}:5533`);

    ws.addEventListener('message', ({data}) => {
      const {percentual, status, totalNotasEnviadas, totalNotasPendentes, totalNotas, slug, time} = JSON.parse(data);

      if (percentual !== undefined) setPercentual(percentual);
      if (status !== undefined) setStatus(status);
      if (totalNotasEnviadas !== undefined) setTotalNotasEnviadas(totalNotasEnviadas);
      if (totalNotasPendentes !== undefined) setTotalNotasPendentes(totalNotasPendentes);
      if (totalNotas !== undefined) setTotalNotas(totalNotas);
      if (time !== undefined) setTime(time);
      if (slug !== undefined) setSlug(slug);
    });

    ws.onclose = () => {
      console.log("[i] Conexão WebSocket perdida, reconectando");
      conectarWebSocket();
    };
  };

  const alterarHost = async () => {
    const {value} = await Swal.fire({
      icon: "question",
      title: "Digite o IP do host que deseja acessar.",
      input: "text"
    });
    setHostConectado(value);
    localStorage.setItem(`hostSincronizador`, value);
    getDadosSistema();
  };

  const getDadosSistema = async () => {
    const {ok, versao} = await WsSincronizador(`/v1/sistema/getVersao`, {}, "GET", true, false);
    if (ok) {
      setVersao(versao);
      conectarWebSocket();
      getStatusAtual();
      getFonteDados();
      getConfigEmpresas();
    } else {
      const {value} = await Swal.fire({
        icon: "question",
        title: `Não encontramos o sincronizador no endereço [${ls('hostSincronizador', '127.0.0.1')}].`,
        text: "Deseja pesquisar em outro endereço?",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim"
      });
      if (value) alterarHost();
      else history.push(`/`);
    }
  };

  useEffect(() => {
    // localStorage.setItem(`hostSincronizador`, hostConectado);
  }, [hostConectado]);

  useEffect(() => {
    localStorage.removeItem(`hostSincronizador`);
    setHostConectado("127.0.0.1");
    getDadosSistema();
  }, []);

  const conectarBanco = async () => {
    const {ok, bancos} = await WsSincronizador(`/v1/banco/conectarBanco`, {tipoBancoDados, ...configDB}, "POST");
    if (ok) {
      setConfigDB(c => {
        return {
          ...c,
          bancos: bancos.map(prop => {
            return {
              nome: prop,
              checked: false,
              nomeCampoChave: "",
              nomeCampoXML: "",
              nomeCampoWhere: "",
              nomeTabela: ""
            };
          })
        }
      })
    }
  };

  const buscarDiretorioFiles = async path => {
    const {ok, paths} = await WsSincronizador(`/v1/diretorio/getDiretorio`, {path}, "POST");
    if (ok) {
      if (path) return paths;
      setDiretorios(paths);
    }
  };

  const getDetalheFonteDados = key => {
    let texto = ``;
    const getChild = child => {
      if (typeof child === "object") {
        const keys = Object.keys(child);
        keys.forEach(prop => {
          texto += `<strong>${prop}:</strong> ${(prop === "senha") ? '******' : getChild(child[prop])}<br/>`;
        });
      } else return child;
    };
    const {configDB, tabelas} = fonteDados[key];
    texto += `<div align="left">`;
    texto += `<h4 style="margin-bottom: 0;"><strong>Configuração BD:</strong></h4>`;
    getChild(configDB);

    texto += `<h4 style="margin-bottom: 0;"><strong>Tabelas:</strong></h4>`;
    tabelas.forEach(prop => {
      getChild(prop);
      texto += `<hr style="margin: 0; width: 60%;"/>`;
    });
    texto += `</div>`;
    Swal.fire({html: texto});
  };

  const getConfigEmpresas = async () => {
    const {ok, configuracoes} = await WsSincronizador(`/v1/configuracoes/getConfiguracoes`);
    if (ok) {
      const {cnpj_empresa} = configuracoes;
      setCnpjEmpresa(cnpj_empresa || "");
    }
  };

  const getStatusAtual = async () => {
    const {ok, percentual, status, totalNotasEnviadas, totalNotasPendentes, totalNotas, slug, time} = await WsSincronizador(`/v1/sistema/getStatusAtual`);
    if (ok) {
      if (percentual !== undefined) setPercentual(percentual);
      if (status !== undefined) setStatus(status);
      if (totalNotasEnviadas !== undefined) setTotalNotasEnviadas(totalNotasEnviadas);
      if (totalNotasPendentes !== undefined) setTotalNotasPendentes(totalNotasPendentes);
      if (totalNotas !== undefined) setTotalNotas(totalNotas);
      if (time !== undefined) setTime(time);
      if (slug !== undefined) setSlug(slug);
    }
  };

  const cadastrarNovaFonteDados = async config => {
    config.tipo = tipoFonte;
    config.tipoBD = tipoBancoDados;
    config.configBD = {...configDB};
    config.tabelas = configDB.bancos.filter(el => el.checked);
    delete config.configBD.bancos;

    const {ok} = await WsSincronizador(`/v1/configuracoes/cadastrarNovaFonteDados`, config, "POST");
    if (ok) {
      await Swal.fire({icon: "success", title: "Fonte de dados cadastrada com sucesso!"});
      await getFonteDados();
    }
  };

  const excluirFonteDados = async id_fonte_dados => {
    const {ok} = await WsSincronizador(`/v1/configuracoes/excluirFonteDados`, {id_fonte_dados});
    if (ok) {
      await Swal.fire({icon: "success", title: "Fonte de dados exlcuida com sucesso!"});
      await getFonteDados();
    }
  };

  const salvarDadosEmpresa = async () => {
    const {ok} = await WsSincronizador(`/v1/configuracoes/salvarDadosEmpresa`, {cnpjEmpresa}, "POST");
    if (ok) {
      await Swal.fire({icon: "success", title: "Dados salvos com sucesso!"});
    }
  };

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal80 + " " + classes.modal
        }}
        onClose={() => setModalConfigurarOpen(false)}
        open={modalConfigurarOpen}
        fullWidth
        fullScreen
      >
        <DialogTitle>
          <Button
            justIcon
            className={classes.modalCloseButton}
            aria-label="Close"
            color="transparent"
            onClick={() => setModalConfigurarOpen(false)}
          >
            <Close className={classes.modalClose}/>
          </Button>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem lg={12} md={12} sm={12} xs={12}>
              <NavPills
                color="info"
                alignCenter
                active={tipoFonte}
                onChange={t => setTipoFonte(t)}
                tabs={[
                  {tabButton: "Arquivo", tabIcon: FileCopy},
                  {tabButton: "Banco de dados", tabIcon: () => <i className="fas fa-database" style={{fontSize: 30}}/>}
                ]}
              />
            </GridItem>
            <GridItem lg={12} md={12} sm={12} xs={12}>
              {tipoFonte === 0 && (
                <GridContainer justify={"center"}>
                  <GridItem lg={6} md={8} sm={10} xs={12}>
                    <Button color={"info"} fullWidth onClick={() => buscarDiretorioFiles()}>
                      Buscar diretório&nbsp;<NoteAdd/>
                    </Button>
                  </GridItem>
                  <GridItem lg={12} md={12} sm={12} xs={12} align={"left"}>
                    <Treebeard
                      data={diretorios}
                      onToggle={async (node, toggled) => {
                        const {name, path} = node;
                        if (cursor) cursor.active = false;
                        const paths = await buscarDiretorioFiles(path || name);
                        node.children = paths && paths.length ? paths : undefined;
                        node.toggled = toggled;
                        node.active = true;

                        setCursor(node);
                      }}
                    />
                  </GridItem>
                </GridContainer>
              )}
              {tipoFonte === 1 && (
                <>
                  <GridContainer>
                    <GridItem lg={4} md={4} sm={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Tipo Banco de Dados</InputLabel>
                        <Select
                          value={tipoBancoDados}
                          onChange={({target: {value}}) => setTipoBancoDados(value)}
                        >
                          <MenuItem value={0}>MySQL</MenuItem>
                          <MenuItem value={1}>Microsoft SQL Server</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem lg={4} md={4} sm={6} xs={12}>
                      <CustomInput
                        labelText="Host/IP"
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          type: "text",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Dns className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                          ),
                          value: configDB.host,
                          onChange: ({target: {value: host}}) => setConfigDB(v => {
                            return {...v, host};
                          })
                        }}
                      />
                    </GridItem>
                    {tipoBancoDados === 1 && (
                      <GridItem lg={4} md={4} sm={6} xs={12}>
                        <CustomInput
                          labelText="Instância"
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            type: "text",
                            endAdornment: (
                              <InputAdornment position="end">
                                <Dns className={classes.inputAdornmentIcon}/>
                              </InputAdornment>
                            ),
                            value: configDB.instancia,
                            onChange: ({target: {value: instancia}}) => setConfigDB(v => {
                              return {...v, instancia};
                            })
                          }}
                        />
                      </GridItem>
                    )}
                    <GridItem lg={4} md={4} sm={6} xs={12}>
                      <CustomInput
                        labelText="Porta"
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          type: "number",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Filter9Plus className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                          ),
                          value: configDB.porta,
                          onChange: ({target: {value: porta}}) => setConfigDB(v => {
                            return {...v, porta};
                          })
                        }}
                      />
                    </GridItem>
                    <GridItem lg={4} md={4} sm={6} xs={12}>
                      <CustomInput
                        labelText="Usuário"
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          type: "text",
                          endAdornment: (
                            <InputAdornment position="end">
                              <HowToReg className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                          ),
                          value: configDB.usuario,
                          onChange: ({target: {value: usuario}}) => setConfigDB(v => {
                            return {...v, usuario};
                          })
                        }}
                      />
                    </GridItem>
                    <GridItem lg={4} md={4} sm={6} xs={12}>
                      <CustomInput
                        labelText="Senha"
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          type: "password",
                          endAdornment: (
                            <InputAdornment position="end">
                              <VpnKey className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                          ),
                          value: configDB.senha,
                          onChange: ({target: {value: senha}}) => setConfigDB(v => {
                            return {...v, senha};
                          })
                        }}
                      />
                    </GridItem>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <GridContainer>
                        <GridItem lg={4} md={4} sm={6} xs={12}>
                          <Button color={"info"} fullWidth onClick={conectarBanco.bind(this)}>
                            Conectar&nbsp;<CloudDone/>
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem lg={12} md={12} sm={12} xs={12} align={"left"}>
                      <GridContainer>
                        {configDB.bancos.map((prop, key) => (
                          <GridItem lg={4} md={4} sm={6} xs={12} key={`${prop.nome}_0`}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={prop.checked}
                                  onChange={() => {
                                    const {bancos} = configDB;
                                    bancos[key].checked = !bancos[key].checked;
                                    setConfigDB(c => {
                                      return {...c, bancos};
                                    })
                                  }}
                                />
                              }
                              label={prop.nome}
                            />
                          </GridItem>
                        ))}
                        <GridItem lg={12} md={12} sm={12} xs={12}>
                          <hr/>
                        </GridItem>
                        {configDB.bancos.filter(el => el.checked).map((prop, key) => (
                          <GridItem lg={12} md={12} sm={12} xs={12} key={`${prop.nome}_1`}>
                            <h4><strong>{prop.nome}</strong></h4>
                            <hr style={{width: '50%', float: 'left', marginTop: 0}}/>
                            <GridContainer>
                              <GridItem lg={3} md={3} sm={6} xs={12}>
                                <CustomInput
                                  labelText="Nome tabela"
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    type: "text",
                                    // value: configDB.bancos[key]['nomeCampoChave'],
                                    onChange: ({target: {value: nomeTabela}}) => setConfigDB(v => {
                                      v.bancos[v.bancos.map(el => el.nome).indexOf(prop.nome)]['nomeTabela'] = nomeTabela;
                                      return v;
                                    })
                                  }}
                                />
                              </GridItem>
                              <GridItem lg={3} md={3} sm={6} xs={12}>
                                <CustomInput
                                  labelText="Nome campo chave"
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    type: "text",
                                    // value: configDB.bancos[key]['nomeCampoChave'],
                                    onChange: ({target: {value: nomeCampoChave}}) => setConfigDB(v => {
                                      v.bancos[v.bancos.map(el => el.nome).indexOf(prop.nome)]['nomeCampoChave'] = nomeCampoChave;
                                      return v;
                                    })
                                  }}
                                />
                              </GridItem>
                              <GridItem lg={3} md={3} sm={6} xs={12}>
                                <CustomInput
                                  labelText="Nome campo XML"
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    type: "text",
                                    // value: configDB.bancos[key]['nomeCampoXML'],
                                    onChange: ({target: {value: nomeCampoXML}}) => setConfigDB(v => {
                                      v.bancos[v.bancos.map(el => el.nome).indexOf(prop.nome)]['nomeCampoXML'] = nomeCampoXML;
                                      return v;
                                    })
                                  }}
                                />
                              </GridItem>
                              <GridItem lg={3} md={3} sm={6} xs={12}>
                                <CustomInput
                                  labelText="Clausura WHERE"
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    type: "text",
                                    // value: configDB.bancos[key]['nomeCampoWhere'],
                                    onChange: ({target: {value: nomeCampoWhere}}) => setConfigDB(v => {
                                      v.bancos[v.bancos.map(el => el.nome).indexOf(prop.nome)]['nomeCampoWhere'] = nomeCampoWhere;
                                      return v;
                                    })
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        ))}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </>
              )}
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
          <Button color={"danger"} onClick={() => setModalConfigurarOpen(false)} simple>
            <Close/>&nbsp;Cancelar
          </Button>
          <Button
            color={"info"}
            onClick={async () => {
              setModalConfigurarOpen(false);
              const {path} = cursor;

              if (tipoFonte === 0) {
                const {value} = await Swal.fire({
                  icon: "question",
                  title: 'Deseja sincronizar o diretório?',
                  html: `<code>${path}</code>`,
                  showCancelButton: true,
                  showConfirmButton: true,
                  cancelButtonText: 'Não',
                  confirmButtonText: 'Sim'
                });

                if (value) await cadastrarNovaFonteDados({path});
              } else if (tipoFonte === 1) {
                const {value} = await Swal.fire({
                  icon: "question",
                  title: 'Deseja cadastrar o banco de dados?',
                  // html: `<code>${path}</code>`,
                  showCancelButton: true,
                  showConfirmButton: true,
                  cancelButtonText: 'Não',
                  confirmButtonText: 'Sim'
                });

                if (value) await cadastrarNovaFonteDados({path});
              }
            }}
          >
            Cadastrar&nbsp;<Add/>
          </Button>
        </DialogActions>
      </Dialog>
      {/***************************************************************************************/}
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem lg={12} md={12} sm={12} xs={12} align={"center"}>
              <h4>
                <Button color={"info"} simple onClick={alterarHost}>Alterar Host</Button>
                <br/>
                Host: {hostConectado}
                <br/>
                Versão: {versao}
              </h4>
            </GridItem>
          </GridContainer>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <NavPills
              color="info"
              alignCenter
              active={tabActive}
              onChange={t => setTabActive(t)}
              tabs={[
                {tabButton: "Status", tabIcon: CompareArrows},
                {tabButton: "Configurações", tabIcon: Build}
              ]}
            />
          </GridItem>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          {tabActive === 0 && (
            <>
              <StatusSincronizacao
                slug={slug}
                percentual={percentual}
                status={status}
                totalNotas={totalNotas}
                totalNotasEnviadas={totalNotasEnviadas}
                totalNotasPendentes={totalNotasPendentes}
                time={time}
              />
            </>
          )}

          {tabActive === 1 && (
            <div>
              <GridContainer>
                <GridItem lg={12} md={12} sm={12} xs={12}>
                  <h3>Dados da empresa</h3>
                  <hr style={{width: '50%', float: 'left', marginTop: 0, marginBottom: 0}}/>
                </GridItem>
                <GridItem lg={12} md={12} sm={12} xs={12}/>
                <GridItem lg={3} md={4} sm={6} xs={12}>
                  <CustomInput
                    labelText="CNPJ da empresa"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: cnpjEmpresa,
                      onChange: ({target: {value}}) => setCnpjEmpresa(value),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Business className={classes.inputAdornmentIcon}/>
                        </InputAdornment>
                      )
                    }}
                  />
                </GridItem>
                <GridItem lg={3} md={4} sm={6} xs={12}>
                  <br/>
                  <Button color={"info"} fullWidth onClick={salvarDadosEmpresa.bind(this)}>
                    Salvar
                  </Button>
                </GridItem>
              </GridContainer>
              <hr/>
              <Button color={"info"} onClick={() => setModalConfigurarOpen(true)}>
                Nova fonte de dados
              </Button>
              <Table
                hover
                tableHead={["Tipo captura", "Dados", "Opções"]}
                tableData={fonteDados.map((prop, key) => {
                  const {tipo, banco, path, id_fonte_dados} = prop;
                  const tp = {BD: 'Banco de Dados', arquivo: 'Arquivo'};

                  return [
                    tp[tipo],
                    (banco || path),
                    (
                      <>
                        {tipo === "BD" && (
                          <Tooltip title={"Visualizar detalhes"}>
                            <Button
                              simple
                              color={"info"}
                              justIcon
                              round
                              style={{marginTop: -10, marginBottom: -10}}
                              onClick={() => getDetalheFonteDados(key)}
                            >
                              <Loupe/>
                            </Button>
                          </Tooltip>
                        )}
                        <Tooltip title={"Excluir fonte de dados"}>
                          <Button
                            simple
                            color={"danger"}
                            justIcon
                            round
                            style={{marginTop: -10, marginBottom: -10}}
                            onClick={() => excluirFonteDados(id_fonte_dados)}
                          >
                            <Close/>
                          </Button>
                        </Tooltip>
                      </>
                    )
                  ];
                })}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
