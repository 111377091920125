import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../../components/CustomButtons/Button";
import {Close, Send} from "@material-ui/icons";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {Editor} from "@tinymce/tinymce-react";
import {DialogActions, makeStyles} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import modalStyle from "../../assets/jss/material-dashboard-pro-react/modalStyle";

const useStyles1 = makeStyles(modalStyle);

export default function ModalEnviarEmail({open, conteudoEmail, onCloseModal, onSendMail, onConteudoEmailChange}) {
  const classes = useStyles1();

  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal80 + " " + classes.modal
      }}
      onClose={() => onCloseModal && onCloseModal()}
      open={open}
      fullWidth
      fullScreen
    >
      <DialogTitle>
        <Button
          justIcon
          className={classes.modalCloseButton}
          aria-label="Close"
          color="transparent"
          onClick={() => onCloseModal && onCloseModal()}
        >
          <Close className={classes.modalClose}/>
        </Button>
      </DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} align={"center"}>
            <h3>Editar conteúdo do email</h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Editor
              // initialValue={defaultText}
              value={conteudoEmail}
              apiKey={"z3rps119hlg37g5fuqvfuxakfvblvmb7fepw9lrtetate84t"}
              init={{
                height: 500,
                menubar: false,
                language: 'pt_BR',
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help'
              }}
              onEditorChange={onConteudoEmailChange}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
        <Button color={"danger"} onClick={() => onCloseModal && onCloseModal()} simple>
          <Close/> Cancelar
        </Button>
        <Button color={"info"} onClick={onSendMail}>
          Enviar&nbsp;&nbsp;<Send/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
