import React, {useEffect, useState} from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import ResponsiveTable from "../../components/ResponsiveTable";
import CustomLinearProgress from "../../components/CustomLinearProgress/CustomLinearProgress";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import Button from "../../components/CustomButtons/Button";
import {ArrowBack, GetApp, ZoomIn} from "@material-ui/icons";
import {convertDate, nomeProprio} from "../../components/Funcoes";
import {WebService} from "../../components/WebService";
import Success from "../../components/Typography/Success";
import * as Swal from "sweetalert2";
import {Tooltip} from "@material-ui/core";
import Warning from "../../components/Typography/Warning";

export default function ManagerDownloads({history, webSocket}) {
  const [downloads, setDownloads] = useState([]);
  const [atualiza, setAtualiza] = useState(false);

  const getAllStatus = async () => {
    const {ok, status} = await WebService(`/sistema/getStatus`);
    if (ok) {
      setDownloads(status);
      setAtualiza(true);
    }
  };

  const recebeProgresso = ({data}) => {
    const {download} = JSON.parse(data);
    if (download) {
      const {percentual, flag_finalizado, id_sistema_status} = download;
      const tempDownloads = [...downloads];
      const key = tempDownloads.map(prop => prop['id_sistema_status']).indexOf(id_sistema_status);
      tempDownloads[key] = {...tempDownloads[key], percentual, flag_finalizado};
      setDownloads(tempDownloads);
    }
  };

  const conectarWebSocket = () => {
    if (webSocket && (webSocket.readyState === webSocket.OPEN)) {
      webSocket.send(JSON.stringify({metodo: 'adicionarEvento', evento: 'monitorarDownloads'}));
      webSocket.addEventListener("message", recebeProgresso);
    }
  };

  const desconectarWebSocket = () => {
    if (webSocket && (webSocket.readyState === webSocket.OPEN)) {
      webSocket.send(JSON.stringify({metodo: 'removerEvento', evento: 'monitorarDownloads'}));
      webSocket.removeEventListener("message", recebeProgresso);
    }
  };

  useEffect(() => {
    if (atualiza)
      conectarWebSocket();

    return () => desconectarWebSocket();
  }, [webSocket, atualiza]);

  useEffect(() => {
    getAllStatus();
  }, []);

  return (
    <GridContainer>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Button color={"info"} onClick={() => history.push(`/notas`)}>
          <ArrowBack/> Voltar
        </Button>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardBody>
            <ResponsiveTable
              colunas={["#", "Tipo", "Data", "Data finalizado", "Status", "Opções"]}
              dados={downloads.map(prop => {
                const {id_sistema_status, tipo, data_inicio, data_finalizado, flag_finalizado, flag_erro, percentual, descricao, link_finalizado} = prop;
                return [
                  id_sistema_status,
                  nomeProprio(tipo),
                  convertDate(data_inicio, false, true),
                  convertDate(data_finalizado, false, true),
                  flag_finalizado ? (
                    <Success>
                      Concluido
                    </Success>
                  ) : (
                    flag_erro ? <Warning>Download perdido, tente realizar novamente.</Warning> : (
                      <>
                        {percentual ? percentual.toFixed(1) : ""}%<br/>
                        <CustomLinearProgress color={"info"} variant="buffer" value={percentual} valueBuffer={0}/>
                      </>
                    )
                  ),
                  <>
                    <Tooltip title={"Visualizar detalhes"}>
                      <Button
                        simple
                        round
                        justIcon
                        color={"info"}
                        onClick={() => Swal.fire({
                          title: "Resumo da solicitação",
                          text: descricao,
                          customClass: {content: 'texto-normal'}
                        })}
                      >
                        <ZoomIn/>
                      </Button>
                    </Tooltip>
                    {flag_finalizado && link_finalizado ? (
                      <Tooltip title={"Baixar arquivo"}>
                        <Button simple round justIcon color={"info"} onClick={() => window.open(link_finalizado)}>
                          <GetApp/>
                        </Button>
                      </Tooltip>
                    ) : ""}
                  </>
                ]
              })}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
