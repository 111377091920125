import React, {useEffect, useState} from "react";
import ReactTable from "react-table";
import {makeStyles} from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardHeader from "../../components/Card/CardHeader.js";
import {cardTitle} from "../../assets/jss/material-dashboard-pro-react.js";
import Business from "@material-ui/icons/Business";
import {traducaoReactTable} from "../../components/DataModule";
import {WebService} from "../../components/WebService";
import * as Swal from "sweetalert2";
import {infoColor} from "../../assets/jss/material-dashboard-pro-react";
import DotLoader from "react-spinners/DotLoader";
import ClockLoader from "react-spinners/ClockLoader";
import {
  convertDate, Filter,
  formataCPFCNPJ, ls,
  pontuacaoNumero,
  somenteNumero,
  zeroEsquerda
} from "../../components/Funcoes";
import Success from "../../components/Typography/Success";
import Danger from "../../components/Typography/Danger";
import Countdown from 'react-countdown';
import matchSorter from 'match-sorter';
import Store from "@material-ui/icons/Store";
import CardFooter from "../../components/Card/CardFooter";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import {Tooltip} from "@material-ui/core";
import {ArrowForward, CloudDone, ErrorOutline, Info, Sync} from "@material-ui/icons";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }, ...extendedFormsStyle, ...dashboardStyle
};
const useStyles = makeStyles(styles);

export default function ListarEmpresas({history, webSocket, setToken, flag_contabilidade}) {
  const [empresas, setEmpresas] = useState([]);
  const [adm, setAdm] = useState(0);
  const [atualiza, setAtualiza] = useState(false);

  const getStatusServidor = statusServidor => {
    if (statusServidor) {
      let [mensagem, outros] = statusServidor.split("*");
      let Componente = DotLoader;
      if (outros) {
        let {tempoRegressivo} = JSON.parse(outros);
        if (tempoRegressivo) {
          mensagem = (
            <>
              {mensagem}{" "}
              <Countdown date={new Date(tempoRegressivo)} renderer={({hours, minutes, seconds, completed}) => {
                return <span>{zeroEsquerda(hours)}:{zeroEsquerda(minutes)}:{zeroEsquerda(seconds)}</span>;
              }}/>
            </>
          );
          Componente = ClockLoader
        }
      }

      const mostrarLoading = !!statusServidor && statusServidor !== 'O certificado digital está vencido. Nenhuma ação será feita.';
      statusServidor = (
        <>
          <span style={{float: 'left', marginLeft: mostrarLoading ? 30 : 0}}>{mensagem}</span>
          {mostrarLoading && (
            <Componente size={25} color={infoColor[0]} loading={true}/>
          )}
        </>
      );
      return statusServidor;
    }
  };

  const buscarEmpresas = async () => {
    const {ok, empresas: empresasBD, id_empresa} = await WebService(`/empresa/getEmpresas`, {flag_contabilidade});
    if (ok) {
      setEmpresas(empresasBD.map(prop => {
        prop.statusServidor = getStatusServidor(prop.statusServidor);
        return prop;
      }));
      setAdm(id_empresa);
      setAtualiza(!atualiza);
    }
  };

  const RDV = ({value}) => {
    const Component = (new Date(value) > new Date()) ? Success : Danger;
    value = convertDate(value, false, true);
    value = <Component>{value}</Component>;
    return value;
  };

  useEffect(() => {
    buscarEmpresas();
  }, []);

  const reiniciarSincronizacao = async id_empresa => {
    const {value} = await Swal.fire({
      icon: "question",
      title: 'Deseja reiniciar a sincronização dos dados da empresa?',
      text: 'O uso incorreto desta funcionalidade poderá acarretar em consumo indevido pela receita federal.',
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não'
    });

    if (value === true) {
      const {ok} = await WebService(`/empresa/reiniciarSincronizacao`, {id_empresa});
      if (ok) {
        await Swal.fire({icon: "success", title: "Serviço reiniciado com sucesso!"});
        await buscarEmpresas();
      }
    }
  };

  const excluirEmpresa = async (id_empresa) => {
    const {value} = await Swal.fire({
      icon: "question",
      title: 'Deseja realmente excluir a empresa?',
      text: 'Todos os dados continuarão armazenados.',
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não'
    });

    if (value === true) {
      const {ok} = await WebService(`/empresa/excluirEmpresa`, {id_empresa});
      if (ok) {
        await Swal.fire({icon: "success", title: "Empresa excluida com sucesso!"});
        await buscarEmpresas();
      }
    }
  };

  const recebeStatusEmpresa = (props) => {
    let {data} = props;
    data = JSON.parse(data);
    const {id_empresa, statusServidor} = data;
    const tempEmpresas = [...empresas];
    for (let i = 0; i < tempEmpresas.length; i++) {
      if (tempEmpresas[i].id_empresa === id_empresa) {
        if (statusServidor !== undefined)
          tempEmpresas[i].statusServidor = getStatusServidor(statusServidor);
      }
    }
    setEmpresas([...tempEmpresas]);
  };

  useEffect(() => {
    if (webSocket && (webSocket.readyState === webSocket.OPEN)) {
      webSocket.send(JSON.stringify({metodo: 'adicionarEvento', evento: 'monitorarListaEmpresa'}));
      webSocket.addEventListener("message", recebeStatusEmpresa);
    }

    return () => {
      if (webSocket && (webSocket.readyState === webSocket.OPEN)) {
        webSocket.send(JSON.stringify({metodo: 'removerEvento', evento: 'monitorarListaEmpresa'}));
        webSocket.removeEventListener("message", recebeStatusEmpresa);
      }
    };
  }, [webSocket, atualiza]);

  const FilterOnlyNumber = ({filter, onChange}) => Filter({filter, onChange}, somenteNumero);

  const acessarEmpresa = async id_empresa => {
    const {ok, token} = await WebService(`/sistema/getToken`, {id_empresa});
    if (ok) {
      const tokenAtual = ls('token');
      localStorage.setItem('tokenAtual', tokenAtual);
      localStorage.setItem('token', token);
      history.push(`/painel`);
    }
  };

  const classes = useStyles();

  const label = (flag_contabilidade ? "Contabilidade" : "Empresa");

  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={6} lg={4} className={"wizard-step-ListarEmpresas-1"}>
        <Card>
          <CardHeader color="info" stats icon>
            <CardIcon color="info">
              <Store/>
            </CardIcon>
            <p className={classes.cardCategory}>{label}s</p>
            <h3 className={classes.cardTitle}>{pontuacaoNumero(empresas.length)}</h3>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              <Business/>
              {label}s cadastradas
            </div>
          </CardFooter>
        </Card>
      </GridItem>

      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <GridContainer>
            <GridItem lg={2} md={3} sm={4} xs={12} className={"wizard-step-ListarEmpresas-2"}>
              <Button color={"info"} fullWidth onClick={() => history.push(`/${label.toLowerCase()}s/cadastrar`)}
                      simple>
                Nova {label.toLowerCase()}
              </Button>
            </GridItem>
            <GridItem lg={4} md={4} sm={6} xs={12} className={"wizard-step-ListarEmpresas-3"}>
              <Button color={"info"} fullWidth onClick={() => history.push(`/${label.toLowerCase()}s/cadastrarMassa`)}
                      simple>
                Cadastro/Atualização de {label.toLowerCase()} e certificados em massa
              </Button>
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>

      <GridItem lg={12} md={12} sm={12} xs={12} className={"wizard-step-ListarEmpresas-4"}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <Business/>
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Listar {label.toLowerCase()}s</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              {...traducaoReactTable}
              data={empresas.map((prop, key) => {
                prop.id = key;
                const status = prop['data_ultima_conexao'] ? ((prop['tempoInativo'] > 30 * 60 * 1000) ? "warning" : "success") : "danger";
                const icones = {success: CloudDone, danger: ErrorOutline, warning: Info};
                const labes = {success: "Ativo", danger: "Nunca conectado", warning: "Inativo"};
                const Icone = icones[status];
                prop.statusSincronizador = (
                  <Button disabled color={status} fullWidth>
                    <Icone/>{labes[status]}
                  </Button>
                )
                prop.actions = (
                  <div className="actions-right wizard-step-ListarEmpresas-5">
                    <Tooltip title={`Peril da ${label.toLowerCase()}`}>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() => history.push(`/${label.toLowerCase()}s/${prop.id_empresa}`)}
                        color="info"
                      >
                        <Edit/>
                      </Button>
                    </Tooltip>
                    <Tooltip title={`Excluir ${label.toLowerCase()}`}>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() => excluirEmpresa(prop.id_empresa)}
                        color="danger"
                      >
                        <Close/>
                      </Button>
                    </Tooltip>
                    <Tooltip title={`Reiniciar Sincronização`}>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() => reiniciarSincronizacao(prop.id_empresa)}
                        color="warning"
                      >
                        <Sync/>
                      </Button>
                    </Tooltip>
                    {(adm === 1) && (prop.id_empresa !== adm) && (
                      <Tooltip title={`Acessar perfil ${label}`}>
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => acessarEmpresa(prop.id_empresa)}
                          color="success"
                        >
                          <ArrowForward/>
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                );
                return prop;
              })}
              getTrProps={(state, rowInfo) => (rowInfo && rowInfo.original.data_vencimento_certificado && !rowInfo.original.flag_certificado_completo) ? {style: {background: 'rgba(255, 90, 0,0.1)'}} : {}}
              columns={[
                {Header: "#", accessor: "id_empresa", filterable: true, Filter},
                {Header: "Razão Social", accessor: "nome_razao_social", filterable: true, Filter},
                {Header: "Nome Fantasia", accessor: "nome_fantasia", filterable: true, Filter},
                {
                  Header: "CNPJ",
                  accessor: "cnpj",
                  filterable: true,
                  Cell: ({value}) => formataCPFCNPJ(value),
                  Filter: FilterOnlyNumber
                },
                {Header: "Status", accessor: "statusServidor", filterable: false},
                {Header: "Validade Certificado", accessor: "data_vencimento_certificado", filterable: false, Cell: RDV},
                {Header: "Sincronizador Saídas", accessor: "statusSincronizador", filterable: false},
                {Header: "Opções", accessor: "actions", sortable: false, filterable: false}
              ]}
              filterable
              defaultFilterMethod={(filter, row) => matchSorter([row[filter.id]], filter.value, {threshold: matchSorter.rankings.WORD_STARTS_WITH}).length !== 0}
              defaultPageSize={10}
              showPaginationBottom={true}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
