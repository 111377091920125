import React, {useEffect, useState} from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import ResponsiveTable from "../../components/ResponsiveTable";
import {WebService} from "../../components/WebService";
import Button from "../../components/CustomButtons/Button";
import {Close, Edit, Add} from "@material-ui/icons";
import * as Swal from "sweetalert2";
import {Tooltip} from "@material-ui/core";

export default function UsuarioManager({history, id_empresa}) {
  const [usuarios, setUsuarios] = useState([]);

  const getUsuarios = async () => {
    const {ok, usuarios} = await WebService(`/usuario/getUsuarios`, {id_empresa});
    if (ok)
      setUsuarios(usuarios);
  };

  useEffect(() => {
    getUsuarios();
  }, []);

  const excluirUsuario = async id_usuario => {
    const {value} = await Swal.fire({
      icon: "question",
      title: 'Deseja realmente excluir o usuário?',
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim"
    });
    if (value === true) {
      const {ok} = await WebService(`/usuario/excluirUsuario`, {id_usuario});
      if (ok) {
        await Swal.fire({icon: "success", title: "Usuário excluído com sucesso!"});
        await getUsuarios();
      }
    }
  };

  return (
    <div>
      <Button color={"info"} onClick={() => history.push(`/configuracoes/usuario/novo`, {id_empresa})}>
        <Add/> Adicionar
      </Button>
      <GridContainer>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardBody>
              <ResponsiveTable
                colunas={["#", "Nome", "Email", "Opções"]}
                dados={usuarios.map(prop => {
                  const {id_usuario, nome, email} = prop;
                  return [id_usuario, nome, email, (
                    <div className="actions-right">
                      <Tooltip title={"Editar Perfil"}>
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => history.push(`/configuracoes/usuario/${id_usuario}`, id_usuario)}
                          color="info"
                        >
                          <Edit/>
                        </Button>
                      </Tooltip>
                      <Tooltip title={"Excluir usuário"}>
                        <Button justIcon round simple onClick={() => excluirUsuario(id_usuario)} color="danger">
                          <Close/>
                        </Button>
                      </Tooltip>
                    </div>
                  )];
                })}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
    ;
}
