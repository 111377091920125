import React, {useEffect, useState} from "react";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import {formataCPFCNPJ, pontuacaoNumero} from "../../components/Funcoes";
import CardFooter from "../../components/Card/CardFooter";
import Business from "@material-ui/icons/Business";
import GridItem from "../../components/Grid/GridItem";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import {whiteColor} from "../../assets/jss/material-dashboard-pro-react";
import {WebService} from "../../components/WebService";
import {ArrowBack, Build, CloudDone, ErrorOutline, Info} from "@material-ui/icons";
import Button from "../../components/CustomButtons/Button";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Zoom from "@material-ui/core/Zoom";
import StatusSincronizacao from "../Components/StatusSincronizacao";
import * as Swal from "sweetalert2";

const useStyles = makeStyles({...styles, ...extendedFormsStyle, selectWhite: {color: whiteColor}});

const Transition = React.forwardRef((props, ref) => <Zoom direction="down" ref={ref} {...props} />);

export default function DashboardSincronizador({history}) {
  const classes = useStyles();

  const [empresas, setEmpresas] = useState([]);
  const [modalDetalhesSincroniador, setModalDetalhesSincroniador] = useState({
    open: false,
    nomeRazao: "",
    dispositivos: []
  });

  const getEmpresas = async () => {
    const {ok, empresas} = await WebService(`/v1/sincronizador/getStatusEmpresa`);
    if (ok) setEmpresas(empresas);
  };

  useEffect(() => {
    getEmpresas();
  }, []);

  return (
    <div>
      <Dialog
        fullWidth
        classes={{root: classes.center + " " + classes.modalRoot, paper: classes.modal}}
        open={modalDetalhesSincroniador.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModalDetalhesSincroniador({open: false, nomeRazao: "", dispositivos: []})}
      >
        <DialogContent className={classes.modalBody}>
          <GridContainer>
            <GridItem md={12} xs={12} sm={12} lg={12}>
              {modalDetalhesSincroniador.dispositivos.map(prop => {
                const {configuracao: {cpu: {nucleos, modelo}, hostname, versaoSistema}, status: {slug, totalNotas, totalNotasEnviadas, totalNotasPendentes, percentual, status, time}} = prop;
                return (
                  <div>
                    <hr/>
                    <table style={{width: '100%'}}>
                      {[
                        {nome: "CPU", valor: `${nucleos} X ${modelo}`},
                        {nome: "Hostname", valor: hostname},
                        {nome: "Versão Sincronizador", valor: versaoSistema}
                      ].map((prop, key) => (
                        <tr key={key}>
                          <th style={{float: 'left'}}>{prop.nome}:</th>
                          <td>{prop.valor}</td>
                        </tr>
                      ))}
                    </table>
                    <StatusSincronizacao
                      slug={slug}
                      percentual={percentual}
                      status={status}
                      totalNotas={totalNotas}
                      totalNotasEnviadas={totalNotasEnviadas}
                      totalNotasPendentes={totalNotasPendentes}
                      time={time}
                    />
                    <hr/>
                  </div>
                );
              })}
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <GridContainer style={{display: 'flex'}}>
        <GridItem xs={12} sm={4} md={2} lg={2}>
          <Button color={"info"} onClick={() => history.push(`/painel`)} fullWidth>
            <ArrowBack/> Voltar
          </Button>
        </GridItem>
        <GridItem xs={12} sm={4} md={3} lg={2}>
          <Button color={"info"} onClick={() => history.push(`/sincronizador`)} fullWidth>
            <Build/> Configurações
          </Button>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}/>
        <GridItem xs={12} sm={4} md={3} lg={3} style={{display: 'inline-grid'}}>
          <Card>
            <CardHeader color={"info"} stats icon>
              <CardIcon color={"info"}>
                {empresas.length}
              </CardIcon>
              <p className={classes.cardCategory}></p>
              <h3 className={classes.cardTitle}></h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Business/>
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        {empresas.map((prop, key) => {
          const {cnpj, nome_razao_social, total_dispositivos, data_ultima_conexao, dispositivos} = prop;

          const tempoInativo = new Date() - new Date(data_ultima_conexao);
          const status = data_ultima_conexao ? ((tempoInativo > 30 * 60 * 1000) ? "warning" : "success") : "danger";
          // const status = data_ultima_conexao ? (flag_online ? "success" : "warning") : "danger";

          const icones = {success: CloudDone, danger: ErrorOutline, warning: Info};

          const Icon = icones[status];

          return (
            <GridItem xs={12} sm={4} md={3} lg={3} key={key} style={{display: 'inline-grid'}}>
              <Card>
                <CardHeader color={status} stats icon>
                  <CardIcon
                    color={status}
                    style={{cursor: 'pointer'}}
                    onClick={() => dispositivos ? setModalDetalhesSincroniador({
                      open: true,
                      nomeRazao: nome_razao_social,
                      dispositivos: Object.keys(dispositivos).map(prop => dispositivos[prop])
                    }) : Swal.fire({icon: "info", title: "Nenhum dispositico conectado."})}
                  >
                    <Icon/>
                  </CardIcon>
                  <p className={classes.cardCategory}>{formataCPFCNPJ(cnpj)}</p>
                  <h3 className={classes.cardTitle}>{pontuacaoNumero(total_dispositivos)}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <Business/>
                    {nome_razao_social}
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          );
        })}
      </GridContainer>
    </div>
  );
}
