import React from "react";
import {Route} from "react-router-dom";

export const traducaoReactTable = {
  previousText: "<",
  nextText: ">",
  loadingText: "Carregando...",
  pageText: "Página",
  ofText: "de",
  noDataText: "Nenhum registro encontrado",
  rowsText: "registros"
};

export const mesesAno = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];

/*export const chartBarConfig = {
  options: {
    axisX: {showGrid: false},
    low: 0,
    chartPadding: {top: 0, right: 5, bottom: 0, left: 0}
  },
  responsiveOptions: [
    ["screen and (max-width: 640px)", {seriesBarDistance: 5, axisX: {labelInterpolationFnc: value => value[0]}}]
  ],
  animation: {
    draw: data => (data.type === "bar") && data.element.animate({
      opacity: {begin: (data.index + 1) * 20, dur: 500, from: 0, to: 1, easing: "ease"}
    })
  }
};*/

export const LoadRouterProps = ({component: Component, ...props}) => (
  <Route render={p => <Component {...props} {...p}/>}/>
);

export const moduloAtivo = uuid => {
  const modulos = JSON.parse(localStorage.getItem("modulos_ativo_sistema"));
  if (!modulos) {
    /*Swal.fire({
      icon: "warning",
      title: "Sessão desconectada.",
      text: `Sua sessão atual expirou, faça login novamente para continuar.`
    }).then(() => {
      // sairSistema();
    });*/
    return false;
  }

  return (modulos.indexOf(uuid) > -1);
};
