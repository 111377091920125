import React, {useEffect, useState} from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {makeStyles} from "@material-ui/core/styles";
import {Chip} from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import {
  primaryCardHeader as primaryColor,
  infoCardHeader as infoColor,
  successCardHeader as successColor,
  warningCardHeader as warningColor,
  roseCardHeader,
  primaryColor as PCp
} from "assets/jss/material-dashboard-pro-react.js";
import {WebService} from "../../components/WebService";

const useStyles = makeStyles((theme) => ({
  colorSecondary: {backgroundColor: "red"}
}));

export default function ChangeLogs({computedMatch, location}) {

  const classes = useStyles();

  const [changeLogs, setChangelogs] = useState([]);

  const getChangeLogs = async () => {
    const {ok, changeLogs} = await WebService(`/sistema/getChangelog`);
    if (ok) setChangelogs(changeLogs);
  };

  useEffect(() => {
    getChangeLogs();
  }, []);

  return (
    <Timeline align="left">
      {
        changeLogs.map(changeLog => {
          const {versao, data, logs} = changeLog;
          const dataAtualizacao = new Date(data)
          {/* const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }; */}
          return (

            <TimelineItem>
              <TimelineOppositeContent style={{maxWidth: 100}}>
                <Typography color="textSecondary" style={{marginTop: -3, marginRight: 2}}>
                  {dataAtualizacao.toLocaleDateString('pt-BR')}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot style={{backgroundColor: PCp[0]}}/>
                <TimelineConnector/>
              </TimelineSeparator>
              <TimelineContent>
                <Typography style={{fontSize: 24, fontWeight: 'bold'}}>v{versao}</Typography>
                {
                  logs.map((log) => {
                    const {tipo, comentario} = log;
                    {
                      /*
                      primaryColor = Segurança
                      infoColor = Correção
                      successColor =Funcionalidade
                      warningColor = Alteração
                      grayColor = Outros
                      */
                    }

                    const setBackground = (tipos) => {
                      let color;
                      switch (tipos) {
                        case 'Segurança':
                          color = primaryColor
                          break;
                        case 'Correção':
                          color = infoColor
                          break;
                        case 'Funcionalidade':
                          color = successColor
                          break;
                        case 'Alteração':
                          color = warningColor
                          break;
                        default:
                          color = roseCardHeader
                      }
                      return color
                    }

                    return (
                      <GridContainer
                        style={{marginBottom: 10, marginLeft: 15}}
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                      >
                        <Chip
                          size="small"
                          style={{...setBackground(tipo), fontWeight: '500', color: 'white'}}
                          label={tipo}
                        />
                        <Typography style={{paddingLeft: 10, fontSize: 16}}>{comentario}</Typography>
                      </GridContainer>
                    )
                  })
                }
              </TimelineContent>
            </TimelineItem>
          )
        })
      }
    </Timeline>
  );
}
