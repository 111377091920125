import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import ExitToApp from "@material-ui/icons/ExitToApp";
import {sairSistema} from "../Funcoes";
import {EmojiObjects, Palette} from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";
import Swal from "sweetalert2";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const {rtlActive} = props;
  const wrapper = classNames({[classes.wrapperRTL]: rtlActive});

  return (
    <div className={wrapper}>
      <Tooltip title={"Alterar cor sistema"}>
        <Button
          color="transparent"
          simple
          justIcon
          className={classes.buttonLink + ' ' + 'litelighterstyle'}
          onClick={async () => {
            const {value: color} = await Swal.fire({
              title: 'Selecione a cor',
              input: 'radio',
              inputOptions: {
                'info': 'Padrão',
                'success': 'Verde',
                'danger': 'Vermelho',
                'warning': 'Laranja',
                'rose': 'Rosa',
                'purple': 'Roxo',
              },
              inputValidator: (value) => {
                if (!value) return 'Você não selecionou a cor!'
              }
            });

            if (color) {
              localStorage.setItem('system.color', color);
              window.location.reload();
            }
          }}
          muiClasses={{label: ""}}
        >
          <Palette className={classes.headerLinksSvg + " " + classes.links}/>
          <Hidden mdUp implementation="css">
          <span className={classes.linkText}>
            Cores
          </span>
          </Hidden>
        </Button>
      </Tooltip>
      {/**********************************************************************************************/}
      <Tooltip title={"Ideias e sugestões"}>
        <Button
          color="transparent"
          simple
          justIcon
          className={classes.buttonLink + ' ' + 'litelighterstyle'}
          href="https://feedback.userreport.com/cbed5b87-d1c6-420e-8f90-89f3fd3a4663/"
          onClick={e => {
            e.preventDefault();
            window._urq.push(['Feedback_Open']);
          }}
          muiClasses={{label: ""}}
          // onClick={sairSistema}
        >
          <EmojiObjects className={classes.headerLinksSvg + " " + classes.links}/>
          <Hidden mdUp implementation="css">
          <span className={classes.linkText}>
            Sugestões
          </span>
          </Hidden>
        </Button>
      </Tooltip>
      <Tooltip title={"Sair do sistema"}>
        <Button
          color="transparent"
          simple
          justIcon
          className={classes.buttonLink}
          muiClasses={{label: ""}}
          onClick={sairSistema}
        >
          <ExitToApp className={classes.headerLinksSvg + " " + classes.links}/>
          <Hidden mdUp implementation="css">
          <span className={classes.linkText}>
            Sair
          </span>
          </Hidden>
        </Button>
      </Tooltip>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool
};
