import React from "react";
import {ls, sairSistema} from "./Funcoes";
import withReactContent from "sweetalert2-react-content";
import {infoColor} from "../assets/jss/material-dashboard-pro-react";
import BounceLoader from "react-spinners/BounceLoader";
import Swal from "sweetalert2";

const SwalR = withReactContent(Swal);

export const baseURL = ((process.env.NODE_ENV === 'production') ? `https://api.gefis.com.br` : `http://127.0.0.1:8085`);

export const WebService = async (path, parameters = {}, method = 'GET', loading = true, mostrarMensagemEro = true, blob = false, sincronizador = false) => {
  const timeout = setTimeout(() => {
    if (loading) {
      SwalR.fire({
        title: 'Gefis - Inteligência Fiscal',
        allowOutsideClick: false,
        showConfirmButton: false,
        html: (
          <div align={"center"}>
            <BounceLoader size={150} color={infoColor[0]} loading={true}/>
            Carregando...
            <p id={"labelCarregando"}>
              {ls('ultimaMensagemCarregando')}
            </p>
          </div>
        )
      });
    }
  }, 800);

  try {
    const request = {};
    let parametersUrl = ``;

    if (!parameters.token)
      parameters.token = ls('token') || undefined;

    if (method === 'GET')
      Object.keys(parameters).filter(el => parameters[el] !== undefined).map(prop => parametersUrl += `${(parametersUrl ? '&' : '?')}${prop}=${parameters[prop]}`);
    else if (method === 'POST')
      request.body = JSON.stringify(parameters);

    const hostSincronizador = localStorage.getItem('hostSincronizador');

    const requestReturn = await fetch(`${sincronizador ? `http://${hostSincronizador || 'localhost'}:8183` : baseURL}${path}${parametersUrl}`, {method, ...request});

    const contentType = requestReturn.headers.get("content-type");
    if (blob && contentType !== "application/json") {
      const dataBlob = await requestReturn.blob();
      clearTimeout(timeout);
      let limpo = false;
      while (!limpo) {
        try {
          Swal.close();
          limpo = true;
        } catch (e) {

        }
      }
      return dataBlob;
    }
    const data = await requestReturn.json();
    clearTimeout(timeout);

    let limpo = false;
    while (!limpo) {
      try {
        Swal.close();
        limpo = true;
      } catch (e) {

      }
    }
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    const {ok, mensagem_usuario, descricao_usuario, codigo_erro, erros_aplicacao, tipo_mensagem} = data;
    if (!ok) {
      if (codigo_erro === 46135789165) {
        await Swal.fire({
          icon: "warning",
          title: "Sessão desconectada.",
          text: `Sua sessão atual expirou, faça login novamente para continuar.`
        });
        sairSistema();
        return {ok: false};
      }
      if (mostrarMensagemEro) {
        await Swal.fire({
          title: mensagem_usuario,
          icon: tipo_mensagem || "warning",
          text: descricao_usuario,
          html: erros_aplicacao && erros_aplicacao.map(prop => prop['mensagem_usuario_html']).join("<br/>")
        });
      }
    }
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    return data;
  } catch (e) {
    await clearTimeout(timeout);
    if (mostrarMensagemEro)
      await Swal.fire({
        icon: 'warning',
        title: 'Não foi possivel processar a operação solicitada.',
        text: e.toString()
      });
    return {ok: false};
  } finally {
    localStorage.removeItem('ultimaMensagemCarregando');
  }
};

export const WsSincronizador = async (path, parameters = {}, method = 'GET', loading = true, mostrarMensagemEro = true, blob = false) => WebService(path, parameters, method, loading, mostrarMensagemEro, blob, true);
