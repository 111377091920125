import React from "react";
import {LoadRouterProps} from "../components/DataModule";
import routes from "../routes.js";
import AuthNavbar from "../components/Navbars/AuthNavbar";
import {Switch} from "react-router-dom";
import login from "../assets/img/login.jpeg";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../assets/jss/material-dashboard-pro-react/layouts/authStyle";
import stylesLogin from "../assets/jss/material-dashboard-pro-react/views/loginPageStyle";

const useStyles = makeStyles(styles);
const useStylesLogin = makeStyles(stylesLogin);
export default function SincronizadorLayout() {
  const classes = {...useStyles(), ...useStylesLogin()};
  return (
    <div>
      <AuthNavbar brandText={"Sincronizador notas saída"}/>
      <div className={classes.wrapper}>
        <div
          className={classes.fullPage}
          style={{backgroundImage: `url(${login})`, transition: '.3s'}}
        >
          <div className={classes.container}>
            <Switch>
              {routes.filter(el => el.layout === "/sincronizador").map((prop, key) => (
                <LoadRouterProps path={prop.path} component={prop.component} key={key}/>
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
