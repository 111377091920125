import React, {useEffect, useState} from "react";
import * as Swal from "sweetalert2";
import {WebService} from "../../../components/WebService";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardText from "../../../components/Card/CardText";
import {ls, pontuacaoNumero} from "../../../components/Funcoes";
import GridContainer from "../../../components/Grid/GridContainer";
import {cardTitle, whiteColor} from "../../../assets/jss/material-dashboard-pro-react";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import extendedFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import CardBody from "../../../components/Card/CardBody";
import ResponsiveTable from "../../../components/ResponsiveTable";
import Button from "../../../components/CustomButtons/Button";
import {ArrowBack, CallMissedOutgoing} from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";
import stylesLogin from "../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import stylesForm from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const styles1 = {
  cardTitle,
  cardTitleWhite: {...cardTitle, color: "#FFFFFF", marginTop: "0"},
  cardCategoryWhite: {margin: "0", color: "rgba(255, 255, 255, 0.8)", fontSize: ".875rem"}
};

const useStyles = makeStyles({...styles, ...extendedFormsStyle, selectWhite: {color: whiteColor}, ...styles1});

const useStyles1 = makeStyles(stylesForm);
const useStyles2 = makeStyles(stylesLogin);

export default function EmpresaNotasNaoLancadaCliente({computedMatch, history}) {
  const classes = {...useStyles(), ...useStyles1(), ...useStyles2()};

  const [uuidEmpresa, setUUIDEmpresa] = useState("");
  const [notasNaoLancadas, setNotasNaoLancadas] = useState({});

  const getNotasNaoLancadasUUID = async () => {
    const {ok, notasNaoLancadas} = await WebService(`/relatorio/getNotasNaoLancadasUUID`, {uuidEmpresa});
    if (ok)
      setNotasNaoLancadas(notasNaoLancadas);
  };

  useEffect(() => {
    if (uuidEmpresa)
      getNotasNaoLancadasUUID();
  }, [uuidEmpresa]);

  useEffect(() => {
    if (computedMatch) {
      const {params} = computedMatch;
      if (params) {
        const {uuidEmpresa} = params;
        if (uuidEmpresa)
          setUUIDEmpresa(uuidEmpresa);
        else
          Swal.fire({icon: "info", title: "Dados não conferem."});
      }
    }
  }, []);

  const lancarNotas = async (serie, tipo_nota) => {
    const {value: numeracao} = await Swal.fire({
      icon: "question",
      title: `Digite a numeração da nota para série ${serie}.`,
      input: "text",
      confirmButtonText: 'Próximo',
      showCancelButton: true,
      cancelButtonText: 'Cancelar'
    });
    if (numeracao) {
      const {value: motivo} = await Swal.fire({
        icon: "question",
        title: `Digite o motivo do não lançamento.`,
        input: "textarea",
        confirmButtonText: 'Salvar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar'
      });
      if (motivo) {
        const dados = {serie, numeracao, motivo, uuidEmpresa, tipo_nota};
        const {ok} = await WebService(`/relatorio/inserirNotaNaoLancada`, dados, "POST");
        if (ok) {
          await Swal.fire({
            icon: "success",
            title: `Nota lançada com sucesso!`,
            text: `Nota ${numeracao} série ${serie} lançada com sucesso!`
          });
          await getNotasNaoLancadasUUID();
        }
      } else
        Swal.fire({icon: "warning", title: 'É preciso inserir o motivo.'});
    }
  };

  const labels = {55: 'NF-e', 57: 'CT-e', 65: 'NFC-e'};

  return (
    <div className={classes.container}>
      <GridContainer>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          {ls('token') ? (
            <Button color={"info"} onClick={() => history.goBack()}>
              <ArrowBack/> Voltar
            </Button>
          ) : ""}
        </GridItem>
        {Object.keys(notasNaoLancadas).filter(el => notasNaoLancadas[el].total).map((prop, key) => {
          const dadosTabela = [];

          Object.keys(notasNaoLancadas[prop]).forEach(prop1 => {
            if (isNaN(parseInt(prop1))) return;
            notasNaoLancadas[prop][prop1].forEach(prop2 => {
              dadosTabela.push([
                prop2,
                prop1,
                <Tooltip title={"Lançar notas"}>
                  <Button color={"info"} simple justIcon round onClick={() => lancarNotas(prop1, prop)}>
                    <CallMissedOutgoing/>
                  </Button>
                </Tooltip>
              ])
            })
          });

          return (
            <GridItem lg={12} md={12} sm={12} xs={12} key={key}>
              <Card>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <h4 className={classes.cardTitleWhite}>{labels[prop]}</h4>
                    <h4 className={classes.cardCategoryWhite}>
                      {pontuacaoNumero(notasNaoLancadas[prop].total)} nota não lançadas
                    </h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <ResponsiveTable
                    titlePrint={`${prop} não lançadas`}
                    colunas={["Numeração", "Série", "Opções"]}
                    dados={dadosTabela}
                  />
                </CardBody>
              </Card>
            </GridItem>
          );
        })}
      </GridContainer>
    </div>
  );
}
