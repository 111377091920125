import React, {useState} from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import NavPills from "../../components/NavPills/NavPills";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Info from "@material-ui/icons/Info";
import {Assignment} from "@material-ui/icons";
import WebISS from "./NFSe/WebISS";
import IssDigitalThe from "./NFSe/IssDigitalThe";
import Megasoft from "./NFSe/Megasoft";

const NFSe = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <NavPills
            color="primary"
            alignCenter
            active={activeTab}
            onChange={value => setActiveTab(value)}
            tabs={[
              {tabButton: "WebISS", tabIcon: Assignment},
              {tabButton: "IssDigitalThe", tabIcon: Assignment},
              {tabButton: "MegaSoft", tabIcon: Assignment}
            ]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          {activeTab === 0 ? (<WebISS/>) : ''}
          {activeTab === 1 ? (<IssDigitalThe/>) : ''}
          {activeTab === 2 ? <Megasoft/> : ''}
        </GridItem>
      </GridContainer>
    </>
  );
};

export default NFSe;